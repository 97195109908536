/* eslint-disable react/display-name */
import React, { forwardRef } from 'react';

import { cn } from '@/utils/cn';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  title?: string;
  required?: boolean;
}

const Input: React.FC<InputProps> = forwardRef<HTMLInputElement, InputProps>(
  ({ title, required, ...rest }, ref) => {
    return (
      <div className="flex flex-col">
        {title && (
          <div
            className={`flex-col text-[12px] text-[#4A4A4A] ${rest.disabled ? 'text-[#cfcfcf]' : ''}`}
          >
            {title}
            {required && <span className="text-red">*</span>}
          </div>
        )}

        <input
          onKeyDown={(event) => {
            if (event.key.charCodeAt(0) < 48) {
              event.preventDefault();
            }
          }}
          {...rest}
          ref={ref}
          required={required}
          min={rest.min ?? 1}
          max={999}
          type="number"
          className={cn(
            'text-[##1F1F20], h-[2rem] rounded-[0.25rem] border-[1px] border-[#B9B9BF] bg-inherit px-[10px] text-[12px] active:border-none ',
            rest.className ?? '',
            rest.disabled ? 'bg-gray-200' : '',
          )}
        />
      </div>
    );
  },
);

export default Input;
