/* eslint-disable react-hooks/rules-of-hooks */
import { ColumnDef } from '@tanstack/react-table';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useRecoilState } from 'recoil';

import EditableTableInputText from '@/components/atoms/EditableTableInputText';
import { RequirementControlUpdateIndicatorsAtom } from '@/state/RequirementControlUpdateIndicators.atom';
import {
  RequirementsTypes,
  SituationStatus,
} from '@/types/RequirementControlTypes';
import { userCanEditRequirementControl } from '@/utils/handleSavePermissions';

import { queryClient } from '../../App';
import EditableTableDate from '../../components/atoms/EditableTableDate';
import SimpleMenu from '../../components/atoms/Menu';
import TableCellDate from '../../components/atoms/TableCellDate';
import TableCellStatus from '../../components/atoms/TableCellStatus';
import TableCellText from '../../components/atoms/TableCellText';
import api from '../../services/apiSgft';
import { ExpirationStatus } from '../../types/TrainingControlTypes';
import { removeTimeZone } from '../../utils/formatDate';
import { situationOptions } from '../RequirementConstants';

export const columns: Array<
  ColumnDef<{
    id: number;
    requirement: string;
    requirementType: string;
    required: boolean;
    emissionDate?: string;
    expirationDate?: string;
    situation?: string;
    council?: string;
    recordNumber?: string;
    status?: string;
  }>
> = [
  {
    accessorFn: (row) => row.requirementType,
    header: 'Tipo de Requisito',
    meta: {
      headerClassName: 'text-start text-black font-medium',
    },
    cell: (props) => (
      <div className="flex w-[12rem] items-center justify-start pl-2 pr-2">
        <TableCellText
          text={props.getValue() as string}
          align="start"
          textAlign="text-left"
          width="12rem"
          className="uppercase"
        />
      </div>
    ),
  },
  {
    accessorFn: (row) => row.required,
    header: 'Classe',
    meta: {
      headerClassName: 'text-start text-black font-medium',
    },
    cell: (props) => (
      <div className="flex w-[7rem] items-center justify-start pr-2">
        <TableCellText
          text={(props.getValue() as boolean) ? 'Obrigatório' : 'Desejável'}
          align="start"
          textAlign="text-left"
          width="7rem"
          className="uppercase"
        />
      </div>
    ),
  },
  {
    accessorFn: (row) => row.requirementType,
    header: 'Requisito',
    meta: {
      headerClassName: 'text-center text-black font-medium',
      headerContentClassName: 'flex items-center justify-center',
    },
    cell: (props) => (
      <div className="flex w-[12rem] items-center justify-center pl-2">
        <TableCellText
          text={props.row.original.requirement as string}
          align="center"
          textAlign="text-center"
          width="13rem"
          className="uppercase"
        />
      </div>
    ),
  },
  {
    accessorKey: 'emissionDate',
    header: 'Data de emissão',
    meta: {
      headerClassName: 'text-center text-black font-medium',
    },
    cell: (props) => {
      const [emissionDate, setEmissionDate] = useState<Date | undefined>(
        props.getValue() && props.getValue() !== '-'
          ? removeTimeZone(new Date(props.getValue() as string))
          : undefined,
      );

      useEffect(() => {
        const currentEmissionDate =
          props.getValue() && props.getValue() !== '-'
            ? removeTimeZone(new Date(props.getValue() as string))
            : undefined;
        if (
          emissionDate?.toDateString() !== currentEmissionDate?.toDateString()
        ) {
          setEmissionDate(currentEmissionDate);
        }
      }, [props.getValue(), emissionDate]);

      const updateEmissionDate = async (e: Date) => {
        try {
          await api.patch(`/requirement-control/${props.row.original.id}`, {
            emissionDate: (e as Date).toISOString(),
          });
          toast.success(
            `Data de emissão alterada para ${format(e, 'dd-MM-yyyy')} com sucesso!`,
            {
              theme: 'colored',
            },
          );
          queryClient.invalidateQueries(['requirement-control']);
        } catch (e: any) {
          const errorMessage =
            e?.response?.status === 400
              ? e?.response?.data?.message
              : 'Não foi possível alterar a data de emissão!';
          toast.error(errorMessage, {
            theme: 'colored',
            toastId: 'error',
          });
        }
      };
      if (userCanEditRequirementControl())
        return (
          <div className="flex w-[12rem] items-center justify-center">
            <EditableTableDate
              value={emissionDate}
              update={updateEmissionDate}
              disabled={!userCanEditRequirementControl()}
            />
          </div>
        );
      return (
        <div className="flex w-[10rem]">
          <TableCellDate date={emissionDate} className="flex justify-center" />
        </div>
      );
    },
  },

  {
    accessorKey: 'expirationDate',
    header: 'Data de validade',
    meta: {
      headerClassName: 'text-center text-black font-medium',
    },
    cell: (props) => {
      const [_, setUpdateIndicators] = useRecoilState(
        RequirementControlUpdateIndicatorsAtom,
      );
      const [expirationDate, setExpirationDate] = useState<Date | undefined>(
        props.getValue() && props.getValue() !== '-'
          ? removeTimeZone(new Date(props.getValue() as string))
          : undefined,
      );

      useEffect(() => {
        const currentExpirationDate =
          props.getValue() && props.getValue() !== '-'
            ? removeTimeZone(new Date(props.getValue() as string))
            : undefined;
        if (
          expirationDate?.toDateString() !==
          currentExpirationDate?.toDateString()
        ) {
          setExpirationDate(currentExpirationDate);
        }
      }, [props.getValue(), expirationDate]);

      const updateExpirationDate = async (e: Date) => {
        try {
          await api.patch(`/requirement-control/${props.row.original.id}`, {
            expirationDate: (e as Date).toISOString(),
          });
          toast.success(
            `Data de validade alterada para ${format(e, 'dd-MM-yyyy')} com sucesso!`,
            {
              theme: 'colored',
            },
          );
          setUpdateIndicators(true);
          queryClient.invalidateQueries(['requirement-control']);
        } catch (e: any) {
          const errorMessage =
            e?.response?.status === 400
              ? e?.response?.data?.message
              : 'Não foi possível alterar a data de validade!';
          toast.error(errorMessage, {
            theme: 'colored',
            toastId: 'error',
          });
        }
      };
      if (userCanEditRequirementControl()) {
        if (
          (props.row.original
            .requirementType as string as RequirementsTypes) ===
          'Requisitos Educacionais'
        ) {
          return (
            <div className="flex w-[10rem]">
              <TableCellText
                text={'N/A'}
                align="center"
                textAlign="text-center"
                width="13rem"
              />
            </div>
          );
        }
        return (
          <div className="flex w-[12rem] items-center justify-center">
            <EditableTableDate
              value={expirationDate}
              update={updateExpirationDate}
            />
          </div>
        );
      }

      return (
        <div className="flex w-[10rem]">
          <TableCellDate
            date={expirationDate}
            className="flex justify-center"
          />
        </div>
      );
    },
  },
  {
    accessorKey: 'situation',
    header: 'Situação do requisito',
    meta: {
      headerClassName: '!text-left text-black font-medium',
    },
    cell: (props) => {
      const [_, setUpdateIndicators] = useRecoilState(
        RequirementControlUpdateIndicatorsAtom,
      );
      const [status, setStatus] = useState(props.getValue() as string);

      useEffect(() => {
        const currentStatus = props.getValue() as string as SituationStatus;
        if (status !== currentStatus) {
          setStatus(currentStatus);
        }
      }, [props, status]);

      const handleUpdateCompletionStatus = async (
        value: string,
        id: number,
      ) => {
        try {
          await api.patch(`/requirement-control/${id}`, {
            situation: value,
          });
          toast.success(
            `Situação de requerimento alterada para ${value} com sucesso!`,
            { theme: 'colored' },
          );
          setUpdateIndicators(true);
          queryClient.invalidateQueries(['requirement-control']);
        } catch (error: any) {
          toast.error(`${error.response.data.message}`, {
            theme: 'colored',
            toastId: 'error',
          });
          throw error;
        }
      };

      const options = situationOptions.map((item) => ({
        name: item.label,
        onClick: () =>
          handleUpdateCompletionStatus(item.value, props.row.original.id),
        disabled: false,
      }));

      if (
        userCanEditRequirementControl() &&
        (props.row.original.requirementType as string as RequirementsTypes) ===
          'Requisitos Educacionais'
      )
        return (
          <div className="flex w-[12rem] items-center justify-between">
            <TableCellText
              text={status}
              align="left"
              textAlign="text-left"
              width="120px"
              className="uppercase"
            />
            <SimpleMenu
              options={options}
              disabled={!userCanEditRequirementControl()}
              canSave={true}
            />
          </div>
        );
      return (
        <TableCellText
          text={status}
          align="left"
          textAlign="text-left"
          width="13rem"
          className="uppercase"
        />
      );
    },
  },
  {
    accessorKey: 'status',
    header: 'Status',
    meta: {
      headerClassName: '!text-left text-black font-medium',
    },
    cell: (props) => (
      <div className="mr-5 flex h-full justify-start">
        <TableCellStatus
          status={props.getValue() as ExpirationStatus}
          upperCase={true}
          className="ml-0"
        />
      </div>
    ),
  },
  {
    accessorKey: 'council',
    header: 'Conselho',
    meta: {
      headerClassName: 'text-center text-black font-medium',
    },
    cell: (props) => {
      const [council, setCouncil] = useState<string>(
        props.getValue() ? (props.getValue() as string) : '',
      );

      const [editValue, setEditValue] = useState<string>(council);

      const submitCouncilUpdate = async () => {
        console.log('submit council');
        console.log(editValue);
        try {
          await api.patch(`/requirement-control/${props.row.original.id}`, {
            council: String(editValue),
          });
          toast.success(`Conselho alterado para ${editValue} com sucesso!`, {
            theme: 'colored',
          });
          // e.target.blur();
          setCouncil(editValue);
          queryClient.invalidateQueries(['requirement-control']);
        } catch (e) {
          toast.error('Erro ao salvar conselho!', {
            theme: 'colored',
            toastId: 'error',
          });
        }
      };

      if (userCanEditRequirementControl() && editValue !== 'N/A')
        return (
          <div className="flex w-[14rem] items-center justify-between">
            <EditableTableInputText
              title="Conselho"
              disabled={!userCanEditRequirementControl()}
              value={editValue}
              setState={setEditValue}
              handleSubmit={submitCouncilUpdate}
            />
          </div>
        );
      return (
        <TableCellText
          text={editValue}
          align="left"
          textAlign="text-left"
          width="13rem"
        />
      );
    },
  },
  {
    accessorKey: 'recordNumber',
    header: 'Número de registo',
    meta: {
      headerClassName: 'text-center text-black font-medium',
    },
    cell: (props) => {
      const [recordNumber, setRecordNumber] = useState<string>(
        props.getValue() ? (props.getValue() as string) : '',
      );

      const [editValue, setEditValue] = useState<string>(recordNumber);

      const recordNumberSubmit = async () => {
        try {
          await api.patch(`/requirement-control/${props.row.original.id}`, {
            recordNumber: String(editValue),
          });
          toast.success(
            `Número de registro alterado para ${editValue} com sucesso!`,
            {
              theme: 'colored',
            },
          );

          setRecordNumber(editValue);
          queryClient.invalidateQueries(['requirement-control']);
        } catch (e) {
          toast.error('Erro ao salvar o número de registro!', {
            theme: 'colored',
            toastId: 'error',
          });
        }
      };

      if (userCanEditRequirementControl() && editValue !== 'N/A')
        return (
          <div className="flex w-[14rem] items-center justify-between">
            <EditableTableInputText
              title="Número de registro"
              value={editValue}
              disabled={!userCanEditRequirementControl()}
              setState={setEditValue}
              handleSubmit={recordNumberSubmit}
            />
          </div>
        );
      return (
        <TableCellText
          text={editValue}
          align="start"
          textAlign="text-start"
          width="13rem"
        />
      );
    },
  },
];
