import React, { useEffect, useState } from 'react';
import Select, {
  components,
  GroupBase,
  MultiValue,
  SingleValue,
  StylesConfig,
  Theme,
} from 'react-select';
import Async from 'react-select/async';

import { Option } from '../../types/Filter';
import { cn } from '../../utils/cn';

type SelectOptions = {
  value: string;
  label: string;
  order?: number;
};

type Props = {
  options: SelectOptions[];
  onChange: (
    selectedOptions: MultiValue<SelectOptions> | SingleValue<SelectOptions>,
  ) => void;
  className?: string;
  asyncFn?: (search: string, atom?: any) => Promise<Option[]>;
  defaultValue?: SelectOptions[];
  value: string[];
  isSearchable?: boolean;
  filtersData: any;
  isDisabled: boolean;
  isMulti?: boolean;
  isLoading?: boolean;
};
const getUniqueOptions = (options: Array<SelectOptions>) =>
  options.reduce((acc: Array<SelectOptions>, option) => {
    if (!acc.find((o) => o.value === option.value)) {
      acc.push(option);
    }
    return acc;
  }, []);
const SelectInput: React.FC<Props> = ({ isSearchable = true, ...props }) => {
  const [lastOptions, setLastOptions] = useState(props.options);
  const theme: (theme: Theme) => Theme = (theme) => ({
    ...theme,
    borderRadius: 6,
    spacing: {
      ...theme.spacing,
      controlHeight: 34,
    },
    colors: {
      ...theme.colors,
      primary: '#aabbff',
      neutral60: '#E5E5E5',
    },
  });

  const styles: StylesConfig<SelectOptions, true, GroupBase<SelectOptions>> = {
    placeholder: (provided, state) => ({
      ...provided,
      color: state.isDisabled ? '#AAA' : 'white',
      fontWeight: '300',
      fontSize: '16px',
    }),
    valueContainer: (base) => ({
      ...base,
      height: 'auto',
    }),
    multiValue: (base) => ({
      ...base,
    }),
    control: (provided, state) => ({
      ...provided,
      background: '#193CB9',
      borderRadius: '10px',
      borderColor: state.isDisabled ? '#AAA' : '#FFF', // Personalize as cores aqui
    }),
    menuList: (provided) => ({
      ...provided,
      minHeight: '50px',
      fontWeight: 'thin',
      color: 'white',
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? 'white' : '#193CB9',
      fontSize: '13px',
      fontWeight: state.isSelected ? 'bold' : 'normal',
    }),
    menuPortal: (provided) => ({
      ...provided,
      fontSize: '13px',
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      whiteSpace: 'break-spaces',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'white',
      fontSize: '12px',
    }),
    input: (provided) => ({
      ...provided,
      color: 'white',
    }),
  };

  useEffect(() => {
    const mergedOptions = [
      ...props.options,
      ...lastOptions.filter((option) => props.value.includes(option.value)),
    ];

    const uniqueOptions = Array.from(
      new Set(mergedOptions.map((option) => option.value)),
    )
      .map((value) => mergedOptions.find((option) => option.value === value))
      .filter((option): option is SelectOptions => option !== undefined);

    setLastOptions(uniqueOptions);
  }, [props.options, props.value]);

  const handleLoadOptions = async (search: string) => {
    if (props.asyncFn) {
      const options = await props.asyncFn(search);
      setLastOptions((prev) =>
        getUniqueOptions([
          ...prev.filter((val) => props.value?.includes(val.value)),
          ...options,
        ]),
      );
      return options;
    }
    return props.options;
  };
  return props.asyncFn !== undefined ? (
    <Async
      key={JSON.stringify(props.filtersData)}
      className={cn('w-full rounded-lg font-medium', props.className)}
      isMulti={props.isMulti ? true : undefined}
      options={lastOptions}
      placeholder="Selecione..."
      onChange={props.onChange}
      theme={theme}
      styles={styles}
      components={{ ValueContainer }}
      loadOptions={handleLoadOptions}
      isDisabled={props.isDisabled}
      defaultOptions
      noOptionsMessage={() => 'Nenhuma opção encontrada'}
      loadingMessage={() => 'Carregando'}
      isSearchable={isSearchable}
      value={lastOptions?.filter((option) =>
        props.value?.includes(String(option.value)),
      )}
      menuPosition="fixed"
      isLoading={props?.isLoading}
    />
  ) : (
    <Select
      className={`w-full rounded-lg font-medium ${props.className}`}
      isMulti={props.isMulti ? true : undefined}
      options={lastOptions}
      placeholder="Selecione..."
      onChange={props.onChange}
      theme={theme}
      styles={styles}
      components={{ ValueContainer }}
      defaultValue={props.defaultValue}
      noOptionsMessage={() => 'Nenhuma opção encontrada'}
      isSearchable={isSearchable}
      value={lastOptions?.filter((option) =>
        props.value?.includes(String(option.value)),
      )}
      menuPosition="fixed"
      isLoading={props?.isLoading}
    />
  );
};

const ValueContainer = ({ children, ...props }: any) => {
  const { hasValue } = props;
  const [tags, otherChildren] = children;

  return (
    <components.ValueContainer {...props}>
      {!hasValue ? (
        <>{children}</>
      ) : (
        <>
          {tags}
          {otherChildren}
        </>
      )}
    </components.ValueContainer>
  );
};

export default SelectInput;
