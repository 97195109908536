import * as r from '@recoiljs/refine';
import { atom } from 'recoil';
import { urlSyncEffect } from 'recoil-sync';

export interface PendenciesFilters extends Record<string, string[]> {
  managements: string[];
  areas: string[];
  sectors: string[];
  workstations: string[];
  scales: string[];
  types: string[];
  responsibles: string[];
}
const employee = JSON.parse(localStorage.getItem('employee') ?? '{}');
const managementId = employee?.management_id;
const stringArrayChecker = r.array(r.string()) as r.Checker<string[]>;
export const PendenciesFiltersAtom = atom<PendenciesFilters>({
  key: 'pendenciesFilters',
  default: {
    managements: managementId ? [String(managementId)] : [],
    areas: [],
    sectors: [],
    workstations: [],
    scales: [],
    types: [],
    responsibles: [],
  },
  effects: [
    urlSyncEffect<PendenciesFilters>({
      refine: r.object({
        managements: stringArrayChecker,
        areas: stringArrayChecker,
        sectors: stringArrayChecker,
        workstations: stringArrayChecker,
        scales: stringArrayChecker,
        types: stringArrayChecker,
        responsibles: stringArrayChecker,
      }),
      history: 'push',
    }),
  ],
});
