import { useInfiniteQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';

import {
  PendencyListSchema,
  PendencyStatus,
} from '@/constants/PendencyConstants';
import api from '@/services/apiSgft';
import { PendenciesFiltersAtom } from '@/state/PendenciesFilter.atom';

import EmptyIcon from '../../assets/icons/empty.svg';
import { columns } from '../../constants/tableColumns/PendencyTableColumns';
import Spinner from '../atoms/Spinner';
import InfiniteTable from './InfiniteTable';

const OpenPendenciesTab = () => {
  const filterValues = useRecoilValue(PendenciesFiltersAtom);

  const fetchPendencies = async ({ pageParam = 1 }) => {
    try {
      const response = await api.get('pendencies/', {
        params: {
          size: 20,
          page: pageParam,
          status: PendencyStatus.OPEN,
          management: filterValues.managements,
          area: filterValues.areas,
          sector: filterValues.sectors,
          workStation: filterValues.workstations,
          scale: filterValues.scales,
          pendencyType: filterValues.types,
          responsible: filterValues.responsibles,
        },
      });
      const parsedData = PendencyListSchema.parse(response.data);
      return parsedData;
    } catch (e) {
      toast.error('Erro ao carregar os dados', {
        theme: 'colored',
        toastId: 'error',
      });
      throw e;
    }
  };

  const { data, isLoading, fetchNextPage, isFetchingNextPage, hasNextPage } =
    useInfiniteQuery(['pendency-open', filterValues], fetchPendencies, {
      getNextPageParam: (actualPage) => {
        return actualPage.nextPage;
      },
    });

  const openPendencies = data?.pages.flatMap((page) => page.pendencyData) ?? [];
  return (
    <div className="w-full rounded-md bg-white px-4 py-4">
      {isLoading ? (
        <div className="flex h-full w-full items-center rounded-md bg-white">
          <Spinner size={50} />
        </div>
      ) : openPendencies.length > 0 ? (
        <div className="max-w-full overflow-x-auto  scrollbar-thin scrollbar-thumb-[#D9D9D9] scrollbar-thumb-rounded-full">
          <InfiniteTable
            hasNextPage={hasNextPage}
            data={openPendencies}
            columns={columns}
            fetchNextPage={fetchNextPage}
            isFetchingNextPage={isFetchingNextPage}
            alignTextLeft
          />
        </div>
      ) : (
        <div className="flex w-full flex-col items-center justify-center py-10">
          <img src={EmptyIcon} className="w-40" />
          <div className="flex flex-col items-center text-sm">
            <p>Nenhuma pendência em aberto encontrada com esses parâmetros</p>
            <span className="font-semibold text-blue-800">
              Quando surgirem novas, poderá encontrá-las aqui!
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default OpenPendenciesTab;
