import { toast } from 'react-toastify';

import api from '@/services/apiSgft';
import { TurnstileApprovalFilters } from '@/state/TurnstileApprovalFilter.atom';

import { Filter, Option } from '../../types/Filter';
import { PendencyStatus, PendencyType } from '../PendencyConstants';
import {
  TurnstileApprovalFilterOptionsSchema,
  TurnstileApprovalFiltersOptions,
} from '../TurnstileApprovalsConstants';

const fetchFilter = async (
  field: keyof TurnstileApprovalFiltersOptions,
  search: string,
  atom: TurnstileApprovalFilters,
) => {
  try {
    const status = getStatusOptions(atom);
    const type = getTypeOptions(atom);
    const response = await api.get('pendencies/turnstile-approvals-filters', {
      params: {
        status,
        type,
        management: atom?.management,
        teamStructure: atom?.teamStructure,
        area: atom?.area,
        workStation: atom?.workStation,
        scale: atom?.scale,
        regime: atom?.regime,
        sector: atom?.sector,
      },
    });
    const parsedFilters = TurnstileApprovalFilterOptionsSchema.parse(
      response.data,
    );
    const fieldOptions: Option[] = parsedFilters[field] || [];
    return fieldOptions;
  } catch (e) {
    toast.error('Erro ao carregar os dados', {
      theme: 'colored',
      toastId: 'error',
    });
    throw e;
  }
};

export const filters: Filter[] = [
  {
    key: 'management',
    name: 'Gerência',
    options: [],
    isMulti: false,
    asyncFn: (search, atom: TurnstileApprovalFilters) =>
      fetchFilter('managements', search, atom),
    isDisabled: () => false,
  },
  {
    key: 'teamStructure',
    name: 'Estrutura de Turma',
    options: [],
    isMulti: true,
    asyncFn: (search, atom: TurnstileApprovalFilters) =>
      fetchFilter('teamStructures', search, atom),
    isDisabled: () => false,
  },
  {
    key: 'area',
    name: 'Área',
    options: [],
    isMulti: true,
    asyncFn: (search, atom: TurnstileApprovalFilters) =>
      fetchFilter('areas', search, atom),
    isDisabled: () => false,
  },
  {
    key: 'workStation',
    name: 'Local de Trabalho',
    options: [],
    isMulti: true,
    asyncFn: (search, atom: TurnstileApprovalFilters) =>
      fetchFilter('workstations', search, atom),
    isDisabled: () => false,
  },
  {
    key: 'scale',
    name: 'Escala',
    options: [],
    isMulti: true,
    asyncFn: (search, atom: TurnstileApprovalFilters) =>
      fetchFilter('scales', search, atom),
    isDisabled: () => false,
  },
  {
    key: 'regime',
    name: 'Regime',
    options: [],
    isMulti: true,
    asyncFn: (search, atom: TurnstileApprovalFilters) =>
      fetchFilter('regimes', search, atom),
    isDisabled: () => false,
  },
  {
    key: 'sector',
    name: 'Setor',
    options: [],
    isMulti: true,
    asyncFn: (search, atom: TurnstileApprovalFilters) =>
      fetchFilter('sectors', search, atom),
    isDisabled: () => false,
  },
  {
    key: 'type',
    name: 'Tipo',
    options: [],
    isMulti: true,
    asyncFn: (search, atom: TurnstileApprovalFilters) =>
      fetchFilter('types', search, atom),
    isDisabled: () => false,
  },
  {
    key: 'status',
    name: 'Situação',
    options: [],
    isMulti: true,
    asyncFn: (search, atom: TurnstileApprovalFilters) =>
      fetchFilter('statuses', search, atom),
    isDisabled: () => false,
  },
];

function getStatusOptions(atom: TurnstileApprovalFilters) {
  switch (atom.tab) {
    case 2:
      return atom.status.length
        ? atom.status
        : [
            PendencyStatus.CLOSED.toString(),
            PendencyStatus.CANCELLED.toString(),
            PendencyStatus.REFUSED.toString(),
          ];
    default:
      return [PendencyStatus.OPEN.toString()];
  }
}

function getTypeOptions(atom: TurnstileApprovalFilters) {
  switch (atom.tab) {
    case 1:
      return [PendencyType.TURNSTILE_APPROVAL];
    case 2:
      return atom.type.length
        ? atom.type
        : [PendencyType.ALLOCATION_REQUEST, PendencyType.TURNSTILE_APPROVAL];
    default:
      return [PendencyType.ALLOCATION_REQUEST];
  }
}
