import { NewFilter } from '@/types/NewFilter';

export const filters: NewFilter[] = [
  {
    key: 'managements',
    name: 'Gerência',
    isMulti: false,
    isDisabled: () => false,
  },
  {
    key: 'types',
    name: 'Tipo da Pendência',
    isMulti: true,
    isDisabled: () => false,
  },
  {
    key: 'responsibles',
    name: 'Responsável',
    isMulti: true,
    isDisabled: () => false,
  },
  {
    key: 'areas',
    name: 'Área',
    isMulti: true,
    isDisabled: () => false,
  },
  {
    key: 'sectors',
    name: 'Setor',
    isMulti: true,
    isDisabled: () => false,
  },
  {
    key: 'workstations',
    name: 'Local de Trabalho',
    isMulti: true,
    isDisabled: () => false,
  },
  {
    key: 'scales',
    name: 'Escala',
    isMulti: true,
    isDisabled: () => false,
  },
];
