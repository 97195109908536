import { useQueryClient } from '@tanstack/react-query';
import { ColumnDef } from '@tanstack/react-table';
import { isAxiosError } from 'axios';
import { useState } from 'react';
import { FaCheckCircle, FaEye } from 'react-icons/fa';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';

import LightTooltip from '@/components/atoms/LightTooltip';
import ModalConfirmation from '@/components/molecules/ConfirmationModal';
import api from '@/services/apiSgft';

import TableCellDate from '../../components/atoms/TableCellDate';
import TableCellText from '../../components/atoms/TableCellText';
import { removeTimeZone } from '../../utils/formatDate';
import { allocationRequestApproveResponseSchema } from '../AllocationRequest';
import { getStatusName, PendencyStatus } from '../PendencyConstants';
import {
  getCancelledTooltipMessage,
  TurnstileApproval,
} from '../TurnstileApprovalsConstants';

export const columns: Array<ColumnDef<TurnstileApproval>> = [
  {
    id: 'view',
    header: '',
    cell: (value) => {
      const navigate = useNavigate();
      const pendingRequest = value.row.original;
      const handleView = () => {
        navigate(
          `/home/planejamento-de-escalas-e-ferias?colaborador=${pendingRequest.employeeId}&estrutura=${pendingRequest.teamStructureId}&tipo=alocações`,
        );
      };

      return (
        <div className="flex w-20 justify-center">
          <button
            onClick={handleView}
            className="flex size-5 items-center justify-center rounded-full bg-[#1F3FA8] hover:bg-[#4c4c73]"
          >
            <FaEye style={{ color: 'white', cursor: 'pointer' }} size={12} />
          </button>
        </div>
      );
    },
  },
  {
    accessorKey: 'employeeName',
    header: 'Colaborador',
    cell: (value) => {
      return (
        <div className="w-60">
          <TableCellText text={value.getValue() as string} widthFull />
        </div>
      );
    },
  },
  {
    accessorKey: 'management',
    header: 'Gerência',
    cell: (value) => {
      return (
        <div className="w-56">
          <TableCellText text={value.getValue() as string} widthFull />
        </div>
      );
    },
  },
  {
    accessorKey: 'teamStructureName',
    header: 'Estrutura de Turma',
    cell: (value) => {
      const name = value.getValue ? value.getValue() : undefined;
      return (
        <div className="w-60">
          <TableCellText
            text={name ? (name as string).toUpperCase() : ''}
            widthFull
          />
        </div>
      );
    },
  },
  {
    accessorKey: 'team',
    header: 'Turma',
    cell: (value) => {
      return (
        <div className="w-28">
          <TableCellText text={value.getValue() as string} widthFull />
        </div>
      );
    },
  },
  {
    accessorKey: 'area',
    header: 'Área',
    cell: (value) => {
      return (
        <div className="w-36">
          <TableCellText text={value.getValue() as string} widthFull />
        </div>
      );
    },
  },
  {
    accessorKey: 'regime',
    header: 'Regime',
    cell: (value) => {
      return (
        <div className="w-28">
          <TableCellText text={value.getValue() as string} widthFull />
        </div>
      );
    },
  },
  {
    accessorKey: 'scale',
    header: 'Escala',
    cell: (value) => {
      return (
        <div className="w-28">
          <TableCellText text={value.getValue() as string} widthFull />
        </div>
      );
    },
  },
  {
    accessorKey: 'sector',
    header: 'Setor',
    cell: (value) => {
      return (
        <div className="w-40">
          <TableCellText text={value.getValue() as string} widthFull />
        </div>
      );
    },
  },
  {
    accessorKey: 'workStation',
    header: 'Local de Trabalho',
    cell: (value) => {
      return (
        <div className="w-36">
          <TableCellText text={value.getValue() as string} widthFull />
        </div>
      );
    },
  },
  {
    id: 'requestDate',
    accessorKey: 'requestDate',
    header: 'Data',
    cell: (value) => {
      return (
        <div className="w-28">
          <TableCellDate
            date={
              (value.getValue() as string) && value.getValue() !== '-'
                ? removeTimeZone(new Date(value.getValue() as string))
                : undefined
            }
          />
        </div>
      );
    },
  },
  {
    id: 'approve',
    header: '',
    cell: (props) => {
      const data = props.row.original;
      const queryClient = useQueryClient();
      const [isModalOpen, setIsModalOpen] = useState(false);

      const handleConfirmation = async () => {
        try {
          const response = await api.post(
            `/pendencies/close-pendency/${data.pendencyId}`,
          );
          const responseData = allocationRequestApproveResponseSchema.safeParse(
            response.data,
          );
          if (responseData.success) {
            toast.success(responseData.data.message, {
              theme: 'colored',
            });
            queryClient.invalidateQueries(['pending-turnstile-approvals']);
            queryClient.invalidateQueries(['closed-turnstile-approvals']);
          }
        } catch (e) {
          if (isAxiosError(e)) {
            if (e.response?.data?.message) {
              toast.error(e.response?.data?.message, {
                theme: 'colored',
                toastId: 'error',
              });
            }
          } else {
            toast.error('Não foi possível enviar a solicitação', {
              theme: 'colored',
              toastId: 'error',
            });
          }
        }
      };

      const handleApproveClick = () => {
        setIsModalOpen(true);
      };

      return (
        <div className="flex w-20 justify-center">
          <button onClick={handleApproveClick}>
            <FaCheckCircle
              style={{ color: '#108a6d', cursor: 'pointer' }}
              size={18}
            />
          </button>
          {isModalOpen && (
            <ModalConfirmation
              title="Liberação de Catraca"
              description="Deseja confirmar a liberação da catraca?"
              descriptionClassName="whitespace-pre-wrap"
              confirmAction={handleConfirmation}
              isOpen={isModalOpen}
              onClose={() => setIsModalOpen(false)}
              discartAction={() => setIsModalOpen(false)}
            />
          )}
        </div>
      );
    },
    enableSorting: false,
  },
];

const requestDateColumnIndex = columns.findIndex(
  (column) => column.id === 'requestDate',
);

const closeColumns: Array<ColumnDef<TurnstileApproval>> = [
  {
    accessorKey: 'completionDate',
    header: 'Concluido em',
    cell: (value) => {
      return (
        <div className="w-28">
          <TableCellDate
            date={
              (value.getValue() as string) && value.getValue() !== '-'
                ? removeTimeZone(new Date(value.getValue() as string))
                : undefined
            }
          />
        </div>
      );
    },
  },
  {
    accessorKey: 'type',
    header: 'Tipo',
    cell: (value) => {
      return (
        <div className="w-56">
          <TableCellText text={value.getValue() as string} width="full" />
        </div>
      );
    },
  },
  {
    accessorKey: 'status',
    header: 'Situação',
    cell: (value) => {
      const [tooltipEnabled, setTooltipEnabled] = useState(false);
      const { status, type } = value.row.original;
      const tooltipMessage = getCancelledTooltipMessage(type, status);
      const label = getStatusName(status);
      const bgColor =
        status === PendencyStatus.CLOSED ? 'bg-[#10AE88]' : 'bg-[#F4503A]';
      const textColor = 'text-white';

      const handleMouseEnter = () => {
        setTooltipEnabled(true);
      };

      const handleMouseLeave = () => {
        setTooltipEnabled(false);
      };

      return (
        <LightTooltip
          title={tooltipMessage}
          arrow
          placement="top-end"
          open={tooltipEnabled}
          onClose={handleMouseLeave}
        >
          <div
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className="w-60"
          >
            <div
              className={`w-56 font-graphie text-[11px] ${textColor} flex items-center justify-center text-nowrap rounded p-1 tracking-widest ${bgColor}`}
            >
              <p className="cursor-default">{label}</p>
            </div>
          </div>
        </LightTooltip>
      );
    },
  },
];

const closedColumns = columns
  .map((column) => ({ ...column }))
  .filter((column) => column.id !== 'approve');

closedColumns.splice(requestDateColumnIndex + 1, 0, ...closeColumns);

export { closedColumns };
