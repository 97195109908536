import { useInfiniteQuery } from '@tanstack/react-query';
import { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useRecoilState, useRecoilValue } from 'recoil';

import NoPermissionText from '@/components/atoms/NoPermissionText';
import { RequirementControlTemplateButton } from '@/components/atoms/RequirementControlTemplateButton';
import Spinner from '@/components/atoms/Spinner';
import { RequirementControlDownloadButton } from '@/components/molecules/RequirementControlDownloadButton';
import { RequirementControlHistoryDownloadButton } from '@/components/molecules/RequirementControlHistoryDownloadButton';
import { RequirementControlUploadButton } from '@/components/molecules/RequirementControlUploadButton';
import RequirementControlCard from '@/components/organisms/RequirementControlCard';
import { filters } from '@/constants/filters/RequirementControlFilter';
import { requirementControlAtom } from '@/state/RequirementControl.atom';
import { RequirementControlUpdateIndicatorsAtom } from '@/state/RequirementControlUpdateIndicators.atom';
import {
  EmployeeWithRequirementControl,
  Indicators,
} from '@/types/RequirementControl';
import { userCanSeeRequirementControl } from '@/utils/handleSavePermissions';

import EmptyIcon from '../../assets/icons/empty.svg';
import Cards from '../../components/molecules/Cards';
import ExpandableSubtitle from '../../components/molecules/ExpandableSubtitle';
import SideFilter from '../../components/molecules/SideFilter';
import ViewScheduleWrapper from '../../components/molecules/ViewScheduleWrapper';
import api from '../../services/apiSgft';

const RequirementControlPage = () => {
  const filterValues = useRecoilValue(requirementControlAtom);
  const [filteredData, setFilteredData] = useState(filterValues);
  const [indicators, setIndicators] = useState<Indicators>();
  const [updateIndicators, setUpdateIndicators] = useRecoilState(
    RequirementControlUpdateIndicatorsAtom,
  );
  const observerRef = useRef<HTMLDivElement | null>(null); // Ref para o observer do último elemento

  const fetchControl = async ({
    pageParam = 1,
  }): Promise<{
    count: number;
    nextPage: number | null;
    employeeRequirementControls: EmployeeWithRequirementControl[];
  }> => {
    try {
      const response = await api.get(`requirement-control`, {
        params: {
          size: 20,
          page: pageParam,
          employee: filteredData.employee,
          role: filteredData.role,
          management: filteredData.management,
          areaCoordinator: filteredData.areaCoordinator,
          pole: filteredData.pole,
          workstation: filteredData.workstation,
          status: filteredData.status,
          situation: filteredData.situation,
          requirementType: filteredData.requirementType,
          requirementClass: filteredData.requirementClass,
          requirement: filteredData.requirement,
        },
      });
      return response.data;
    } catch (e) {
      toast.error('Erro ao carregar os dados', {
        theme: 'colored',
        toastId: 'error',
      });
      throw e;
    }
  };

  const {
    data,
    isLoading,
    isError,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useInfiniteQuery(['requirement-control', filteredData], fetchControl, {
    retry: false,
    getNextPageParam: (actualPage) => {
      return actualPage.nextPage;
    },
  });
  const observerCallback = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      if (entries[0].isIntersecting && hasNextPage && !isFetchingNextPage) {
        fetchNextPage();
      }
    },
    [hasNextPage, isFetchingNextPage, fetchNextPage],
  );
  useEffect(() => {
    const observer = new IntersectionObserver(observerCallback, {
      root: null,
      rootMargin: '100px',
      threshold: 1.0,
    });
    if (observerRef.current) observer.observe(observerRef.current);
    return () => observer.disconnect();
  }, [observerCallback]);
  const handleApplyFilter = (isReseting: boolean) => {
    if (isReseting)
      setFilteredData(() => ({
        employee: [],
        role: [],
        management: [],
        areaCoordinator: [],
        pole: [],
        workstation: [],
        situation: [],
        status: [],
        requirementType: [],
        requirementClass: [],
        requirement: [],
      }));
    else {
      setFilteredData(() => ({
        ...filterValues,
      }));
    }
  };

  const requirementsControl =
    data?.pages.flatMap((page) => page.employeeRequirementControls) ?? [];

  useEffect(() => {
    const getIndicators = async () => {
      try {
        const response = await api.get('requirement-control/indicators', {
          params: {
            employee: filteredData.employee,
            role: filteredData.role,
            management: filteredData.management,
            areaCoordinator: filteredData.areaCoordinator,
            pole: filteredData.pole,
            workstation: filteredData.workstation,
            status: filteredData.status,
            situation: filteredData.situation,
            requirementType: filteredData.requirementType,
            requirementClass: filteredData.requirementClass,
            requirement: filteredData.requirement,
          },
        });
        setIndicators(response.data);
        setUpdateIndicators(false);
      } catch (e) {
        toast.error('Erro ao carregar indicadores', {
          theme: 'colored',
          toastId: 'error',
        });
        throw e;
      }
    };
    getIndicators();
  }, [filteredData, updateIndicators]);

  if (!userCanSeeRequirementControl()) return <NoPermissionText />;

  return (
    <div className="relative flex max-h-screen w-full flex-col items-start">
      <div className="absolute -top-10 right-20 flex items-center">
        <RequirementControlTemplateButton />
        <RequirementControlUploadButton filteredData={filteredData} />
        <RequirementControlDownloadButton filteredData={filteredData} />
        <RequirementControlHistoryDownloadButton filteredData={filteredData} />
      </div>
      <SideFilter
        refetchOnChange
        filters={filters}
        atom={requirementControlAtom}
        applyChanges={handleApplyFilter}
      />
      <ViewScheduleWrapper
        isError={isError}
        isLoading={isLoading}
        className="w-full"
      >
        <div className="w-full">
          {indicators?.requirementPendencies && (
            <ExpandableSubtitle
              subtitle={'Pendências por Requisito'}
              hasTooltip
            >
              {indicators?.requirementPendencies.length > 0 ? (
                <Cards
                  cards={indicators?.requirementPendencies.map((el) => ({
                    value: el.count,
                    status: 'grey',
                    title: el.name,
                  }))}
                />
              ) : (
                <div className="p-2 font-graphie text-[15px] text-[#4A4A4A]">
                  Não existem requisitos com pendências
                </div>
              )}
            </ExpandableSubtitle>
          )}
          {indicators?.statusCounts && (
            <ExpandableSubtitle subtitle={'Requisito por Status'}>
              <Cards
                cards={[
                  {
                    value: indicators.statusCounts.Vencidos,
                    status: 'red',
                    title: 'Vencidos',
                  },
                  {
                    value: indicators.statusCounts.VenceEm1Mes,
                    status: '#dbdb00',
                    title: 'Vence em 1 mês',
                  },
                  {
                    value: indicators.statusCounts.VenceEm3Meses,
                    status: '#dbdb00',
                    title: 'Vence em 3 meses',
                  },
                  {
                    value: indicators.statusCounts.Validos,
                    status: 'green',
                    title: 'Válidos',
                  },
                ]}
              />
            </ExpandableSubtitle>
          )}
        </div>
        <div className="overflow-auto">
          {requirementsControl?.length ? (
            <div className="custom-scrollbar flex h-full flex-col gap-2 overflow-y-scroll rounded-xl pb-4 pr-3">
              {requirementsControl?.map(
                (
                  employeeWithRequirementControl: EmployeeWithRequirementControl,
                  index: number,
                ) => {
                  return (
                    <div
                      key={employeeWithRequirementControl.employee.id}
                      className="relative w-full"
                      ref={
                        index === requirementsControl.length - 1
                          ? observerRef
                          : null
                      }
                    >
                      <RequirementControlCard
                        employeeWithRequirementControl={
                          employeeWithRequirementControl
                        }
                      />
                    </div>
                  );
                },
              )}
              {isFetchingNextPage && (
                <div className="flex w-full justify-center p-4">
                  <Spinner />
                </div>
              )}
            </div>
          ) : (
            <div className="flex h-full w-full flex-col items-center justify-center rounded-md bg-white">
              <img src={EmptyIcon} className="w-40" />
              <div className="w-full text-center text-sm">
                <p>Nenhum requerimento foi encontrado com esses parâmetros</p>
                <span className="font-semibold text-blue-800">
                  Cadastre um ou altere os valores dos filtros para visualizar a
                  tabela!
                </span>
              </div>
            </div>
          )}
        </div>
      </ViewScheduleWrapper>
    </div>
  );
};

export default RequirementControlPage;
