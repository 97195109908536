import { ChartFilterType } from '@/state/ChartFilter.atom';

import api from '../../services/apiSgft';
import { Filter, Option } from '../../types/Filter';
import {
  RoleChartFilterOptionsSchema,
  RoleChartFiltersOptions,
} from '../ChartConstants';

const fetchFilterJobs = async (
  field: keyof RoleChartFiltersOptions,
  search?: string,
  atom?: ChartFilterType,
) => {
  const response = await api.get(`position/filter`, {
    params: {
      boardFilter: atom?.areaBoardFilter[0],
      ...(field === 'boards' && { areaBoardSearch: search }),
      managementFilter: atom?.managementFilter[0],
      ...(field === 'managements' && { managementSearch: search }),
      coordinationFilter: atom?.coordinationFilter[0],
      companiesFilter: atom?.companiesFilter,
      // TODO: descomentar ao adicionar os filtros de local de trabalho, polo, regime e position
      // ...(field === 'workStation' && { workStationSearch: search }),
      // poleFilter: atom?.poleFilter[0],
      // ...(field === 'pole' && { poleSearch: search }),
      // scaleFilter: atom?.scaleFilter[0],
      // ...(field === 'scale' && { scaleSearch: search }),
      // roleFilter: atom?.roleFilter[0],
      // ...(field === 'role' && { roleSearch: search }),
    },
  });
  const parsedFilters = RoleChartFilterOptionsSchema.parse(response.data);
  const fieldOptions: Option[] = parsedFilters[field] || [];
  return fieldOptions;
};

export const filters: Filter[] = [
  {
    key: 'companiesFilter',
    name: 'Empresa',
    options: [],
    isMulti: true,
    asyncFn: (search: string, atom?: ChartFilterType) =>
      fetchFilterJobs('companies', search, atom),
    isSearchable: false,
    isDisabled: () => false,
  },
  {
    key: 'areaBoardFilter',
    name: 'Diretoria',
    options: [],
    isMulti: false,
    asyncFn: (search: string, atom?: ChartFilterType) =>
      fetchFilterJobs('boards', search, atom),
    isSearchable: false,
    isDisabled: () => false,
  },
  {
    key: 'managementFilter',
    name: 'Gerência',
    options: [],
    isMulti: false,
    asyncFn: (search: string, atom?: ChartFilterType) =>
      fetchFilterJobs('managements', search, atom),
    isSearchable: false,
    isDisabled: () => false,
  },
  {
    key: 'coordinationFilter',
    name: 'Coordenação',
    options: [],
    isMulti: false,
    asyncFn: (search: string, atom?: ChartFilterType) =>
      fetchFilterJobs('coordinations', search, atom),
    isSearchable: false,
    isDisabled: () => false,
  },
  // {
  //   key: 'poleFilter',
  //   name: 'Polo',
  //   options: [],
  //   isMulti: false,
  //   asyncFn: (search: string, atom?: ChartFilterType) =>
  //     fetchFilterJobs('pole', search, atom),
  //   isSearchable: false,
  //   isDisabled: () => false,
  // },
  // {
  //   key: 'workStationFilter',
  //   name: 'Local de trabalho',
  //   options: [],
  //   isMulti: false,
  //   asyncFn: (search: string, atom?: ChartFilterType) =>
  //     fetchFilterJobs('workStation', search, atom),
  //   isSearchable: false,
  //   isDisabled: () => false,
  // },
  // {
  //   key: 'scaleFilter',
  //   name: 'Escala',
  //   options: [],
  //   isMulti: false,
  //   asyncFn: (search: string, atom?: ChartFilterType) =>
  //     fetchFilterJobs('scale', search, atom),
  //   isSearchable: false,
  //   isDisabled: () => false,
  // },
  // {
  //   key: 'roleFilter',
  //   name: 'Cargo',
  //   options: [],
  //   isMulti: false,
  //   asyncFn: (search: string, atom?: ChartFilterType) =>
  //     fetchFilterJobs('role', search, atom),
  //   isSearchable: false,
  //   isDisabled: () => false,
  // },
];
