import * as r from '@recoiljs/refine';
import { atom } from 'recoil';
import { urlSyncEffect } from 'recoil-sync';

import { PendencyStatus, PendencyType } from '@/constants/PendencyConstants';

export interface TurnstileApprovalFilters
  extends Record<string, string[] | number | string | number[]> {
  tab: number;
  management: string[];
  area: string[];
  sector: string[];
  workStation: string[];
  scale: string[];
  type: string[];
  status: string[];
}
const stringArrayChecker = r.array(r.string()) as r.Checker<string[]>;

export const TurnstileApprovalFiltersAtom = atom<TurnstileApprovalFilters>({
  key: 'turnstileApprovalFilters',
  default: {
    tab: 0,
    management: [],
    area: [],
    sector: [],
    workStation: [],
    scale: [],
    type: [PendencyType.ALLOCATION_REQUEST],
    status: [PendencyStatus.OPEN.toString()],
  },
  effects: [
    urlSyncEffect<TurnstileApprovalFilters>({
      refine: r.object({
        tab: r.number(),
        management: stringArrayChecker,
        area: stringArrayChecker,
        sector: stringArrayChecker,
        workStation: stringArrayChecker,
        scale: stringArrayChecker,
        type: stringArrayChecker,
        status: stringArrayChecker,
      }),
      history: 'push',
    }),
  ],
});
