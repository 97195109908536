import { useInfiniteQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';

import { PendencyStatus, PendencyType } from '@/constants/PendencyConstants';
import { pendingRequestColumns } from '@/constants/tableColumns/PendingRequestColumns';
import { PendingRequestListSchema } from '@/constants/TurnstileApprovalsConstants';
import api from '@/services/apiSgft';
import { TurnstileApprovalFiltersAtom } from '@/state/TurnstileApprovalFilter.atom';

import EmptyIcon from '../../assets/icons/empty.svg';
import Spinner from '../atoms/Spinner';
import InfiniteTable from './InfiniteTable';

const PendingApprovalsTab = () => {
  const [filteredData, setFilteredData] = useRecoilState(
    TurnstileApprovalFiltersAtom,
  );

  const getAllPendingApprovals = async ({ pageParam = 1 }) => {
    const response = await api.get(
      'team-employee/pending-allocation-requests',
      {
        params: {
          size: 20,
          page: pageParam,
          status: [PendencyStatus.OPEN],
          type: [PendencyType.ALLOCATION_REQUEST],
          teamStructure: filteredData.teamStructure,
          area: filteredData.area,
          workStation: filteredData.workStation,
          sector: filteredData.sector,
          regime: filteredData.regime,
          scale: filteredData.scale,
        },
      },
    );

    const data = PendingRequestListSchema.parse(response.data);
    return data;
  };

  const { data, isLoading, fetchNextPage, isFetchingNextPage, hasNextPage } =
    useInfiniteQuery(
      ['pending-allocation-requests', filteredData],
      getAllPendingApprovals,
      {
        retry: false,
        getNextPageParam: (actualPage) => {
          return actualPage.nextPage;
        },
      },
    );

  const pendingRequests =
    data?.pages.flatMap((page) => page.pendingRequests) ?? [];

  useEffect(() => {
    setFilteredData((prev) => ({
      ...prev,
      tab: 0,
      status: [],
      type: [],
    }));
  }, []);

  return (
    <div className="w-full rounded-md bg-white px-4 py-4">
      {isLoading ? (
        <div className="flex h-full w-full items-center rounded-md bg-white">
          <Spinner size={50} />
        </div>
      ) : pendingRequests.length > 0 ? (
        <div className="max-w-full overflow-x-auto scrollbar-thin scrollbar-thumb-[#D9D9D9] scrollbar-thumb-rounded-full">
          <InfiniteTable
            hasNextPage={hasNextPage}
            data={pendingRequests}
            columns={pendingRequestColumns}
            fetchNextPage={fetchNextPage}
            isFetchingNextPage={isFetchingNextPage}
            alignTextLeft={true}
            columnVisibility={{ status: false }}
          />
        </div>
      ) : (
        <div className="flex h-full w-full flex-col items-center justify-center py-10">
          <img src={EmptyIcon} className="w-40" />
          <div className="flex flex-col items-center text-sm">
            <span className="font-semibold text-blue-800">
              Nenhuma solicitação foi encontrada
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default PendingApprovalsTab;
