import { Popover } from '@headlessui/react';
import { TbPinnedFilled } from 'react-icons/tb';

import { getColor } from '@/utils/getColorByChart';
import { subtitleEntries } from '@/utils/getSubtitleByChart';

import { cn } from '../../utils/cn';

const ChartSubtitle = () => {
  return (
    <Popover className="relative">
      <Popover.Button className="mx-5 h-7 whitespace-nowrap rounded-full border-2 border-blue-800 px-4 text-xs font-medium  text-blue-800">
        Ver Legendas
      </Popover.Button>

      <Popover.Panel className="absolute right-0 z-10">
        <div className="mt-4 flex  flex-col rounded-sm bg-[#f1f1f1] p-4 text-xs text-gray-600">
          <div className="absolute right-7 top-0 mt-1 h-0 w-0 border-b-[12px] border-l-[12px] border-r-[12px] border-b-[#f1f1f1] border-l-transparent border-r-transparent" />
          <h4 className="mb-2 font-semibold text-blue-800">Legenda</h4>
          <div className="flex flex-col gap-y-1">
            {subtitleEntries.map(([value, legend]: [string, string]) => {
              const textColor = getColor(`${value}-text`);
              return (
                <div
                  className="mb-[8px] flex w-full items-center gap-2 whitespace-nowrap"
                  key={`legend-${value}`}
                >
                  <div
                    className={cn(
                      `flex h-[22px] w-14 items-center justify-center rounded text-[9px] font-bold ${textColor}`,
                      getColor(value),
                    )}
                  >
                    {(value === 'ADM' || value === 'TURNO') && value}
                  </div>
                  {legend}
                </div>
              );
            })}
            <div className="flex items-center gap-2">
              <div className="flex h-[22px] w-14 items-center justify-center">
                <TbPinnedFilled className="text-[#FD8E28]" size={14} />
              </div>
              <div>Alocação Fixa</div>
            </div>
            <div className="flex items-center gap-2">
              <div className="flex h-[22px] w-14 items-center justify-center">
                <TbPinnedFilled className="text-[#9B54FF]" size={14} />
              </div>
              <div>Alocação Fixa - PJ</div>
            </div>
          </div>
        </div>
      </Popover.Panel>
    </Popover>
  );
};

export default ChartSubtitle;
