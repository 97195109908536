import { MouseEvent, useState } from 'react';

import { cn } from '@/utils/cn';

import { getFirstAndLastName } from '../../utils/getFirstAndLastName';
import LightTooltip from './LightTooltip';

interface Props {
  text: string;
  isName?: boolean;
  align?: string;
  color?: string;
  width?: string;
  paddingX?: string;
  ifEmptyString?: string;
  textAlign?: string;
  className?: string;
  widthFull?: boolean;
}

const TableCellText = ({
  text,
  isName,
  align,
  color,
  width,
  paddingX,
  ifEmptyString,
  textAlign,
  className = '',
  widthFull,
}: Props) => {
  const [tooltipEnabled, setTooltipEnabled] = useState(false);

  const handleShouldShow = ({ currentTarget }: MouseEvent<Element>) => {
    if (currentTarget.scrollWidth > currentTarget.clientWidth || isName) {
      setTooltipEnabled(true);
    }
  };

  return (
    <LightTooltip
      title={text}
      open={tooltipEnabled}
      onClose={() => setTooltipEnabled(false)}
      arrow
      placement="top-end"
    >
      {text && text !== '-' ? (
        <p
          onMouseEnter={handleShouldShow}
          className={cn(
            'max-w-full justify-start overflow-hidden text-ellipsis whitespace-nowrap py-2 text-[12px]',
            isName
              ? 'text- [#4B5563] font-semibold'
              : 'font-normal text-[#4A4A4A]',
            width ? `w-[${width}]` : 'w-[8rem]',
            align ? `justify-${align}` : 'justify-start',
            paddingX ? `px-[${paddingX}]` : 'px-0',
            color ? `text-[${color}]` : '',
            textAlign || '',
            className,
            widthFull && 'w-full',
          )}
        >
          {isName ? getFirstAndLastName(text) : text}
        </p>
      ) : (
        <div className="w-full text-ellipsis text-center text-[12px] text-[#4A4A4A]">
          {ifEmptyString}
        </div>
      )}
    </LightTooltip>
  );
};

export default TableCellText;
