import { z } from 'zod';

const PeopleChartFilterOption = z
  .object({
    id: z
      .union([z.number(), z.string()])
      .transform((id) => String(id))
      .pipe(z.string()),
    name: z.string(),
  })
  .transform(({ id, name }) => ({
    value: id,
    label: name,
  }));

const PeopleChartFilterSingleOption = z.string().transform((value) => {
  return {
    value,
    label: value,
  };
});

export const PeopleChartFilterOptionsSchema = z.object({
  areaBoard: z.array(PeopleChartFilterSingleOption).default([]),
  management: z.array(PeopleChartFilterSingleOption).default([]),
  coordination: z.array(PeopleChartFilterSingleOption).default([]),
  // TODO: descomentar quando o filtro de colaborador ficar pronto no back
  // employee: z.array(PeopleChartFilterOption).default([]),
});

export type PeopleChartFiltersOptions = z.infer<
  typeof PeopleChartFilterOptionsSchema
>;

const RoleChartFilterOption = z
  .object({
    id: z
      .union([z.number(), z.string()])
      .transform((id) => String(id))
      .pipe(z.string()),
    name: z.string(),
  })
  .transform(({ id, name }) => ({
    value: id,
    label: name,
  }));

const RoleChartFilterSingleOption = z.string().transform((value) => {
  return {
    value,
    label: value,
  };
});

export const RoleChartFilterOptionsSchema = z.object({
  companies: z.array(RoleChartFilterSingleOption).default([]),
  boards: z.array(RoleChartFilterSingleOption).default([]),
  managements: z.array(RoleChartFilterSingleOption).default([]),
  coordinations: z.array(RoleChartFilterSingleOption).default([]),
  // TODO: descomentar quando os filtros de local mde trabalho, polo, regime e posição ficarem prontso no back
  // workStation: z.array(RoleChartFilterSingleOption).default([]),
  // pole: z.array(RoleChartFilterSingleOption).default([]),
  // scale: z.array(RoleChartFilterSingleOption).default([]),
  // role: z.array(RoleChartFilterOption).default([]),
});

export type RoleChartFiltersOptions = z.infer<
  typeof RoleChartFilterOptionsSchema
>;
