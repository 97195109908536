import { Dialog, Transition } from '@headlessui/react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { ptBR } from '@mui/x-date-pickers/locales';
import { useQuery } from '@tanstack/react-query';
import br from 'date-fns/locale/pt-BR';
import { useState } from 'react';
import { FiMinusCircle, FiPlusCircle } from 'react-icons/fi';
import { toast } from 'react-toastify';
import { useRecoilState, useResetRecoilState } from 'recoil';

import {
  Reallocation,
  ReallocationSchema,
} from '@/constants/AllocationConstants';
import {
  AllocationRequestHistoryList,
  AllocationRequestHistoryListSchema,
} from '@/constants/AllocationRequest';
import { allocationRequestColumns } from '@/constants/tableColumns/AllocationRequestColumns';
import { DeallocationEditData } from '@/state/AllocationDataType.atom';
import { AllocationEditData } from '@/state/AllocationEditData';
import { AllocationRequestEditData } from '@/state/AllocationRequestEditData.atom';
import { AllocationTypeAtom } from '@/state/AllocationType.atom';
import { removeTimeZone } from '@/utils/formatDate';
import { userIsRhOrAdmin } from '@/utils/handleSavePermissions';

import api from '../../services/apiSgft';
import { getFirstAndLastName } from '../../utils/getFirstAndLastName';
import { AllocationColumns } from '../atoms/AllocationColumns';
import { Button } from '../atoms/Button';
import CloseButton from '../atoms/CloseButton';
import Spinner from '../atoms/Spinner';
import SubtitleText from '../atoms/SubtitleText';
import TextComponent from '../atoms/TextComponent';
import AllocationCard from '../molecules/AllocationCard';
import ExpandableSubtitle from '../molecules/ExpandableSubtitle';
import Table from './Table';

interface Props {
  employeeId: number | undefined;
  handleClose: () => void;
}

export default function AllocationModal({ employeeId, handleClose }: Props) {
  const [type, setType] = useRecoilState(AllocationTypeAtom);
  const resetAllocationEditData = useResetRecoilState(AllocationEditData);
  const resetDeallocationEditData = useResetRecoilState(DeallocationEditData);
  const resetAllocationRequestEditData = useResetRecoilState(
    AllocationRequestEditData,
  );
  const [year, setYear] = useState(new Date());
  const isRhOrAdmin = userIsRhOrAdmin();

  const getData = async () => {
    if (employeeId) {
      try {
        const response = await api.get('/team-employee', {
          params: {
            employeeId,
            year: removeTimeZone(new Date(year)).getFullYear(),
          },
        });
        const parsedData = ReallocationSchema.parse(response.data);
        return parsedData;
      } catch (e) {
        toast.error('Erro ao carregar os dados', {
          theme: 'colored',
          toastId: 'error',
        });
        throw e;
      }
    }
  };

  const getRequests = async () => {
    if (employeeId) {
      try {
        const response = await api.get(
          'team-employee/allocation-request-history',
          {
            params: {
              employeeId,
              year: removeTimeZone(new Date(year)).getFullYear(),
            },
          },
        );
        const parsedSchema = AllocationRequestHistoryListSchema.parse(
          response.data,
        );
        return parsedSchema;
      } catch (e) {
        toast.error('Erro ao carregar os dados', {
          theme: 'colored',
          toastId: 'error',
        });
        throw e;
      }
    }
  };

  const { data, isLoading, isError } = useQuery<Reallocation | undefined>(
    ['reallocation', employeeId, removeTimeZone(new Date(year)).getFullYear()],
    getData,
  );

  const { data: requests } = useQuery<AllocationRequestHistoryList | undefined>(
    [
      'allocation-requests',
      employeeId,
      removeTimeZone(new Date(year)).getFullYear(),
    ],
    getRequests,
  );

  const saveYear = (date: Date | null) => {
    if (date) {
      setYear(date);
    }
  };

  const onClose = () => {
    resetAllocationEditData();
    resetDeallocationEditData();
    resetAllocationRequestEditData();
    setType('');
    handleClose();
  };

  return (
    <Transition appear show={true}>
      <Dialog className="relative z-50" onClose={onClose} open={true}>
        <div className="fixed inset-0 flex w-screen items-center justify-center bg-black/30 p-4">
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="custom-scrollbar max-h-[100vh] w-[52rem] flex-col justify-between overflow-auto overflow-y-scroll rounded-lg bg-white">
              <div className="w-full flex-col justify-between p-5">
                <div className="flex h-auto items-center justify-between">
                  <div className="flex justify-between">
                    <SubtitleText
                      className="text-[20px]"
                      subtitle={'Realocações'}
                    />
                  </div>
                  <CloseButton onClick={onClose} className="mt-[-1rem]" />
                </div>
                {isLoading && (
                  <div className="flex h-72 items-center">
                    <Spinner />
                  </div>
                )}
                {isError && (
                  <div className="flex h-72 items-center justify-center">
                    Erro ao carregar os dados
                  </div>
                )}
                {data && (
                  <div className="mt-5">
                    <div className="mb-5 flex w-full justify-between">
                      <TextComponent
                        title="Nome"
                        width="12rem"
                        value={getFirstAndLastName(data.name)}
                      />
                      <TextComponent
                        title="Chapa"
                        width="12rem"
                        value={data.employeeNumber}
                      />
                      <TextComponent
                        title="Colaborador Ferista"
                        width="10rem"
                        value={data.vacationReliefWorker ? 'SIM' : 'NÃO'}
                      />
                      <div>
                        <div className="text-[12px] text-[#4a4a4a]">Ano</div>
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          localeText={
                            ptBR.components.MuiLocalizationProvider.defaultProps
                              .localeText
                          }
                          adapterLocale={br}
                        >
                          <DatePicker
                            key={11}
                            views={['year']}
                            onChange={(date) => saveYear(date)}
                            value={year}
                            slotProps={{ textField: { size: 'small' } }}
                            sx={{
                              width: '8rem',
                              '& .MuiInputBase-root': {
                                height: '2rem',
                                fontSize: '12px',
                              },
                            }}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                    <>
                      <ExpandableSubtitle subtitle="Histórico de realocações">
                        {data.allocationHistories.length > 0 ? (
                          <div className="mb-5">
                            <Table
                              columns={AllocationColumns}
                              data={data.allocationHistories}
                              isWhiteTable
                            />
                          </div>
                        ) : (
                          <div className="flex justify-center p-10 font-graphie text-[13px] font-bold text-[#747474]">
                            Ainda não existem alocações
                          </div>
                        )}
                      </ExpandableSubtitle>
                      <ExpandableSubtitle
                        subtitle="Solicitações de realocação"
                        defaultIsOpen={!!(requests && requests.length > 0)}
                      >
                        {requests && requests.length > 0 ? (
                          <div className="mb-5">
                            <Table
                              columns={allocationRequestColumns}
                              data={requests}
                              isWhiteTable
                            />
                          </div>
                        ) : (
                          <div className="flex justify-center p-10 font-graphie text-[13px] font-bold text-[#747474]">
                            Ainda não existem solicitações de realocação
                          </div>
                        )}
                      </ExpandableSubtitle>
                      {type === '' && (
                        <div className="flex justify-between">
                          <div className="flex cursor-pointer items-center justify-start rounded-md hover:bg-[#e9eaf1]">
                            <FiMinusCircle
                              style={{
                                fontSize: '1rem',
                                color: '#193cb9',
                                marginLeft: '0.5rem',
                              }}
                            />
                            <Button
                              type="button"
                              variant="link"
                              className="w-[160px] font-semibold text-[#193CB9] underline"
                              onClick={() => setType('deallocate')}
                            >
                              Desalocar colaborador
                            </Button>
                          </div>
                          <div className="flex cursor-pointer items-center justify-start rounded-md hover:bg-[#e9eaf1]">
                            <FiPlusCircle
                              style={{
                                fontSize: '1rem',
                                color: '#193cb9',
                                marginLeft: '0.5rem',
                              }}
                            />
                            <Button
                              type="button"
                              variant="link"
                              className="w-[150px] font-semibold text-[#193CB9] underline"
                              onClick={() => setType('create')}
                            >
                              {isRhOrAdmin
                                ? 'Atualizar realocação'
                                : 'Solicitar realocação'}
                            </Button>
                          </div>
                        </div>
                      )}
                      <AllocationCard employeeId={employeeId} data={data} />
                    </>
                  </div>
                )}
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
