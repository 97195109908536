import { ColumnDef } from '@tanstack/react-table';

import { NewJobTitle } from '@/types/NewJobTitle';
import { removeTimeZone } from '@/utils/formatDate';

import TableCellAllocation from './TableCellAllocation';
import TableCellDate from './TableCellDate';
import TableData from './TableData';

export const JobTitleColumns: Array<ColumnDef<NewJobTitle>> = [
  {
    accessorKey: 'version',
    header: 'Versão',
    meta: {
      headerClassName: 'pr-2 text-xs min-w-[10rem] uppercase text-center',
    },
    cell: (props) => {
      console.log(props.row.original);
      return (
        <div className="flex w-full items-center justify-center">
          <TableCellAllocation
            text={(props.getValue() as string) || '0'}
            width="12rem"
            containerClassName="justify-center"
          />
        </div>
      );
    },
    enableSorting: false,
  },
  {
    accessorKey: 'createdAt',
    header: 'Data de alteração',
    meta: {
      headerClassName: 'pr-2 text-xs min-w-[10rem] uppercase',
    },
    cell: (props) => {
      return (
        <div className="w-[5rem]">
          <TableCellDate
            date={removeTimeZone(new Date(props.getValue() as string))}
            className="text-[11px]"
          />
        </div>
      );
    },
    enableSorting: false,
  },
  {
    accessorKey: 'title',
    header: 'Título do Cargo',
    meta: {
      headerClassName: 'pr-2 text-xs min-w-[12rem] uppercase',
    },
    cell: (props) => {
      return (
        <div className="w-[12rem]">
          <TableData
            tableValue={props.getValue() as string}
            className="text-[11px]"
          />
        </div>
      );
    },
    enableSorting: false,
  },
  {
    accessorKey: 'seniority',
    header: 'Nível',
    meta: {
      headerClassName: 'pr-2 text-xs min-w-[8rem] uppercase',
    },
    cell: (props) => {
      return (
        <div className="w-[4rem]">
          <TableData
            tableValue={props.getValue() as string}
            width="4rem"
            className="text-[11px]"
          />
        </div>
      );
    },
    enableSorting: false,
  },
  {
    accessorKey: 'boards',
    header: 'Diretoria',
    meta: {
      headerClassName: 'pr-2 text-xs min-w-[15rem] uppercase',
    },
    cell: (props) => {
      const boards = props.getValue() as Array<string>;
      return (
        <div className="w-[12rem]">
          <TableCellAllocation text={boards.join('; ')} width="12rem" />
        </div>
      );
    },
    enableSorting: false,
  },
  {
    accessorKey: 'managements',
    header: 'Gerência',
    meta: {
      headerClassName: 'pr-2 text-xs min-w-[15rem] uppercase',
    },
    cell: (props) => {
      const managements = props.getValue() as Array<string>;

      return (
        <div className="w-[12rem]">
          <TableCellAllocation text={managements.join('; ')} width="12rem" />
        </div>
      );
    },
    enableSorting: false,
  },
  {
    accessorKey: 'workStations',
    header: 'Local de Trabalho',
    meta: {
      headerClassName: 'pr-2 text-xs min-w-[15rem] uppercase',
    },
    cell: (props) => {
      const workStation = (
        props.getValue() as Array<{
          ghe: string;
          workStation: string;
        }>
      ).map((it) => it.workStation);
      return (
        <div className="w-[12rem]">
          <TableCellAllocation text={workStation.join('; ')} width="12rem" />
        </div>
      );
    },
    enableSorting: false,
  },
  {
    accessorKey: 'objective',
    header: 'Objetivo do cargo',
    meta: {
      headerClassName: 'pr-2 text-xs min-w-[20rem] uppercase',
    },
    cell: (props) => {
      return (
        <div className="w-[25rem] pr-4 text-[11px] text-[#4A4A4A]">
          {props.getValue() ? (
            <TableCellAllocation
              text={props.getValue() as string}
              width="17rem"
              reduceStringIndex={250}
            />
          ) : (
            <>-</>
          )}
        </div>
      );
    },
    enableSorting: false,
  },
  {
    accessorKey: 'responsibilities',
    header: 'Funções e responsabilidades',
    meta: {
      headerClassName: 'pr-2 text-xs min-w-[20rem] uppercase',
    },
    cell: (props) => {
      return (
        <div className="w-[25rem] pr-4">
          {props.getValue() ? (
            <TableCellAllocation
              text={props.getValue() as string}
              width="15rem"
              reduceStringIndex={250}
            />
          ) : (
            <>-</>
          )}
        </div>
      );
    },
    enableSorting: false,
  },
  {
    accessorKey: 'experience',
    header: 'Experiência',
    meta: {
      headerClassName: 'pr-2 text-xs min-w-[20rem] uppercase',
    },
    cell: (props) => {
      return (
        <div className="w-[25rem]">
          {props.getValue() ? (
            <TableCellAllocation
              text={props.getValue() as string}
              width="15rem"
              reduceStringIndex={250}
            />
          ) : (
            <>-</>
          )}
        </div>
      );
    },
    enableSorting: false,
  },
  {
    accessorKey: 'educationalRequirements',
    header: 'Requisitos educacionais obrigatórios',
    meta: {
      headerClassName: 'pr-2 text-xs min-w-[17rem] uppercase',
    },
    cell: (props) => {
      const educationalRequirements = props.getValue() as Array<string>;

      return (
        <div className="w-[15rem] text-[11px] text-[#4A4A4A]">
          {props.getValue() && educationalRequirements.length ? (
            <TableCellAllocation
              text={educationalRequirements.sort().join('; ')}
              width="15rem"
            />
          ) : (
            <>-</>
          )}
        </div>
      );
    },
    enableSorting: false,
  },
  {
    accessorKey: 'educationalRequirementsOptional',
    header: 'Requisitos educacionais desejáveis',
    meta: {
      headerClassName: 'pr-2 text-xs min-w-[16rem] uppercase',
    },
    cell: (props) => {
      const educationalRequirements = props.getValue() as Array<string>;

      return (
        <div className="w-[15rem] text-[11px] text-[#4A4A4A]">
          {props.getValue() && educationalRequirements.length ? (
            <TableCellAllocation
              text={educationalRequirements.sort().join('; ')}
              width="15rem"
            />
          ) : (
            <>-</>
          )}
        </div>
      );
    },
    enableSorting: false,
  },
  {
    accessorKey: 'professionalRequirements',
    header: 'Requisitos profissionais obrigatórios',
    meta: {
      headerClassName: 'pr-2 text-xs min-w-[17rem] uppercase',
    },
    cell: (props) => {
      const professionalRequirements = props.getValue() as Array<string>;

      return (
        <div className="w-[15rem]">
          {props.getValue() && professionalRequirements.length ? (
            <TableData
              tableValue={professionalRequirements.sort().join('; ')}
              width="15rem"
              className="text-[11px]"
            />
          ) : (
            <>-</>
          )}
        </div>
      );
    },
    enableSorting: false,
  },
  {
    accessorKey: 'professionalRequirementsOptional',
    header: 'Requisitos profissionais desejáveis',
    meta: {
      headerClassName: 'pr-2 text-xs min-w-[16rem] uppercase',
    },
    cell: (props) => {
      const professionalRequirements = props.getValue() as Array<string>;

      return (
        <div className="w-[15rem]">
          {props.getValue() && professionalRequirements.length ? (
            <TableData
              tableValue={professionalRequirements.sort().join('; ')}
              width="15rem"
              className="text-[11px]"
            />
          ) : (
            <>-</>
          )}
        </div>
      );
    },
    enableSorting: false,
  },
  {
    accessorKey: 'otherRequirements',
    header: 'Outros requisitos obrigatórios',
    meta: {
      headerClassName: 'pr-2 text-xs min-w-[15rem] uppercase',
    },
    cell: (props) => {
      const otherRequirements = props.getValue() as Array<string>;

      return (
        <div className="flex w-[15rem] justify-center break-all text-[11px] text-[#4A4A4A]">
          {props.getValue() && otherRequirements.length ? (
            <TableCellAllocation
              text={otherRequirements.sort().join('; ')}
              width="15rem"
            />
          ) : (
            <>-</>
          )}
        </div>
      );
    },
    enableSorting: false,
  },
  {
    accessorKey: 'otherRequirementsOptional',
    header: 'Outros requisitos desejáveis',
    meta: {
      headerClassName: 'pr-2 text-xs min-w-[15rem] uppercase',
    },
    cell: (props) => {
      const otherRequirements = props.getValue() as Array<string>;

      return (
        <div className="flex w-[15rem] justify-center break-all text-[11px] text-[#4A4A4A]">
          {props.getValue() && otherRequirements.length ? (
            <TableCellAllocation
              text={otherRequirements.sort().join('; ')}
              width="15rem"
            />
          ) : (
            <>-</>
          )}
        </div>
      );
    },
    enableSorting: false,
  },
];
