import { DialogTitle } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { queryClient } from '@/App';
import api from '@/services/apiSgft';
import { getBackgroundColor } from '@/utils/getBackgroundColors';
import { getColor } from '@/utils/getColorByChart';

import BadgeAvatar from '../atoms/BadgeAvatar';
import { Button } from '../atoms/Button';
import CloseButton from '../atoms/CloseButton';
import UncontrolledInputText from '../atoms/UncontrolledInputText';

interface Props {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  employeeId: number;
  employeeName: string;
  employeeAlias?: string;
  employeePosition: string;
  employeePole: string;
  employeeRegime: string;
  employeeEmail: string;
  employeeNumber: string;
}

export const AliasModal = ({
  isOpen,
  setIsOpen,
  employeeId,
  employeeName,
  employeeAlias,
  employeePosition,
  employeePole,
  employeeRegime,
  employeeEmail,
  employeeNumber,
}: Props) => {
  const poleColor = getBackgroundColor(employeePole);
  const bgColor = getBackgroundColor(employeeRegime);
  const textColor = getColor(`${employeeRegime}-text`);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const methods = useForm({
    defaultValues: {
      name: employeeName,
      alias: employeeAlias,
      email: employeeEmail,
      number: employeeNumber,
    },
  });

  const onSubmit = async (data: any) => {
    setIsSubmitting(true);

    try {
      await api.put(`employees/alias`, {
        id: employeeId,
        alias: data.alias.toUpperCase(),
      });
      toast.success('Nome para exibição cadastrado com sucesso!', {
        theme: 'colored',
        toastId: 'success',
      });

      closeAndClear();
    } catch (error: any) {
      toast.error(
        `${'Erro ao salvar nome para exibição, tente novamente mais tarde!'}`,
        {
          theme: 'colored',
          toastId: 'error',
        },
      );
      throw error;
    } finally {
      setIsSubmitting(false);
    }
  };

  const closeAndClear = () => {
    methods.reset();
    setIsOpen(false);
    queryClient.invalidateQueries(['all-employees']);
  };

  return (
    <Dialog
      PaperProps={{
        style: {
          borderRadius: '10px',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
          overflowY: 'auto',
          height: 'auto',
          width: '100%',
          maxWidth: '25rem',
        },
      }}
      slotProps={{ backdrop: { sx: { background: 'rgba(0, 0, 0, 0.25)' } } }}
      open={isOpen}
      onClose={() => setIsOpen(false)}
    >
      <DialogTitle>
        <div className="flex h-auto flex-col items-center justify-between">
          <div className="flex w-[24rem] justify-end">
            <CloseButton
              onClick={() => setIsOpen(false)}
              className="my-[-0.5rem]"
            />
          </div>
          <BadgeAvatar
            name={employeeName ?? '-'}
            regime={employeeRegime}
            poleColor={poleColor}
            bgColor={bgColor}
            active={true}
            textColor={textColor}
            size={70}
            fontSize={25}
            height={12}
          />
          <div className="mt-5 flex flex-col items-center justify-center font-graphie font-medium">
            <div className={`text-[13px] text-[${poleColor}] text-center`}>
              {employeeName}
            </div>
            <div className="mt-1 text-center text-[12px] text-[#5A5A5A]">
              {employeePosition}
            </div>
          </div>
        </div>
      </DialogTitle>
      <DialogContent>
        <FormProvider {...methods}>
          <form
            className="flex w-full flex-col"
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            <UncontrolledInputText
              title="Nome Completo"
              fullWidth
              {...methods.register('name')}
              disabled
              className="mb-2"
            />
            <UncontrolledInputText
              title="Nome para exibição"
              fullWidth
              {...methods.register('alias')}
              className="mb-2"
              tooltipText={
                'Nome reduzido que será utilizado para exibição no sistema. Utilize quando o colaborar é conhecido por outro nome diferente de seu primeiro nome e último sobrenome.'
              }
              upperCase
            />
            <UncontrolledInputText
              title="E-mail"
              fullWidth
              {...methods.register('email')}
              disabled
              className="mb-2"
            />
            <UncontrolledInputText
              title="Chapa"
              fullWidth
              {...methods.register('number')}
              disabled
              className="mb-2"
            />

            <div className="my-2 mt-[2rem] flex w-full justify-between">
              <Button
                type="button"
                className="h-8 min-w-36 border-2 border-[#193CB9] bg-white text-[#193CB9] hover:bg-[#e9eaf1]"
                onClick={closeAndClear}
              >
                Cancelar
              </Button>
              <Button
                type="submit"
                className=" h-8 min-w-36"
                isLoading={isSubmitting}
              >
                Salvar
              </Button>
            </div>
          </form>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
};
