import { Paper } from '@mui/material';
import { useEffect } from 'react';
import { useFieldArray, useFormContext, UseFormReturn } from 'react-hook-form';

import { Modality } from '@/constants/TrainingConstants';

import SubtitleText from '../atoms/SubtitleText';
import { TrainingCell } from './TrainingCell';

interface Props {
  methods: UseFormReturn<any, any, any>;
  setOnlineMaxDaysInput: (value: boolean) => void;
  setInPersonMaxDaysInput: (valuie: boolean) => void;
}

const AllTrainins = ({
  methods,
  setOnlineMaxDaysInput,
  setInPersonMaxDaysInput,
}: Props) => {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: `trainings`,
  });
  const addTraining = () => {
    append({
      id: null,
      locations: [],
      modalities: [],
      trainingType: null,
      name: null,
      totalWorkload: null,
      inPersonWorkload: null,
      onlineWorkload: null,
    });
  };

  const updateDueInputs = () => {
    // Ead = libera prazo online, Semipresencial = libera os prazos online e presencial; Presencial = não libera nenhum prazo
    const trainings = methods.getValues('trainings')
      ? methods.getValues('trainings')
      : [];
    const modalities = trainings.flatMap(
      (el: { modalities: Modality[] }) => el.modalities,
    );
    if (modalities.includes(Modality.Semipresencial)) {
      setOnlineMaxDaysInput(true);
      setInPersonMaxDaysInput(true);
    } else if (modalities.includes(Modality.Online)) {
      setOnlineMaxDaysInput(true);
    } else {
      methods.resetField('onlineMaxDays', { defaultValue: '' });
      methods.resetField('inPersonMaxDays', { defaultValue: '' });
      setOnlineMaxDaysInput(false);
      setInPersonMaxDaysInput(true);
    }
  };

  useEffect(() => {
    updateDueInputs();
  }, [fields]);

  return (
    <Paper sx={{ background: '#edeff1', padding: 1, marginBottom: 3 }}>
      <div className="flex items-center py-2">
        <SubtitleText
          subtitle={'Treinamentos Aplicados'}
          className="text-[16px]"
        />
      </div>
      {fields.map((el, idx) => {
        return (
          <TrainingCell
            key={el.id}
            methods={methods}
            updateDueInputs={updateDueInputs}
            idx={idx}
            remove={remove}
          />
        );
      })}
      <button
        type="button"
        className="mt-2 text-[12px] text-primary underline "
        onClick={addTraining}
      >
        Adicionar treinamento
      </button>
    </Paper>
  );
};

export default AllTrainins;
