import { z } from 'zod';

import { CombinedJobTitle } from '@/types/NewJobTitle';

type CombinedJobTitleKeys = keyof CombinedJobTitle;

export const RoleConstant: { value: CombinedJobTitleKeys; label: string }[] = [
  { value: 'name', label: 'Título do cargo' },
  { value: 'board', label: 'Diretoria' },
  { value: 'management', label: 'Gerência' },
  { value: 'seniorityLevelName', label: 'Senioridade do Cargo' },
  { value: 'workStations', label: 'Local de Trabalho' },
  { value: 'objective', label: 'Objetivo do Cargo' },
  { value: 'responsibilities', label: 'Funções e Responsabilidades' },
  { value: 'educationalRequirements', label: 'Requisitos Educacionais' },
  { value: 'professionalRequirements', label: 'Requisitos Profissionais' },
  { value: 'otherRequirements', label: 'Outros Requisitos' },
  {
    value: 'educationalRequirementsOptional',
    label: 'Requisitos Educacionais Desejáveis',
  },
  {
    value: 'professionalRequirementsOptional',
    label: 'Requisitos Profissionais Desejáveis',
  },
  { value: 'otherRequirementsOptional', label: 'Outros Requisitos Desejáveis' },
  { value: 'experience', label: 'Experiência' },
  { value: 'skillsAndCompetencies', label: 'Habilidades e Competências' },
  { value: 'workStations', label: 'GHE por local de trabalho' },
];

const NewJobTitleFilterOption = z
  .object({
    id: z
      .union([z.number(), z.string()])
      .transform((id) => String(id))
      .pipe(z.string()),
    name: z.string(),
  })
  .transform(({ id, name }) => ({
    value: id,
    label: name,
  }));

export const NewJobTitleFilterOptionsSchema = z.object({
  titles: z.array(NewJobTitleFilterOption).default([]),
  seniorityLevels: z.array(NewJobTitleFilterOption).default([]),
  companies: z.array(NewJobTitleFilterOption).default([]),
  boards: z.array(NewJobTitleFilterOption).default([]),
  managements: z.array(NewJobTitleFilterOption).default([]),
  workStations: z.array(NewJobTitleFilterOption).default([]),
  // requirementTypes: z.array(NewJobTitleFilterSingleOption).default([]),
  // educationalRequirements: z.array(NewJobTitleFilterOption).default([]),
  // professionalRequirements: z.array(NewJobTitleFilterOption).default([]),
  // otherRequirements: z.array(NewJobTitleFilterOption).default([]),
});

export type NewJobTitleFiltersOptions = z.infer<
  typeof NewJobTitleFilterOptionsSchema
>;
