import React, { useState } from 'react';

import { cn } from '@/utils/cn';

import ArrowDownButton from '../atoms/ArrowDownButton';
import ArrowUpButton from '../atoms/ArrowUpButton';
import Line from '../atoms/Line';
import SubtitleText from '../atoms/SubtitleText';

interface Props {
  subtitle: string;
  children: React.ReactNode;
  hasTooltip?: boolean;
  tooltipText?: string;
  containerClassName?: string;
  className?: string;
  defaultIsOpen?: boolean;
}

const ExpandableSubtitle = ({
  subtitle,
  children,
  hasTooltip,
  tooltipText,
  containerClassName,
  className,
  defaultIsOpen = true,
}: Props) => {
  const [isOpen, setIsOpen] = useState(defaultIsOpen);
  const onClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="flex flex-col">
      <div
        className={cn('mb-[-0.5rem] flex justify-between', containerClassName)}
      >
        <SubtitleText
          subtitle={subtitle}
          hasTooltip={hasTooltip}
          tooltipText={tooltipText}
          className={className}
        />
        {isOpen ? (
          <ArrowUpButton onClick={onClick} />
        ) : (
          <ArrowDownButton onClick={onClick} />
        )}
      </div>
      <Line />
      {isOpen && children}
    </div>
  );
};

export default ExpandableSubtitle;
