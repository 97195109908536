import { ColumnDef } from '@tanstack/react-table';
import { useRecoilValue } from 'recoil';

import TableCellDate from '@/components/atoms/TableCellDate';
import TableCellText from '@/components/atoms/TableCellText';
import AllocationRequestButtons from '@/components/molecules/AllocationRequestButtons';
import { actualUserAtom } from '@/state/ActualUser.atom';
import { removeTimeZone } from '@/utils/formatDate';

import { AllocationRequestHistory } from '../AllocationRequest';

export const allocationRequestColumns: ColumnDef<AllocationRequestHistory>[] = [
  {
    accessorKey: 'management',
    header: 'Gerência',
    cell: (props) => {
      return (
        <div className="w-32">
          <TableCellText text={props.getValue() as string} />
        </div>
      );
    },
    enableSorting: false,
  },
  {
    accessorKey: 'teamStructure',
    header: 'Estrutura',
    cell: (props) => {
      return (
        <div className="w-32">
          <TableCellText text={props.getValue() as string} />
        </div>
      );
    },
    enableSorting: false,
  },
  {
    accessorKey: 'team',
    header: 'Turma',
    cell: (props) => {
      return (
        <div className="w-16">
          <TableCellText text={props.getValue() as string} widthFull />
        </div>
      );
    },
  },
  {
    accessorKey: 'requestDate',
    header: 'Data da Solicitação',
    cell: (props) => {
      return (
        <div className="w-24">
          <TableCellDate
            date={
              (props.getValue() as string) && props.getValue() !== '-'
                ? removeTimeZone(new Date(props.getValue() as string))
                : undefined
            }
          />
        </div>
      );
    },
    enableSorting: false,
  },
  {
    accessorKey: 'motive',
    header: 'Motivo',
    cell: (props) => {
      return (
        <div className="w-40">
          <TableCellText text={props.getValue() as string} widthFull />
        </div>
      );
    },
    enableSorting: false,
  },
  {
    id: 'actions',
    header: '',
    cell: (props) => {
      const actualUser = useRecoilValue(actualUserAtom);
      const isRequester =
        props.cell.row.original.requester === actualUser.email;

      if (isRequester) {
        return (
          <div className="w-20">
            <AllocationRequestButtons allocation={props.cell.row.original} />
          </div>
        );
      }
      return <div className="h-[35px] w-20" />;
    },
    enableSorting: false,
  },
];
