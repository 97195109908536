import { MouseEvent, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';

import { NewJobTitle } from '@/types/NewJobTitle';
import { removeTimeZone } from '@/utils/formatDate';

import JobTitleHistoryModal from '../organisms/JobTitleHistoryModal';
import JobTitleModalNew from '../organisms/JobTitleModalNew';
import PrintJobTitlePDF from '../organisms/PrintJobTitlePDF';
import LightTooltip from './LightTooltip';
import SimpleMenu from './Menu';
import TableCellDate from './TableCellDate';

interface JobTitleLineProps {
  newJobTitle: NewJobTitle;
}
export const JobTitleLine = ({ newJobTitle }: JobTitleLineProps) => {
  const [isJobTitleModalOpen, setIsJobTitleModalOpen] = useState(false);
  const [tooltipEnabled, setTooltipEnabled] = useState(false);
  const [isJobTitleHistoryModalOpen, setIsJobTitleHistoryModalOpen] =
    useState(false);
  const printRef = useRef<HTMLDivElement>(null);

  const pdfName =
    newJobTitle.seniorityLevel.name && newJobTitle.seniorityLevel.name !== 'N/A'
      ? `Descrição de Cargo - ${newJobTitle.generalInformationJobTitle.name.toUpperCase()} ${newJobTitle.seniorityLevel.name.toUpperCase()}`
      : `Descrição de Cargo - ${newJobTitle.generalInformationJobTitle.name.toUpperCase()}`;

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: `${pdfName}`,
  });

  const handleShouldShow = ({ currentTarget }: MouseEvent<Element>) => {
    if (currentTarget.scrollWidth > currentTarget.clientWidth) {
      setTooltipEnabled(true);
    }
  };
  const options = [
    {
      name: 'Editar Informações',
      onClick: () => {
        const urlRedmine = import.meta.env.VITE_REDMINE_URL;

        const path = `${urlRedmine}/issues/${newJobTitle.generalInformationJobTitle?.issueId}`;
        window.open(path);
      },
      // disabled: !newJobTitle.active,
      disabled: true,
    },
    {
      name: 'Consultar Versões',
      onClick: () => {
        setIsJobTitleHistoryModalOpen(true);
      },
      disabled: false,
    },
    {
      name: 'Exportar Descrição do Cargo',
      onClick: () => {
        handlePrint();
      },
      disabled: false,
    },
  ];

  return (
    <div className="flex w-full items-center justify-between rounded-lg bg-[#F5F7FF] px-2 py-1 transition-all duration-500 ease-in-out">
      <div className="flex w-full">
        <div className="flex w-full items-center justify-between gap-4">
          <LightTooltip
            title={`${newJobTitle?.generalInformationJobTitle?.name} ${newJobTitle?.seniorityLevel?.name}`}
            open={tooltipEnabled}
            onClose={() => setTooltipEnabled(false)}
            arrow
            placement="top-end"
          >
            <div
              className="cursor-pointer overflow-hidden text-ellipsis text-[14px] font-medium uppercase text-[#4a4a4a]"
              onMouseEnter={handleShouldShow}
              onClick={() => setIsJobTitleModalOpen(true)}
            >
              <p className="text-primary underline">
                {`${newJobTitle?.generalInformationJobTitle?.name} ${newJobTitle?.seniorityLevel?.name && newJobTitle?.seniorityLevel?.name !== 'N/A' ? newJobTitle?.seniorityLevel?.name : ''}`}
              </p>
            </div>
          </LightTooltip>
        </div>

        {!newJobTitle.active && (
          <div className="ml-auto w-[4rem] rounded-xl bg-[#d9d9d9] text-center text-[12px] font-medium	text-[#5a5a5a]">
            INATIVO
          </div>
        )}
      </div>
      <div className="flex w-[40%] items-center justify-end text-[11px] text-[#848484]">
        <div className="font-medium">
          {' '}
          Versão: {newJobTitle.generalInformationJobTitle?.version}
        </div>
        <div className="mx-2">●</div>
        <div className="mr-1">Última edição:</div>
        <div className="mr-1 font-medium">
          {' '}
          <TableCellDate
            className="text-[10px] font-normal text-[#848484]"
            date={removeTimeZone(new Date(newJobTitle.updatedAt))}
          />
        </div>
        <SimpleMenu options={options} upDotsButton upDotsButtonColor="action" />
      </div>
      {isJobTitleModalOpen && (
        <JobTitleModalNew
          modalIsOpen={isJobTitleModalOpen}
          handleClose={() => setIsJobTitleModalOpen(false)}
          newJobTitle={newJobTitle}
        />
      )}

      {isJobTitleHistoryModalOpen && (
        <JobTitleHistoryModal
          newJobTitle={newJobTitle}
          modalIsOpen={isJobTitleHistoryModalOpen}
          handleClose={() => setIsJobTitleHistoryModalOpen(false)}
        />
      )}
      <div className="relative  hidden h-[100%] w-full flex-col">
        <PrintJobTitlePDF ref={printRef} newJobTitle={newJobTitle} />
      </div>
    </div>
  );
};
