import { ColumnDef } from '@tanstack/react-table';
import { useState } from 'react';

import { ConfirmationDialog } from '@/components/atoms/ConfirmationDialog';
import TableCellDate from '@/components/atoms/TableCellDate';
import TableCellText from '@/components/atoms/TableCellText';
import { TooltipString } from '@/components/atoms/TooltipString';
import TrashButton from '@/components/atoms/TrashButton';
import { EvidenceFile } from '@/types/EvidenceFile';
import { removeTimeZone } from '@/utils/formatDate';

export const columns: (
  onDeleteFunction: (id: number) => Promise<void>,
  deleteFileDescrption: string,
  hideDeleteButton?: boolean,
) => Array<ColumnDef<EvidenceFile>> = (
  onDeleteFunction,
  deleteFileDescrption,
  hideDeleteButton,
) => [
  {
    accessorKey: 'filename',
    header: 'Arquivo',
    cell: (props) => {
      const presignedUrl = props.row.original.presignedUrl;
      return (
        <a
          className={`w-40  ${presignedUrl ? 'cursor-pointer' : ''} border-none bg-transparent text-[11px] text-[#4A4A4A] ${presignedUrl ? 'underline' : ''} outline-none`}
          href={presignedUrl || undefined}
          target={presignedUrl ? '_blank' : undefined}
          rel={presignedUrl ? 'noreferrer' : undefined}
        >
          <TooltipString
            value={props.getValue() as string}
            width="9rem"
            color="[#4A4A4A]"
            fontSize="12px"
          />
        </a>
      );
    },
    enableSorting: false,
  },
  {
    accessorKey: 'email',
    header: 'Importado por',
    cell: (props) => {
      return (
        <div className="w-[13rem]">
          <TableCellText
            text={props.getValue() as string}
            width="13rem"
            paddingX="0px"
          />
        </div>
      );
    },
    enableSorting: false,
  },
  {
    accessorKey: 'importDate',
    header: 'Data de importação',
    cell: (props) => {
      return (
        <div className="w-32">
          <TableCellDate
            date={
              (props.getValue() as string) !== '-'
                ? removeTimeZone(new Date(props.getValue() as string))
                : undefined
            }
            className="flex justify-center"
          />
        </div>
      );
    },
    enableSorting: false,
  },
  {
    accessorKey: 'deleteEvidence',
    header: '',
    cell: (props) => {
      const [isOpen, setIsOpen] = useState(false);
      const handleDelete = () => {
        onDeleteFunction(props.row.original.id);
        setIsOpen(false);
      };
      return (
        !hideDeleteButton && (
          <div>
            <TrashButton onClick={() => setIsOpen(true)} />
            {isOpen && (
              <ConfirmationDialog
                title={`Deseja excluir o arquivo ${props.row.original.filename}`}
                description={`O arquivo ${props.row.original.filename} será excluído ${deleteFileDescrption}. Deseja continuar?`}
                setIsOpen={setIsOpen}
                className="text-[13px]"
                onConfirmation={handleDelete}
              />
            )}
          </div>
        )
      );
    },
    enableSorting: false,
  },
];
