import { useRecoilState } from 'recoil';

import NoPermissionText from '@/components/atoms/NoPermissionText';
import BasicTabs from '@/components/molecules/BasicTabs';
import SideFilter from '@/components/molecules/SideFilter';
import ClosedTurnstileApprovalsTab from '@/components/organisms/ClosedTurnstileApprovalsTab';
import PendingApprovalsTab from '@/components/organisms/PendingTurnstileApprovalsTab';
import PendingTurnstileApprovalsTab from '@/components/organisms/PendingTurnstileReleasesTab';
import { TurnstileApprovalFiltersAtom } from '@/state/TurnstileApprovalFilter.atom';
import { userIsRhOrAdmin } from '@/utils/handleSavePermissions';

import { filters } from '../../constants/filters/TurnstileApprovalFilter';

const TurnstileApprovals = () => {
  const [filterValues, setFilterValues] = useRecoilState(
    TurnstileApprovalFiltersAtom,
  );

  const useResetFilterValues = () => {
    setFilterValues({
      tab: filterValues.tab,
      area: [],
      management: [],
      scale: [],
      sector: [],
      workStation: [],
      status: [],
      type: [],
    });
  };

  const handleApplyFilter = (isReseting: boolean) => {
    if (isReseting) useResetFilterValues();
    else {
      setFilterValues(filterValues);
    }
  };
  if (!userIsRhOrAdmin()) return <NoPermissionText />;

  const pendingFilters = filters.filter(
    (filter) => filter.key !== 'status' && filter.key !== 'type',
  );

  return (
    <div className="h-full w-full font-graphie">
      <SideFilter
        refetchOnChange
        filters={filterValues.tab === 2 ? filters : pendingFilters}
        atom={TurnstileApprovalFiltersAtom}
        applyChanges={handleApplyFilter}
      />
      <BasicTabs
        tabsNames={[
          'Aprovação Realocação',
          'Liberação de Acesso',
          'Finalizados',
        ]}
        initialTab={filterValues.tab}
        tabs={[
          <PendingApprovalsTab key={0} />,
          <PendingTurnstileApprovalsTab key={1} />,
          <ClosedTurnstileApprovalsTab key={2} />,
        ]}
      />
    </div>
  );
};

export default TurnstileApprovals;
