import { atom } from 'recoil';

export interface TrainingControlAtom extends Record<string, string[]> {
  employee: string[];
  role: string[];
  rmStatus: string[];
  training: string[];
  presencialCompletionStatus: string[];
  onlineCompletionStatus: string[];
  trainingPole: string[];
  expirationStatus: string[];
  management: string[];
  areaCoordinator: string[];
  trainingType: string[];
  pole: string[];
  workstation: string[];
}

export const trainingControlAtom = atom<TrainingControlAtom>({
  key: 'trainingControl',
  default: {
    employee: [],
    role: [],
    rmStatus: [],
    training: [],
    presencialCompletionStatus: [],
    onlineCompletionStatus: [],
    trainingPole: [],
    expirationStatus: [],
    management: [],
    areaCoordinator: [],
    trainingType: [],
    pole: [],
    workstation: [],
  },
});
