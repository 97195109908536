import './index.css';

import {
  AuthenticationResult,
  EventType,
  PublicClientApplication,
} from '@azure/msal-browser';
import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { RecoilURLSyncJSON } from 'recoil-sync';

import App from './App';
import { msalConfig } from './auth/authConfig';

const sentryDSN = import.meta.env.VITE_SENTRY_DSN;
const environment = import.meta.env.VITE_SENTRY_ENVIRONMENT;
const debug = import.meta.env.VITE_SENTRY_DEBUG === 'true';

Sentry.init({
  dsn: sentryDSN,
  debug,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration(),
    Sentry.captureConsoleIntegration({
      levels: ['error'],
    }),
  ],
  environment,
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost'], /// ^https:\/\/yourserver\.io\/api/
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 0.2, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

export const msalInstance = new PublicClientApplication(msalConfig);

if (
  !msalInstance.getActiveAccount() &&
  msalInstance.getAllAccounts().length > 0
) {
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

type IdTokenClaims = {
  roles: string[];
};

const REQUIRED_SCOPE = import.meta.env.VITE_REQUIRED_SCOPE;

msalInstance.addEventCallback(async (event) => {
  const payload = event.payload as AuthenticationResult;
  if (event.eventType === EventType.LOGIN_SUCCESS && payload.account) {
    const account = payload.account;
    msalInstance.setActiveAccount(account);
    if (
      !(payload.idTokenClaims as IdTokenClaims)?.roles?.includes(REQUIRED_SCOPE)
    ) {
      msalInstance.logoutPopup();
    }
  }
  if (payload?.account) {
    Sentry.setUser({ email: payload.account.username });
  }
});

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <RecoilRoot>
    <RecoilURLSyncJSON location={{ part: 'queryParams' }}>
      <App instance={msalInstance} />
    </RecoilURLSyncJSON>
  </RecoilRoot>,
);
