import { Avatar } from '@mui/material';
import Badge from '@mui/material/Badge';

import { getFirstAndLastName } from '@/utils/getFirstAndLastName';

interface Props {
  name?: string;
  poleColor: string;
  regime: string;
  size?: number;
  fontSize?: number;
  bgColor: string;
  active: boolean;
  textColor: string;
  height?: number;
}

export default function BadgeAvatar({
  name = '',
  poleColor,
  regime,
  size = 48,
  fontSize = 16,
  bgColor,
  active,
  textColor,
  height = 9,
}: Props) {
  const stringAvatar = (name: string) => {
    return {
      children: `${getFirstAndLastName(name)
        ?.split(' ')
        .map((el) => el[0]?.toUpperCase())
        .filter((el, idx) => idx < 2)
        .join('')}`,
    };
  };

  const avatarProps = active ? stringAvatar(name) : {};
  return (
    <Badge
      className={textColor}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      badgeContent={regime}
      sx={{
        '& .MuiBadge-badge': {
          fontSize: height,
          height,
          lineHeight: height,
          minWidth: '24px',
          fontWeight: 'semibold',
          fontFamily: 'Graphie',
          marginRight: '50%',
          backgroundColor: bgColor,
          color: textColor,
        },
      }}
      style={{
        border: '1px solid #dbdada',
        borderRadius: '100%',
        padding: '3px',
      }}
    >
      <Avatar
        {...avatarProps}
        sx={{
          width: size,
          height: size,
          backgroundColor: poleColor,
          fontSize,
        }}
      />
    </Badge>
  );
}
