import { CircularProgress } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';
import { format } from 'date-fns';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useRecoilState, useRecoilValue } from 'recoil';

import { queryClient } from '@/App';
import AvailabilityTooltipIcon from '@/components/atoms/AvailabilityTooltipIcon';
import EditableTableDate from '@/components/atoms/EditableTableDate';
import FileUploadComponent from '@/components/atoms/FileUpload';
import { JustificationDialog } from '@/components/atoms/JustificationDialog';
import SimpleMenu from '@/components/atoms/Menu';
import Spinner from '@/components/atoms/Spinner';
import TableData from '@/components/atoms/TableData';
import { TatableCell } from '@/components/atoms/TatableCell';
import TooltipIconText from '@/components/atoms/TooltipIconText';
import TrainingFileTeamEmployeeMenu from '@/components/molecules/TrainingFileTeamEmployeeMenu';
import EditEmployeeOnTrainingFileTeamModal from '@/components/organisms/EditEmployeeOnTrainingFileTeamModal';
import { Checkbox } from '@/components/ui/checkbox';
import api from '@/services/apiSgft';
import { trainingFileTeamOpenTableAtom } from '@/state/TrainingFileTeamOpenTable.atom';
import { CompletionStatus } from '@/types/TrainingControlTypes';
import { TrainingFileTeamEmployee } from '@/types/TrainingFile';
import { removeTimeZone } from '@/utils/formatDate';
import {
  getRedmineStatusColorUpperCase,
  getRedmineStatusUpperCase,
} from '@/utils/redmineStatus';

import TableCellText from '../../components/atoms/TableCellText';
import {
  eadAllowedStatuses,
  Modality,
  presencialAllowedStatuses,
  StatusTrainingControl,
  StatusTrainingControlHistory,
  ValidStatusesForUploadEvidences,
} from '../TrainingConstants';
import { RedmineStatus } from '../TrainingPlanning';

export const columns: (
  onlineTraining: boolean,
  asyncTraining: boolean,
  trainingModality: Modality,
) => Array<ColumnDef<TrainingFileTeamEmployee & { concluded: boolean }>> = (
  onlineTraining,
  asyncTraining,
  trainingModality,
) => [
  {
    accessorKey: 'id',
    header: '',
    meta: {
      headerClassName: 'w-20 bg-white',
      stickyClassName: 'sticky left-0 z-50 align-top',
    },
    cell: (props) => {
      const [tableOpen, setTableOpen] = useRecoilState(
        trainingFileTeamOpenTableAtom,
      );
      const id = props.getValue() as number;
      const planningDays = props.row.original.trainingPlanningEmployeeLinkDays;
      const isOpen = tableOpen.includes(id);
      const onToggle = () => {
        if (isOpen) {
          setTableOpen((prev) => prev.filter((it) => it !== id));
        } else {
          setTableOpen((prev) => prev.concat(id));
        }
      };
      if (planningDays.length <= 1) {
        return <div className="h-12 w-8 p-2"></div>;
      }
      return (
        <button
          className="item flex h-12 w-8 items-center p-2 uppercase"
          onClick={onToggle}
        >
          {isOpen ? (
            <ChevronUp className="text-primary" size={16} />
          ) : (
            <ChevronDown className="text-primary" size={16} />
          )}
        </button>
      );
    },
    enableSorting: false,
  },
  {
    accessorKey: 'name',
    header: 'Nome',
    meta: {
      headerClassName: 'bg-white text-gray-600',
      stickyClassName: 'sticky left-8 z-50 align-top',
    },
    cell: (props) => {
      const extraordinaryAllocation =
        props.row.original.extraordinaryAllocation;
      const extraordinaryAllocationJustification =
        props.row.original.extraordinaryAllocationJustification;
      const trainingDate = props.row.original.trainingDate;
      return (
        <div className="flex h-12 w-[20rem]  items-center">
          <TableData
            tableValue={props.row.original.alias ?? props.getValue()}
            className="overflow-hidden text-ellipsis whitespace-nowrap px-3"
            title={props.getValue() as string}
          />
          {extraordinaryAllocation && (
            <AvailabilityTooltipIcon
              motive={extraordinaryAllocationJustification ?? ''}
            />
          )}
          <div className="flex-shrink-0">
            <TrainingFileTeamEmployeeMenu
              disabled={props.row.original.concluded}
              employeeLink={props.row.original}
              onlineTraining={onlineTraining}
              asyncTraining={asyncTraining}
            />
          </div>
        </div>
      );
    },
    enablePinning: true,
    enableSorting: true,
  },
  {
    accessorKey: 'employeeNumber',
    header: 'Chapa',
    meta: {
      headerClassName: 'bg-white text-gray-600',
      stickyClassName: 'sticky left-[22rem] z-50 align-top',
    },
    cell: (props) => {
      return (
        <div className="flex h-12 w-24 items-center">
          <TableData
            tableValue={props.getValue()}
            className="overflow-hidden text-ellipsis whitespace-nowrap px-3"
            title={props.getValue() as string}
          />
        </div>
      );
    },
    enableSorting: true,
  },
  {
    accessorKey: 'management',
    header: 'Gerência',
    meta: {
      headerClassName: 'text-gray-600',
    },
    cell: (props) => {
      return (
        <div className="flex h-12 w-40  items-center">
          <TableData
            tableValue={props.getValue()}
            className="overflow-hidden text-ellipsis whitespace-nowrap  px-3"
            title={props.getValue() as string}
          />
        </div>
      );
    },
    enableSorting: true,
  },
  {
    accessorKey: 'area_coordinator',
    header: 'Coordenação',
    meta: {
      headerClassName: 'text-gray-600',
    },
    cell: (props) => {
      return (
        <div className="flex h-12 w-40  items-center">
          <TableData
            tableValue={props.getValue()}
            className="overflow-hidden text-ellipsis whitespace-nowrap  px-3"
            title={props.getValue() as string}
          />
        </div>
      );
    },
    enableSorting: true,
  },
  {
    accessorKey: 'workStation',
    header: 'Local de trabalho',
    meta: {
      headerClassName: 'text-gray-600',
    },
    cell: (props) => {
      return (
        <div className="flex h-12 w-32  items-center">
          <TableData
            tableValue={props.getValue()}
            className="overflow-hidden text-ellipsis whitespace-nowrap  px-3"
            title={props.getValue() as string}
          />
        </div>
      );
    },
    enableSorting: true,
  },
  {
    accessorKey: 'role',
    header: 'Função',
    meta: {
      headerClassName: 'text-gray-600 ',
    },
    cell: (props) => {
      return (
        <div className="flex h-12 w-40 items-center overflow-hidden text-ellipsis  whitespace-nowrap">
          <TableData
            tableValue={props.getValue()}
            className="overflow-hidden text-ellipsis whitespace-nowrap  px-3"
            title={props.getValue() as string}
          />
        </div>
      );
    },
    enableSorting: true,
  },
  {
    accessorKey: 'state',
    header: 'Estado de moradia',
    meta: {
      headerClassName: 'text-gray-600',
    },
    cell: (props) => {
      return (
        <div className="flex h-12 w-32  items-center">
          <TableData
            tableValue={props.getValue()}
            className="overflow-hidden text-ellipsis whitespace-nowrap px-3"
            title={props.getValue() as string}
          />
        </div>
      );
    },
    enableSorting: true,
  },
  {
    accessorKey: 'redmineManagerStatus',
    header: 'Status da Autorização',
    meta: {
      headerClassName: 'text-gray-600',
    },
    cell: (props) => {
      return (
        <div className="flex h-12 w-40 items-center   pr-6">
          <TableData
            tableValue={getRedmineStatusUpperCase(props.getValue() as string)}
            className="overflow-hidden text-ellipsis whitespace-nowrap px-3 font-bold"
            title={props.getValue() as string}
            color={getRedmineStatusColorUpperCase(props.getValue() as string)}
          />
          {props.getValue() === RedmineStatus.SolicitacaoRejeitada && (
            <TooltipIconText
              text={props.row.original.redmineManagerJustification || ''}
            />
          )}
        </div>
      );
    },
    enableSorting: true,
  },
  {
    accessorKey: 'confirmedPresence',
    header:
      onlineTraining && asyncTraining
        ? 'Ciência de Liberação'
        : 'Presença confirmada',
    meta: {
      headerClassName: 'text-gray-600',
      tdClassName: 'flex items-center justify-center',
    },
    cell: (props) => {
      const [loading, setLoading] = useState(false);
      const handleCheck = async (checked: boolean) => {
        const id = props.row.original.id;
        setLoading(true);
        try {
          await api.patch(`training-plannings/toggle-field/${id}`, {
            confirmedPresence: checked,
          });
          toast.success('Sucesso ao atualizar o status', {
            theme: 'colored',
            toastId: 'confirmedPresence-success',
          });
          queryClient.invalidateQueries(['training-file-details']);
        } catch {
          toast.error('Erro ao atualizar o status', {
            theme: 'colored',
            toastId: 'confirmedPresence-error',
          });
        } finally {
          setLoading(false);
        }
      };
      return (
        <div className="flex h-12 w-40  items-center">
          {loading ? (
            <div className="flex justify-center py-4">
              <Spinner /> {}
            </div>
          ) : (
            <Checkbox
              defaultChecked={props.getValue() as boolean}
              onCheckedChange={(checked: boolean) => handleCheck(checked)}
              disabled={props.row.original.concluded}
            />
          )}
        </div>
      );
    },
    enableSorting: true,
  },

  {
    accessorKey: 'date',
    header: 'Data',
    meta: {
      headerClassName: 'text-gray-600',
    },
    cell: (props) => {
      const openIds = useRecoilValue(trainingFileTeamOpenTableAtom);
      const id = props.row.original.id;
      const isOpen = openIds.includes(id);
      const planningDays = props.row.original.trainingPlanningEmployeeLinkDays;
      const tableValue = asyncTraining
        ? 'N/A'
        : planningDays.length === 1
          ? format(new Date(planningDays[0].date), 'dd/MM/yyyy')
          : `${planningDays.length} dias`;
      return (
        <TatableCell
          isOpen={isOpen}
          text={tableValue}
          tableCellClassName={
            planningDays.length ? 'font-bold text-primary' : ''
          }
        >
          {planningDays.map((planningDay) => {
            return (
              <div
                className="flex h-8 min-h-8 w-full items-center bg-gray-200 p-1 font-semibold"
                key={planningDay.id}
              >
                <p>{format(new Date(planningDay.date), 'dd/MM/yyyy')}</p>
              </div>
            );
          })}
        </TatableCell>
      );
    },
    enableSorting: true,
  },
  {
    accessorKey: 'statusInDay',
    header: 'Folga',
    meta: {
      headerClassName: 'text-gray-600',
    },
    cell: (props) => {
      const openIds = useRecoilValue(trainingFileTeamOpenTableAtom);
      const id = props.row.original.id;
      const isOpen = openIds.includes(id);
      const planningDays = props.row.original.trainingPlanningEmployeeLinkDays;
      const tableValue = asyncTraining
        ? 'N/A'
        : planningDays.length === 1
          ? planningDays[0].statusInDay
          : ' -';
      return (
        <TatableCell
          isOpen={isOpen}
          text={tableValue}
          containerClassName="min-w-10"
        >
          {planningDays.map((planningDay) => {
            return (
              <div
                className=" flex h-8 min-h-8 w-full items-center bg-gray-200  p-1"
                key={planningDay.id}
              >
                {planningDay.statusInDay ?? ' - '}
              </div>
            );
          })}
        </TatableCell>
      );
    },
    enableSorting: true,
  },

  {
    accessorKey: 'extraMeal',
    header: 'Refeição extra',
    meta: {
      headerClassName: 'text-gray-600 ',
    },
    cell: (props) => {
      const openIds = useRecoilValue(trainingFileTeamOpenTableAtom);
      const id = props.row.original.id;
      const isOpen = openIds.includes(id);
      const planningDays = props.row.original.trainingPlanningEmployeeLinkDays;
      const handleCheck = async (checked: boolean, id: number) => {
        try {
          await api.patch(`training-plannings/day/toggle-field/${id}`, {
            extraMeal: checked,
          });
          toast.success('Sucesso ao atualizar o status', {
            theme: 'colored',
            toastId: 'extraMeal-success',
          });
          queryClient.invalidateQueries(['training-file-details']);
        } catch {
          toast.error('Erro ao atualizar o status', {
            theme: 'colored',
            toastId: 'extraMeal-error',
          });
        }
      };
      return (
        <>
          {planningDays.length === 1 ? (
            <div className="flex h-12 w-[10rem] items-center justify-center gap-3">
              <Checkbox
                defaultChecked={planningDays[0].extraMeal}
                onCheckedChange={(checked: boolean) =>
                  handleCheck(checked, planningDays[0].id)
                }
                disabled={props.row.original.concluded}
              />
            </div>
          ) : (
            <TatableCell
              isOpen={isOpen}
              text=" - "
              containerClassName="min-w-40"
            >
              {planningDays.map((planningDay) => (
                <div
                  className="flex h-8 min-h-8 w-full flex-1 items-center bg-gray-200"
                  key={planningDay.id}
                >
                  <Checkbox
                    defaultChecked={planningDay.extraMeal}
                    onCheckedChange={(checked: boolean) =>
                      handleCheck(checked, planningDay.id)
                    }
                    disabled={props.row.original.concluded}
                  />
                </div>
              ))}
            </TatableCell>
          )}
        </>
      );
    },
    enableSorting: true,
  },
  {
    accessorKey: 'needTransport',
    header: 'Necessita de transporte',
    meta: {
      headerClassName: 'text-gray-600 min-w-32 ',
    },
    cell: (props) => {
      const [loading, setLoading] = useState(false);
      const [isEmployeeInfoModalOpen, setIsEmployeeInfoModalOpen] =
        useState(false);
      const openIds = useRecoilValue(trainingFileTeamOpenTableAtom);
      const id = props.row.original.id;
      const isOpen = openIds.includes(id);
      const planningDays = props.row.original.trainingPlanningEmployeeLinkDays;

      const handleCheck = async (checked: boolean, id: number) => {
        setLoading(true);
        try {
          await api.patch(`training-plannings/day/toggle-field/${id}`, {
            needTransport: checked,
          });
          toast.success('Sucesso ao atualizar o status', {
            theme: 'colored',
            toastId: 'needTransport-success',
          });
          queryClient.invalidateQueries(['training-file-details']);
        } catch {
          toast.error('Erro ao atualizar o status', {
            theme: 'colored',
            toastId: 'needTransport-error',
          });
        } finally {
          setLoading(false);
        }
      };
      return (
        <>
          {isEmployeeInfoModalOpen && (
            <EditEmployeeOnTrainingFileTeamModal
              isOpen={isEmployeeInfoModalOpen}
              onClose={() => setIsEmployeeInfoModalOpen(false)}
              employee={props.row.original}
              onlineTraining={onlineTraining}
              asyncTraining={asyncTraining}
            />
          )}
          {planningDays.length === 1 ? (
            <div className="flex h-12 w-[10rem] items-center justify-center gap-3">
              <Checkbox
                defaultChecked={planningDays[0].needTransport}
                onCheckedChange={(checked: boolean) =>
                  handleCheck(checked, planningDays[0].id)
                }
                disabled={props.row.original.concluded}
              />
            </div>
          ) : (
            <TatableCell
              isOpen={isOpen}
              text=" - "
              containerClassName="min-w-40"
            >
              {planningDays.map((planningDay) => (
                <div
                  className="flex h-8 min-h-8 w-full items-center bg-gray-200 p-1"
                  key={planningDay.id}
                >
                  <div className="flex w-full items-center justify-center gap-3">
                    <Checkbox
                      defaultChecked={planningDay.needTransport}
                      onCheckedChange={(checked: boolean) =>
                        handleCheck(checked, planningDay.id)
                      }
                      disabled={props.row.original.concluded}
                    />
                  </div>
                </div>
              ))}
            </TatableCell>
          )}
        </>
      );
    },
    enableSorting: true,
  },
  {
    accessorKey: 'completionDate',
    header: 'Data de conclusão',
    meta: {
      headerClassName: 'text-gray-600',
    },
    cell: (props) => {
      const [completionDate, setCompletionDate] = useState<Date | undefined>(
        props.getValue() && props.getValue() !== '-'
          ? removeTimeZone(new Date(props.getValue() as string))
          : undefined,
      );
      const [isCompletionDateDisabled, setIsCompletionDateDisabled] =
        useState(true);

      useEffect(() => {
        const completionStatus = props.row.original.completionStatus;
        setIsCompletionDateDisabled(
          !completionStatus ||
            (completionStatus !== 'Convalidado' &&
              completionStatus !== 'Concluído'),
        );
      }, [props.row.original.completionStatus]);

      useEffect(() => {
        const currentCompletionDate =
          props.getValue() && props.getValue() !== '-'
            ? removeTimeZone(new Date(props.getValue() as string))
            : undefined;
        if (
          completionDate?.toDateString() !==
          currentCompletionDate?.toDateString()
        ) {
          setCompletionDate(currentCompletionDate);
        }
      }, [props.getValue(), completionDate]);

      const updateCompletionDate = async (e: Date) => {
        try {
          await api.patch(
            `/training-control/${props.row.original?.trainingControlId}`,
            {
              ...(onlineTraining && { onlineCompletionDate: e.toISOString() }),
              ...(!onlineTraining && {
                presencialCompletionDate: e.toISOString(),
              }),
            },
          );
          await api.patch(
            `training-plannings/employee-link/${props.row.original.id}`,
            {
              ...(onlineTraining && { onlineCompletionDate: e.toISOString() }),
              ...(!onlineTraining && {
                presencialCompletionDate: e.toISOString(),
              }),
            },
          );
          toast.success(
            `Data de conclusão alterada para ${format(e, 'dd-MM-yyyy')} com sucesso!`,
            {
              theme: 'colored',
            },
          );
          queryClient.invalidateQueries(['training-file-details']);
        } catch (e: any) {
          const errorMessage =
            e?.response?.status === 400
              ? e?.response?.data?.message
              : 'Não foi possível alterar a data de conclusão!';
          toast.error(errorMessage, {
            theme: 'colored',
            toastId: 'error',
          });
        }
      };

      return (
        <div className="flex w-[14rem] items-start justify-between">
          <EditableTableDate
            value={completionDate}
            update={updateCompletionDate}
            disabled={isCompletionDateDisabled || props.row.original.concluded}
          />
        </div>
      );
    },
    enableSorting: true,
  },
  {
    accessorKey: 'completionStatus',
    header: 'Situação de treinamento',
    meta: {
      headerClassName: 'text-gray-600',
    },
    cell: (props) => {
      const [status, setStatus] = useState<CompletionStatus | '-'>(
        props.getValue() as string as CompletionStatus,
      );
      const [pendingStatus, setPendingStatus] = useState<
        CompletionStatus | '-'
      >('-');

      useEffect(() => {
        const currentStatus = props.getValue() as string as CompletionStatus;
        if (status !== currentStatus) {
          setStatus(currentStatus);
        }
      }, [props, status]);

      const [isJustificationDialogOpen, setIsJustificationDialogOpen] =
        useState(false);
      const [justification, setJustification] = useState('');

      const openJustificationDialog = () => setIsJustificationDialogOpen(true);
      const closeJustificationDialog = () =>
        setIsJustificationDialogOpen(false);

      const completionOptions = onlineTraining
        ? eadAllowedStatuses
        : presencialAllowedStatuses;

      const handleUpdateCompletionStatus = async (
        value: string,
        id: number,
        justification = '',
      ) => {
        try {
          await api.patch(`/training-control/${id}`, {
            trainingModality,
            ...(onlineTraining && { onlineCompletionStatus: value }),
            ...(!onlineTraining && { presencialCompletionStatus: value }),
            ...((value === StatusTrainingControlHistory.Invalidado ||
              value === StatusTrainingControlHistory.NaoAplicavel) && {
              justification,
            }),
          });
          await api.patch(
            `training-plannings/employee-link/${props.row.original.id}`,
            {
              ...(onlineTraining && { onlineCompletionStatus: value }),
              ...(!onlineTraining && {
                presencialCompletionStatus: value,
              }),
            },
          );
          toast.success(
            `Situação de treinamento alterada para ${value} com sucesso!`,
            { theme: 'colored' },
          );
          queryClient.invalidateQueries(['training-file-details']);
          closeJustificationDialog();
          setJustification('');
        } catch (error: any) {
          toast.error(`${error.response.data.message}`, {
            theme: 'colored',
            toastId: 'error',
          });
          throw error;
        }
      };

      const handleStatusChange = (itemValue: string, itemId: number) => {
        if (
          itemValue === StatusTrainingControlHistory.Invalidado ||
          itemValue === StatusTrainingControlHistory.NaoAplicavel
        ) {
          setPendingStatus(itemValue as CompletionStatus);
          openJustificationDialog();
        } else {
          handleUpdateCompletionStatus(itemValue, itemId);
        }
      };

      const options = completionOptions.map((item) => ({
        name: item.label,
        onClick: () =>
          handleStatusChange(
            item.value,
            props.row.original?.trainingControlId || 0,
          ),
        disabled: false,
      }));

      return (
        <div className="ml-2 flex w-[13rem] items-start justify-between">
          <TableCellText
            text={status?.toUpperCase()}
            align="start"
            width="13rem"
          />
          <SimpleMenu
            options={options}
            edit
            disabled={props.row.original.concluded}
            canSave={true}
          />
          <JustificationDialog
            title={`Justifique a situação do treinamento`}
            justification={justification}
            setJustification={setJustification}
            isOpen={isJustificationDialogOpen}
            setIsOpen={setIsJustificationDialogOpen}
            onConfirmation={() =>
              handleUpdateCompletionStatus(
                pendingStatus,
                props.row.original?.trainingControlId || 0,
                justification,
              )
            }
          />
        </div>
      );
    },
    enableSorting: true,
  },
  {
    accessorKey: 'fileUpload',
    header: 'Upload de evidências',
    meta: {
      headerClassName: 'text-gray-600 text-center',
      headerContentClassName: 'w-40',
    },
    cell: (props) => {
      const [isLoading, setIsLoading] = useState(false);

      async function handleFilesSelected(files: File[], id: string) {
        setIsLoading(true);
        const formData = new FormData();
        for (const file of files) {
          formData.append('files', file);
        }

        formData.append('id', id);

        const partModality = onlineTraining
          ? Modality.Online
          : Modality.Presencial;
        try {
          await api.post(
            `/training-control/${props.row.original?.trainingControlId}/upload-evidence/${partModality}`,
            formData,
          );
          toast.success('Arquivo(s) foram salvos com sucesso!', {
            theme: 'colored',
          });
        } catch (e: any) {
          const errorMessage =
            e?.response?.status === 400
              ? e?.response?.data?.message
              : 'Não foi possível salvar o(s) arquivo(s) enviado(s)!';
          toast.error(errorMessage, {
            theme: 'colored',
            toastId: 'error',
          });
        } finally {
          setIsLoading(false);
          queryClient.invalidateQueries(['training-file-details']);
        }
      }
      if (
        ValidStatusesForUploadEvidences.includes(
          props.row.original.completionStatus as StatusTrainingControl,
        ) &&
        props.row.original.completionDate !== '-'
      )
        return (
          <div className="ml-1 flex w-40 items-start justify-center">
            {isLoading ? (
              <CircularProgress className="ml-8" />
            ) : (
              <FileUploadComponent
                disabled={
                  !ValidStatusesForUploadEvidences.includes(
                    props.row.original
                      ?.completionStatus as StatusTrainingControl,
                  ) || !props.row.original?.completionDate
                }
                onFilesSelected={(filesList: FileList | null) => {
                  const filesArray = filesList ? Array.from(filesList) : [];
                  handleFilesSelected(
                    filesArray,
                    String(props.row.original?.trainingControlId),
                  );
                }}
                rowId={String(props.row.original?.trainingControlId)}
              />
            )}
          </div>
        );
    },
  },
];

export const thirdPartycolumns: (
  onlineTraining: boolean,
  asyncTraining: boolean,
  trainingModality: Modality,
) => Array<ColumnDef<TrainingFileTeamEmployee & { concluded: boolean }>> = (
  onlineTraining,
  asyncTraining,
  trainingModality,
) => [
  {
    accessorKey: 'id',
    header: '',
    meta: {
      headerClassName: 'w-20 bg-white',
      stickyClassName: 'sticky left-0 z-50 align-top',
    },
    cell: (props) => {
      const [tableOpen, setTableOpen] = useRecoilState(
        trainingFileTeamOpenTableAtom,
      );
      const id = props.getValue() as number;
      const planningDays = props.row.original.trainingPlanningEmployeeLinkDays;
      const isOpen = tableOpen.includes(id);
      const onToggle = () => {
        if (isOpen) {
          setTableOpen((prev) => prev.filter((it) => it !== id));
        } else {
          setTableOpen((prev) => prev.concat(id));
        }
      };
      if (planningDays.length <= 1) {
        return <div className="h-12 w-8 p-2"></div>;
      }
      return (
        <button
          className="item flex h-12 w-8 items-center p-2 uppercase"
          onClick={onToggle}
        >
          {isOpen ? (
            <ChevronUp className="text-primary" size={16} />
          ) : (
            <ChevronDown className="text-primary" size={16} />
          )}
        </button>
      );
    },
    enableSorting: false,
  },
  {
    accessorKey: 'name',
    header: 'Nome',
    meta: {
      headerClassName: 'bg-white text-gray-600',
      stickyClassName: 'sticky left-8 z-50 align-top',
    },
    cell: (props) => {
      return (
        <div className="flex h-12 w-[20rem] items-center">
          <TableCellText
            text={props.row.original.alias ?? (props.getValue() as string)}
            width="100%"
          />
          <TrainingFileTeamEmployeeMenu
            employeeLink={props.row.original}
            onlineTraining={onlineTraining}
            asyncTraining={asyncTraining}
          />
        </div>
      );
    },
    enablePinning: true,
    enableSorting: true,
  },
  {
    accessorKey: 'employeeNumber',
    header: 'Chapa',
    meta: {
      headerClassName: 'bg-white text-gray-600',
      stickyClassName: 'sticky left-[22rem] z-50 align-top',
    },
    cell: (props) => {
      return (
        <div className="flex h-12 w-20 items-center">
          <TableCellText text={props.getValue() as string} width="100%" />
        </div>
      );
    },
    enableSorting: true,
  },
  {
    accessorKey: 'cpf',
    header: 'CPF',
    meta: {
      headerClassName: 'text-gray-600',
    },
    cell: (props) => {
      return (
        <div className="flex h-12 w-40 items-center">
          <TableCellText text={props.getValue() as string} />
        </div>
      );
    },
    enableSorting: true,
  },
  {
    accessorKey: 'role',
    header: 'Função',
    meta: {
      headerClassName: 'text-gray-600',
    },
    cell: (props) => {
      return (
        <div className="flex h-12 w-40 items-center">
          <TableCellText text={props.getValue() as string} />
        </div>
      );
    },
    enableSorting: true,
  },
  {
    accessorKey: 'confirmedPresence',
    header:
      onlineTraining && asyncTraining
        ? 'Ciência de Liberação'
        : 'Presença confirmada',
    meta: {
      headerClassName: 'text-gray-600',
      tdClassName: 'flex items-center justify-center',
    },
    cell: (props) => {
      const handleCheck = async (checked: boolean) => {
        const id = props.row.original.id;
        try {
          await api.patch(`training-plannings/toggle-field/${id}`, {
            confirmedPresence: checked,
          });
          toast.success('Sucesso ao atualizar o status', {
            theme: 'colored',
            toastId: 'confirmedPresence-success',
          });
          queryClient.invalidateQueries(['training-file-details']);
        } catch {
          toast.error('Erro ao atualizar o status', {
            theme: 'colored',
            toastId: 'confirmedPresence-error',
          });
        }
      };
      return (
        <div className="flex h-12 w-40 items-center">
          <Checkbox
            defaultChecked={props.getValue() as boolean}
            disabled={props.row.original.concluded}
            onCheckedChange={(checked: boolean) => handleCheck(checked)}
          />
        </div>
      );
    },
    enableSorting: true,
  },
  {
    accessorKey: 'date',
    header: 'Data',
    meta: {
      headerClassName: 'text-gray-600',
    },
    cell: (props) => {
      const openIds = useRecoilValue(trainingFileTeamOpenTableAtom);
      const id = props.row.original.id;
      const isOpen = openIds.includes(id);
      const planningDays = props.row.original.trainingPlanningEmployeeLinkDays;
      const tableValue = asyncTraining
        ? 'N/A'
        : planningDays.length === 1
          ? format(new Date(planningDays[0].date), 'dd/MM/yyyy')
          : `${planningDays.length} dias`;
      return (
        <TatableCell
          isOpen={isOpen}
          text={tableValue}
          tableCellClassName={
            planningDays.length ? 'font-bold text-primary' : ''
          }
        >
          {planningDays.map((planningDay) => {
            return (
              <div
                className="flex h-8 min-h-8 w-full items-center bg-gray-200 p-1 font-semibold"
                key={planningDay.id}
              >
                <p>{format(new Date(planningDay.date), 'dd/MM/yyyy')}</p>
              </div>
            );
          })}
        </TatableCell>
      );
    },
    enableSorting: true,
  },

  {
    accessorKey: 'extraMeal',
    header: 'Refeição extra',
    meta: {
      headerClassName: 'text-gray-600 ',
    },
    cell: (props) => {
      const openIds = useRecoilValue(trainingFileTeamOpenTableAtom);
      const id = props.row.original.id;
      const isOpen = openIds.includes(id);
      const planningDays = props.row.original.trainingPlanningEmployeeLinkDays;
      const handleCheck = async (checked: boolean, id: number) => {
        try {
          await api.patch(`training-plannings/day/toggle-field/${id}`, {
            extraMeal: checked,
          });
          toast.success('Sucesso ao atualizar o status', {
            theme: 'colored',
            toastId: 'extraMeal-success',
          });
          queryClient.invalidateQueries(['training-file-details']);
        } catch {
          toast.error('Erro ao atualizar o status', {
            theme: 'colored',
            toastId: 'extraMeal-error',
          });
        }
      };
      return (
        <>
          {planningDays.length === 1 ? (
            <div className="flex h-12 w-[10rem] items-center justify-center gap-3">
              <Checkbox
                defaultChecked={planningDays[0].extraMeal}
                onCheckedChange={(checked: boolean) =>
                  handleCheck(checked, planningDays[0].id)
                }
                disabled={props.row.original.concluded}
              />
            </div>
          ) : (
            <TatableCell
              isOpen={isOpen}
              text=" - "
              containerClassName="min-w-40"
            >
              {planningDays.map((planningDay) => (
                <div
                  className="flex h-8 min-h-8 w-full flex-1 items-center bg-gray-200"
                  key={planningDay.id}
                >
                  <Checkbox
                    defaultChecked={planningDay.extraMeal}
                    onCheckedChange={(checked: boolean) =>
                      handleCheck(checked, planningDay.id)
                    }
                    disabled={props.row.original.concluded}
                  />
                </div>
              ))}
            </TatableCell>
          )}
        </>
      );
    },
    enableSorting: true,
  },
  {
    accessorKey: 'needTransport',
    header: 'Necessita de transporte',
    meta: {
      headerClassName: 'text-gray-600 min-w-32 ',
    },
    cell: (props) => {
      const [isEmployeeInfoModalOpen, setIsEmployeeInfoModalOpen] =
        useState(false);

      const openIds = useRecoilValue(trainingFileTeamOpenTableAtom);
      const id = props.row.original.id;
      const isOpen = openIds.includes(id);
      const planningDays = props.row.original.trainingPlanningEmployeeLinkDays;

      const handleCheck = async (checked: boolean, id: number) => {
        try {
          await api.patch(`training-plannings/day/toggle-field/${id}`, {
            needTransport: checked,
          });
          toast.success('Sucesso ao atualizar o status', {
            theme: 'colored',
            toastId: 'needTransport-success',
          });
          queryClient.invalidateQueries(['training-file-details']);
        } catch {
          toast.error('Erro ao atualizar o status', {
            theme: 'colored',
            toastId: 'needTransport-error',
          });
        }
      };
      return (
        <>
          {isEmployeeInfoModalOpen && (
            <EditEmployeeOnTrainingFileTeamModal
              isOpen={isEmployeeInfoModalOpen}
              onClose={() => setIsEmployeeInfoModalOpen(false)}
              employee={props.row.original}
              onlineTraining={onlineTraining}
              asyncTraining={asyncTraining}
            />
          )}
          {planningDays.length === 1 ? (
            <div className="flex h-12 w-[10rem] items-center justify-center gap-3">
              <Checkbox
                defaultChecked={planningDays[0].needTransport}
                onCheckedChange={(checked: boolean) =>
                  handleCheck(checked, planningDays[0].id)
                }
                disabled={props.row.original.concluded}
              />
            </div>
          ) : (
            <TatableCell
              isOpen={isOpen}
              text=" - "
              containerClassName="min-w-40"
            >
              {planningDays.map((planningDay) => (
                <div
                  className="flex h-8 min-h-8 w-full items-center bg-gray-200 p-1"
                  key={planningDay.id}
                >
                  <div className="flex w-full items-center justify-center gap-3">
                    <Checkbox
                      defaultChecked={planningDay.needTransport}
                      onCheckedChange={(checked: boolean) =>
                        handleCheck(checked, planningDay.id)
                      }
                      disabled={props.row.original.concluded}
                    />
                  </div>
                </div>
              ))}
            </TatableCell>
          )}
        </>
      );
    },
    enableSorting: true,
  },
  {
    accessorKey: 'completionDate',
    header: 'Data de conclusão',
    meta: {
      headerClassName: 'text-gray-600',
    },
    cell: (props) => {
      const [completionDate, setCompletionDate] = useState<Date | undefined>(
        props.getValue() && props.getValue() !== '-'
          ? removeTimeZone(new Date(props.getValue() as string))
          : undefined,
      );

      useEffect(() => {
        const currentCompletionDate =
          props.getValue() && props.getValue() !== '-'
            ? removeTimeZone(new Date(props.getValue() as string))
            : undefined;
        if (
          completionDate?.toDateString() !==
          currentCompletionDate?.toDateString()
        ) {
          setCompletionDate(currentCompletionDate);
        }
      }, [props.getValue(), completionDate]);

      const updateCompletionDate = async (e: Date) => {
        try {
          await api.patch(
            `/training-control/${props.row.original?.trainingControlId}`,
            {
              trainingModality,
              ...(onlineTraining && {
                onlineCompletionDate: (e as Date).toISOString(),
              }),
              ...(!onlineTraining && {
                presencialCompletionDate: (e as Date).toISOString(),
              }),
            },
          );
          toast.success(
            `Data de conclusão alterada para ${format(e, 'dd-MM-yyyy')} com sucesso!`,
            {
              theme: 'colored',
            },
          );
          queryClient.invalidateQueries(['training-file-details']);
        } catch (e: any) {
          const errorMessage =
            e?.response?.status === 400
              ? e?.response?.data?.message
              : 'Não foi possível alterar a data de conclusão!';
          toast.error(errorMessage, {
            theme: 'colored',
            toastId: 'error',
          });
        }
      };

      return (
        <div className="flex w-[14rem] items-start justify-between">
          <EditableTableDate
            value={completionDate}
            update={updateCompletionDate}
            disabled={props.row.original.concluded}
          />
        </div>
      );
    },
    enableSorting: true,
  },
  {
    accessorKey: 'completionStatus',
    header: 'Situacao de treinamento',
    meta: {
      headerClassName: 'text-gray-600',
    },
    cell: (props) => {
      const [status, setStatus] = useState<CompletionStatus | '-'>(
        props.getValue() as string as CompletionStatus,
      );
      const [pendingStatus, setPendingStatus] = useState<
        CompletionStatus | '-'
      >('-');

      useEffect(() => {
        const currentStatus = props.getValue() as string as CompletionStatus;
        if (status !== currentStatus) {
          setStatus(currentStatus);
        }
      }, [props, status]);

      const [isJustificationDialogOpen, setIsJustificationDialogOpen] =
        useState(false);
      const [justification, setJustification] = useState('');

      const openJustificationDialog = () => setIsJustificationDialogOpen(true);
      const closeJustificationDialog = () =>
        setIsJustificationDialogOpen(false);

      const completionOptions = onlineTraining
        ? eadAllowedStatuses
        : presencialAllowedStatuses;

      const handleUpdateCompletionStatus = async (
        value: string,
        id: number,
        justification = '',
      ) => {
        try {
          await api.patch(`/training-control/${id}`, {
            trainingModality,
            completionStatus: value,
            ...((value === StatusTrainingControlHistory.Invalidado ||
              value === StatusTrainingControlHistory.NaoAplicavel) && {
              justification,
            }),
          });
          toast.success(
            `Situação de treinamento alterada para ${value} com sucesso!`,
            { theme: 'colored' },
          );
          queryClient.invalidateQueries(['training-file-details']);
          closeJustificationDialog();
          setJustification('');
        } catch (error: any) {
          toast.error(`${error.response.data.message}`, {
            theme: 'colored',
            toastId: 'error',
          });
          throw error;
        }
      };

      const handleStatusChange = (itemValue: string, itemId: number) => {
        if (
          itemValue === StatusTrainingControlHistory.Invalidado ||
          itemValue === StatusTrainingControlHistory.NaoAplicavel
        ) {
          setPendingStatus(itemValue as CompletionStatus);
          openJustificationDialog();
        } else {
          handleUpdateCompletionStatus(itemValue, itemId);
        }
      };

      const options = completionOptions.map((item: any) => ({
        name: item.label,
        onClick: () =>
          handleStatusChange(
            item.value,
            props.row.original?.trainingControlId || 0,
          ),
        disabled: false,
      }));

      return (
        <div className="ml-2 flex w-[13rem] items-start justify-between">
          <TableCellText
            text={status?.toUpperCase()}
            align="start"
            width="13rem"
          />
          <SimpleMenu
            options={options}
            edit
            disabled={props.row.original.concluded}
            canSave={true}
          />
          <JustificationDialog
            title={`Justifique a situação do treinamento`}
            justification={justification}
            setJustification={setJustification}
            isOpen={isJustificationDialogOpen}
            setIsOpen={setIsJustificationDialogOpen}
            onConfirmation={() =>
              handleUpdateCompletionStatus(
                pendingStatus,
                props.row.original?.trainingControlId || 0,
                justification,
              )
            }
          />
        </div>
      );
    },
    enableSorting: true,
  },
  {
    accessorKey: 'fileUpload',
    header: 'Upload de evidências',
    meta: {
      headerClassName: 'text-gray-600 text-center',
    },
    cell: (props) => {
      const [isLoading, setIsLoading] = useState(false);

      async function handleFilesSelected(files: File[]) {
        setIsLoading(true);
        const formData = new FormData();
        for (const file of files) {
          formData.append('files', file);
        }

        const partModality = onlineTraining
          ? Modality.Online
          : Modality.Presencial;
        try {
          await api.post(
            `/training-control/${props.row.original?.trainingControlId}/upload-evidence/${partModality}`,
            formData,
          );
          toast.success('Arquivo(s) foram salvos com sucesso!', {
            theme: 'colored',
          });
        } catch (e: any) {
          const errorMessage =
            e?.response?.status === 400
              ? e?.response?.data?.message
              : 'Não foi possível salvar o(s) arquivo(s) enviado(s)!';
          toast.error(errorMessage, {
            theme: 'colored',
            toastId: 'error',
          });
        } finally {
          setIsLoading(false);
          queryClient.invalidateQueries(['training-file-details']);
        }
      }
      if (
        ValidStatusesForUploadEvidences.includes(
          props.row.original.completionStatus as StatusTrainingControl,
        ) &&
        props.row.original.completionDate !== '-'
      )
        return (
          <div className="ml-1 flex w-[15rem] items-start justify-center">
            {isLoading ? (
              <CircularProgress className="ml-8" />
            ) : (
              <FileUploadComponent
                disabled={props.row.original.concluded}
                onFilesSelected={(filesList: FileList | null) => {
                  const filesArray = filesList ? Array.from(filesList) : [];
                  handleFilesSelected(filesArray);
                }}
                rowId={String(props.row.original?.trainingControlId)}
              />
            )}
          </div>
        );
    },
  },
];
