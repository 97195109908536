import { z } from 'zod';

import { AllocationMotive } from './AllocationRequest';
import {
  getStatusName,
  PendencyStatus,
  PendencyType,
} from './PendencyConstants';

export const PendingRequestSchema = z.object({
  requestId: z.number(),
  employeeId: z.number(),
  managementId: z.number(),
  teamStructureId: z.number(),
  requesterName: z.string(),
  employeeName: z.string(),
  employeeNumber: z.string(),
  management: z.string(),
  actualTeamStructure: z.string().optional(),
  actualTeam: z.string().optional(),
  newTeamStructure: z.string(),
  newTeam: z.string(),
  requestDate: z.string(),
  motive: z.enum([
    AllocationMotive.OrigemCarreiras,
    AllocationMotive.Transferencia,
    AllocationMotive.CoberturaDeFerias,
    AllocationMotive.DeterminacaoDaEmpresa,
  ]),
});

export const PendingRequestListSchema = z.object({
  pendingRequests: z.array(PendingRequestSchema),
  count: z.number(),
  nextPage: z.number().optional(),
});

export type PendingRequest = z.infer<typeof PendingRequestSchema>;

export type PendingRequestList = z.infer<typeof PendingRequestListSchema>;

export const TurnstileApprovalSchema = z.object({
  pendencyId: z.number(),
  employeeId: z.number(),
  managementId: z.number(),
  teamStructureId: z.number(),
  status: z.number(),
  type: z.string(),
  requestDate: z.string(),
  completionDate: z.string().nullable(),
  employeeName: z.string(),
  employeeNumber: z.string(),
  teamStructureName: z.string(),
  team: z.string(),
  scale: z.string(),
  regime: z.string(),
  area: z.string(),
  sector: z.string(),
  workStation: z.string(),
  management: z.string(),
});

export const TurnstileApprovalListSchema = z.object({
  pendencyData: z.array(TurnstileApprovalSchema),
  count: z.number(),
  nextPage: z.number().optional(),
});

export type TurnstileApproval = z.infer<typeof TurnstileApprovalSchema>;

export type TurnstileApprovalList = z.infer<typeof TurnstileApprovalListSchema>;

const TurnstileApprovalFilterOption = z
  .object({
    id: z
      .union([z.number(), z.string()])
      .transform((id) => String(id))
      .pipe(z.string()),
    name: z.string(),
  })
  .transform(({ id, name }) => ({
    value: id,
    label: name,
  }));

const TurnstileApprovalFilterSingleOption = z.string().transform((value) => {
  return {
    value,
    label: value,
  };
});

const TurnstileApprovalFilterStatusOption = z.number().transform((status) => {
  return {
    value: status.toString(),
    label: getStatusName(status),
  };
});

export const TurnstileApprovalFilterOptionsSchema = z.object({
  managements: z.array(TurnstileApprovalFilterOption).default([]),
  teamStructures: z.array(TurnstileApprovalFilterOption).default([]),
  areas: z.array(TurnstileApprovalFilterOption).default([]),
  workstations: z.array(TurnstileApprovalFilterOption).default([]),
  scales: z.array(TurnstileApprovalFilterSingleOption).default([]),
  regimes: z.array(TurnstileApprovalFilterSingleOption).default([]),
  sectors: z.array(TurnstileApprovalFilterSingleOption).default([]),
  types: z.array(TurnstileApprovalFilterSingleOption).default([]),
  statuses: z.array(TurnstileApprovalFilterStatusOption).default([]),
});

export type TurnstileApprovalFiltersOptions = z.infer<
  typeof TurnstileApprovalFilterOptionsSchema
>;

export function getCancelledTooltipMessage(
  type: string,
  status: number,
): string {
  if (status !== PendencyStatus.CANCELLED) {
    return '';
  }
  if (type === PendencyType.TURNSTILE_APPROVAL) {
    return 'SEM LIBERAÇÃO DE ACESSO';
  }
  return 'SEM APROVAÇÃO DE REALOCAÇÃO';
}
