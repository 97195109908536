import * as AccordionPrimitive from '@radix-ui/react-accordion';
import { PropsWithChildren } from 'react';
import { MdExpandMore } from 'react-icons/md';

interface JobTitleAccordionProps extends PropsWithChildren {
  title: string;
  defaultExpanded?: boolean;
  className?: string;
}

const JobTitleAccordion = ({
  title,
  defaultExpanded,
  children,
}: JobTitleAccordionProps) => {
  return (
    <AccordionPrimitive.Root
      type="multiple"
      className="w-full rounded-[10px]"
      defaultValue={defaultExpanded ? ['1'] : []}
    >
      <AccordionPrimitive.Item
        value="1"
        className="w-full rounded-[10px] bg-white"
      >
        <AccordionPrimitive.Header className="relative w-full rounded-[10px] bg-white">
          <AccordionPrimitive.Trigger className="group flex w-full grow rounded-[10px] px-5 py-[18px]">
            <h3 className="text-left text-base font-medium text-[#4A4A4A]">
              {title}
            </h3>
            <MdExpandMore className="absolute right-[30px] ml-auto text-3xl text-primary transition-transform duration-300 ease-[cubic-bezier(0.87,_0,_0.13,_1)] group-data-[state=open]:rotate-180" />
            {/* <SimpleMenu
              options={[
                {
                  name: 'teste',
                  onClick: (e) => {
                    e.stopPropagation();
                    console.log('teste');
                  },
                  disabled: false,
                },
              ]}
              upDotsButton
            /> */}
          </AccordionPrimitive.Trigger>
        </AccordionPrimitive.Header>
        <AccordionPrimitive.Content className="data-[state=open]:animate-slideDown data-[state=closed]:animate-slideUp overflow-hidden rounded-[10px] bg-white px-5 text-[15px]">
          <section className="flex flex-col gap-2 pb-4 pt-[10px]">
            {children}
          </section>
        </AccordionPrimitive.Content>
      </AccordionPrimitive.Item>
    </AccordionPrimitive.Root>
  );
};

export default JobTitleAccordion;
