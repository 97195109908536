import { useInfiniteQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useRecoilState } from 'recoil';

import { PendencyStatus, PendencyType } from '@/constants/PendencyConstants';
import { closedColumns } from '@/constants/tableColumns/TurnstileApprovalTableColumns';
import { TurnstileApprovalListSchema } from '@/constants/TurnstileApprovalsConstants';
import api from '@/services/apiSgft';
import { TurnstileApprovalFiltersAtom } from '@/state/TurnstileApprovalFilter.atom';

import EmptyIcon from '../../assets/icons/empty.svg';
import Spinner from '../atoms/Spinner';
import InfiniteTable from './InfiniteTable';

const ClosedTurnstileApprovalsTab = () => {
  const [filteredData, setFilteredData] = useRecoilState(
    TurnstileApprovalFiltersAtom,
  );

  const fetchStructures = async ({ pageParam = 1 }) => {
    const status = filteredData.status.length
      ? filteredData.status
      : [
          PendencyStatus.CLOSED,
          PendencyStatus.REFUSED,
          PendencyStatus.CANCELLED,
        ];
    const type = filteredData.type.length
      ? filteredData.type
      : [PendencyType.ALLOCATION_REQUEST, PendencyType.TURNSTILE_APPROVAL];
    try {
      const response = await api.get('pendencies/turnstile-approvals', {
        params: {
          size: 20,
          page: pageParam,
          status,
          type,
          management: filteredData.management,
          teamStructure: filteredData.teamStructure,
          area: filteredData.area,
          sector: filteredData.sector,
          workStation: filteredData.workStation,
          scale: filteredData.scale,
        },
      });
      const parsedData = TurnstileApprovalListSchema.parse(response.data);
      return parsedData;
    } catch (e) {
      toast.error('Erro ao carregar os dados', {
        theme: 'colored',
        toastId: 'error',
      });
      throw e;
    }
  };

  const { data, isLoading, fetchNextPage, isFetchingNextPage, hasNextPage } =
    useInfiniteQuery(
      ['closed-turnstile-approvals', filteredData],
      fetchStructures,
      {
        retry: false,
        getNextPageParam: (actualPage) => {
          return actualPage.nextPage;
        },
      },
    );

  const closedTurnstilesApprovals =
    data?.pages.flatMap((page) => page.pendencyData) ?? [];

  useEffect(() => {
    setFilteredData((prev) => {
      return {
        ...prev,
        tab: 2,
        type: prev.type.length ? prev.type : [],
        status: prev.status.length ? prev.status : [],
      };
    });
  }, []);

  return (
    <div className="w-full rounded-md bg-white px-4 py-4">
      {isLoading ? (
        <div className="flex h-full w-full items-center rounded-md bg-white">
          <Spinner size={50} />
        </div>
      ) : closedTurnstilesApprovals.length > 0 ? (
        <div className="max-w-full overflow-x-auto scrollbar-thin scrollbar-thumb-[#D9D9D9] scrollbar-thumb-rounded-full">
          <InfiniteTable
            hasNextPage={hasNextPage}
            data={closedTurnstilesApprovals}
            columns={closedColumns}
            fetchNextPage={fetchNextPage}
            isFetchingNextPage={isFetchingNextPage}
            alignTextLeft
          />
        </div>
      ) : (
        <div className="flex h-full w-full flex-col items-center justify-center py-10">
          <img src={EmptyIcon} className="w-40" />
          <div className="flex flex-col items-center text-sm">
            <p>Nenhuma liberação concluída encontrada com esses parâmetros</p>
            <span className="font-semibold text-blue-800">
              Liberações concluídas aparecerão aqui
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClosedTurnstileApprovalsTab;
