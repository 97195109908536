import * as r from '@recoiljs/refine';
import { atom } from 'recoil';
import { urlSyncEffect } from 'recoil-sync';

export interface EmployeeFiltersAtom {
  workStation: string[];
  management: string[];
  employee: string;
  teamStructure: string[];
  role: string[];
}

const stringArrayChecker = r.array(r.string()) as r.Checker<string[]>;

export const employeeFiltersAtom = atom<EmployeeFiltersAtom>({
  key: 'employee',
  default: {
    workStation: [],
    management: [],
    employee: '',
    teamStructure: [],
    role: [],
  },
  effects: [
    urlSyncEffect<EmployeeFiltersAtom>({
      refine: r.object({
        workStation: stringArrayChecker,
        management: stringArrayChecker,
        employee: r.string(),
        teamStructure: stringArrayChecker,
        role: stringArrayChecker,
      }),
      history: 'push',
    }),
  ],
});
