/* eslint-disable no-unused-vars */
export interface SelectOption {
  value: string;
  label: string;
}

export const locationOptions: SelectOption[] = [
  { value: 'Alagoas', label: 'Alagoas' },
  { value: 'Tucano Sul', label: 'Tucano Sul' },
  { value: 'Matriz', label: 'Matriz' },
  { value: 'TAMAC', label: 'Terminal Aquaviáro de Maceió (TAMAC)' },
];

export enum StatusTrainingControl {
  NaoAplicavel = 'Não aplicável',
  AguardandoLiberacao = 'Aguardando liberação',
  Liberado = 'Liberado',
  Planejado = 'Planejado',
  AutorizadoPeloGestor = 'Autorizado pelo gestor',
  NaoAutorizadoPeloGestor = 'Não Autorizado pelo gestor',
  Confirmado = 'Confirmado',
  NaoPlanejado = 'Não planejado',
  Concluido = 'Concluído',
  Finalizado = 'Finalizado',
  Convalidado = 'Convalidado',
  Invalidado = 'Invalidado',
  Reprovado = 'Reprovado',
  NaoRealizado = 'Não realizado',
  ConcluidoPorEquivalencia = 'Concluído por equivalencia',
  ConvocacaoRejeitada = 'Convocação Rejeitada',
}

export enum StatusTrainingControlHistory {
  Concluido = 'Concluído',
  Reprovado = 'Reprovado',
  NaoRealizado = 'Não realizado',
  Convalidado = 'Convalidado',
  Invalidado = 'Invalidado',
  NaoAplicavel = 'Não aplicável',
  Finalizado = 'Finalizado',
  ConcluidoPorEquivalencia = 'Concluído por equivalencia',
}

export const historyStatusOptions: SelectOption[] = [
  {
    value: StatusTrainingControlHistory.Concluido,
    label: StatusTrainingControlHistory.Concluido,
  },
  {
    value: StatusTrainingControlHistory.Reprovado,
    label: StatusTrainingControlHistory.Reprovado,
  },
  {
    value: StatusTrainingControlHistory.NaoRealizado,
    label: StatusTrainingControlHistory.NaoRealizado,
  },
  {
    value: StatusTrainingControlHistory.Convalidado,
    label: StatusTrainingControlHistory.Convalidado,
  },
  {
    value: StatusTrainingControlHistory.Invalidado,
    label: StatusTrainingControlHistory.Invalidado,
  },
  {
    value: StatusTrainingControlHistory.NaoAplicavel,
    label: StatusTrainingControlHistory.NaoAplicavel,
  },
];

export const greenStatus = ['Válido'];

export const yellowStatus = ['Vence em 3 meses', 'Vence em 1 mês'];

export const redStatus = ['Vencido', 'Inválido', 'Pendente'];

export const eadAllowedStatuses = [
  { label: 'Não aplicável', value: 'Não aplicável' },
  { label: 'Aguardando liberação', value: 'Aguardando liberação' },
  { label: 'Concluído', value: 'Concluído' },
  { label: 'Reprovado', value: 'Reprovado' },
  { label: 'Não realizado', value: 'Não realizado' },
  { label: 'Convalidado', value: 'Convalidado' },
  { label: 'Invalidado', value: 'Invalidado' },
  { label: 'Convocação Rejeitada', value: 'Convocação Rejeitada' },
];

export const presencialAllowedStatuses = [
  { label: 'Não aplicável', value: 'Não aplicável' },
  { label: 'Não planejado', value: 'Não planejado' },
  { label: 'Concluído', value: 'Concluído' },
  { label: 'Reprovado', value: 'Reprovado' },
  { label: 'Não realizado', value: 'Não realizado' },
  { label: 'Convalidado', value: 'Convalidado' },
  { label: 'Invalidado', value: 'Invalidado' },
  { label: 'Convocação Rejeitada', value: 'Convocação Rejeitada' },
];

export const ValidStatusesForInvalidStatus = [
  StatusTrainingControl.Concluido,
  StatusTrainingControl.Convalidado,
  StatusTrainingControl.Finalizado,
];

export const ValidStatusesForUploadEvidences = [
  StatusTrainingControl.Concluido,
  StatusTrainingControl.Convalidado,
  StatusTrainingControl.Invalidado,
  StatusTrainingControl.Finalizado,
  StatusTrainingControl.ConcluidoPorEquivalencia,
];

export enum Modality {
  Online = 'Online',
  Presencial = 'Presencial',
  Semipresencial = 'Semipresencial',
  SomenteLeitura = 'Somente Leitura',
}

export enum OnlineTrainingType {
  Sync = 'Síncrono',
  Async = 'Assíncrono',
}
export enum ProcedureModality {
  Online = 'Online',
  Presencial = 'Presencial',
  SomenteLeitura = 'Somente Leitura',
}

export const modalityOptions: SelectOption[] = [
  { value: Modality.Online, label: 'Online' },
  { value: Modality.Semipresencial, label: 'Semipresencial' },
  { value: Modality.Presencial, label: 'Presencial' },
];

export const procedureModalityOptions: SelectOption[] = [
  { value: ProcedureModality.Online, label: 'Online' },
  { value: ProcedureModality.Presencial, label: 'Presencial' },
  { value: ProcedureModality.SomenteLeitura, label: 'Somente Leitura' },
];
