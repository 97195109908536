import { ReactFlowProvider } from '@xyflow/react';
import { format } from 'date-fns';
import React, { useEffect, useRef, useState } from 'react';
import { MdOutlineFileDownload } from 'react-icons/md';
import { TbPinnedFilled } from 'react-icons/tb';
import { useReactToPrint } from 'react-to-print';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';

import api from '@/services/apiSgft';
import { hideEmployeesAtom } from '@/state/HideEmployeesChart.atom';
import { cn } from '@/utils/cn';
import { getColor } from '@/utils/getColorByChart';
import { subtitleEntries } from '@/utils/getSubtitleByChart';
import { trackEvent } from '@/utils/trackEvent';

import { Button } from '../atoms/Button';
import ChartBreadcrumbs from '../atoms/ChartBreadcrumbs';
import LightTooltip from '../atoms/LightTooltip';
import ChartGraph, { Graph } from '../organisms/ChartGraph';

const mapValue = (
  x: number,
  xMin: number,
  xMax: number,
  yMin: number,
  yMax: number,
): number => {
  return yMin + ((x - xMin) * (yMax - yMin)) / (xMax - xMin);
};

const xMin = 1366; // notebook screen width
const xMax = 1920; // monitor screen width
const yMin = 245;
const yMax = -45;

export const ChartDownloadPdfButton = () => {
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = useState<Graph[]>([]);
  const hideEmployees = useRecoilValue(hideEmployeesAtom);

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => {
      setData([]);
      setLoading(false);
    },
  });

  async function download() {
    setLoading(true);
    try {
      const url = hideEmployees
        ? '/position/organization-chart/export'
        : '/position/person-chart/export';
      const response = await api.get(url);
      setData(response.data);
    } catch (e) {
      toast.error('Estrutura sem dados', {
        theme: 'colored',
        toastId: 'error',
      });
      trackEvent(
        'Download do Personograma',
        'file_download',
        'Erro ao realizar o Download',
      );
      throw e;
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    const handleDataChange = () => {
      if (data.length > 0) {
        setTimeout(() => {
          handlePrint();
          trackEvent('Download do Personograma', 'file_download', '');
        }, 500);
      }
    };

    handleDataChange();
  }, [data]);

  const renderGraph = (data: Graph, index: number) => {
    return (
      <div className="flex h-[100vh] w-[100vw] flex-col justify-between p-0">
        <div className="flex h-[8%] justify-between">
          <ChartBreadcrumbs
            board={data.board}
            management={data.management}
            coordination={data.coordination}
            className="text-[14px]"
            toPrint={true}
          />
          <img
            src="/origem.png"
            alt="Logo Origem"
            className="h-[62px] w-[155px]"
          />
        </div>
        <ChartGraph
          key={index}
          data={data}
          defaultViewPort={{
            x: mapValue(innerWidth, xMin, xMax, yMin, yMax),
            y: 0,
            zoom: 0.95,
          }}
          toPrint={true}
        />
        <div className="bottom-0 flex w-full flex-col items-start gap-2">
          <div className="flex h-12 flex-col justify-end text-[14px] text-black">
            {`Data de extração: ${format(new Date(), 'dd/MM/yyyy')}`}
          </div>
          <div className="flex flex-col justify-center gap-1 rounded-[0.5rem] border-[0.5px] border-[#c4c4c4] p-2">
            <div className="text-sm">Legenda:</div>
            <div className="flex flex-wrap items-center text-xs text-gray-600">
              {subtitleEntries.map(([value, legend]: [string, string]) => {
                const textColor = getColor(`${value}-text`);
                return (
                  <div
                    className="mr-[1rem] flex items-center whitespace-nowrap"
                    key={`legend-${value}`}
                  >
                    <div
                      className={cn(
                        `mr-1 flex h-[22px] w-[40px] items-center justify-center rounded text-[10px] ${textColor}`,
                        getColor(value),
                      )}
                    >
                      {(value === 'ADM' || value === 'TURNO') && value}
                    </div>
                    <div>{legend}</div>
                  </div>
                );
              })}
              <div className="flex items-center gap-1">
                <div className="flex w-4 items-center justify-center">
                  <TbPinnedFilled className="text-[#FD8E28]" size={14} />
                </div>
                <div>Alocação Fixa</div>
              </div>
              <div className="ml-2 flex items-center gap-1">
                <div className="flex w-4 items-center justify-center">
                  <TbPinnedFilled className="text-[#9B54FF]" size={14} />
                </div>
                <div>Alocação Fixa - PJ</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="flex items-center">
      <LightTooltip
        title={`Extrair ${hideEmployees ? 'Estrutura' : 'Organograma'}`}
      >
        <Button
          className="ml-2 flex h-9 w-9 items-center justify-center rounded-full bg-transparent p-1  hover:bg-gray-200/70"
          onClick={download}
          isLoading={loading}
          variant={'secondary'}
        >
          <MdOutlineFileDownload className="fill-gray-500" size={20} />
        </Button>
      </LightTooltip>
      <div ref={componentRef}>
        {(data as Graph[])?.map((page, index) => {
          return (
            <div
              key={index}
              className="landscape-section h-0 w-0 overflow-hidden print:h-auto print:w-auto"
            >
              <ReactFlowProvider key={index}>
                <div className="h-[100vh] w-[100vw] print:block">
                  {renderGraph(page, index)}
                </div>
              </ReactFlowProvider>
            </div>
          );
        })}
      </div>
    </div>
  );
};
