import { TextField } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { Button } from './Button';
import Line from './Line';
import SubtitleText from './SubtitleText';

interface Props {
  title: string;
  justification: string;
  setJustification: (value: string) => void;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  onConfirmation: () => void;
}

export const JustificationDialog = ({
  title,
  justification,
  setJustification,
  isOpen,
  setIsOpen,
  onConfirmation,
}: Props) => {
  function onClose() {
    setJustification('');
    setIsOpen(false);
  }

  return (
    <Dialog
      PaperProps={{
        style: {
          borderRadius: '20px',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
          overflowY: 'auto',
          height: 'auto',
          width: '100%',
          maxWidth: '30rem',
        },
      }}
      slotProps={{ backdrop: { sx: { background: 'rgba(0, 0, 0, 0.25)' } } }}
      open={isOpen}
      onClose={onClose}
    >
      <DialogTitle>
        <div className="flex h-auto items-center justify-between">
          <SubtitleText className="text-[18px]" subtitle={title} />
        </div>
        <Line />
      </DialogTitle>
      <DialogContent
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <TextField
          autoFocus
          margin="dense"
          id="justification"
          label="Justificativa"
          type="text"
          fullWidth
          variant="outlined"
          multiline
          rows={3}
          value={justification}
          required
          onChange={(e) => setJustification(e.target.value)}
          placeholder={''}
        />
      </DialogContent>
      <DialogActions
        sx={{
          width: 'full',
          display: 'flex',
          justifyContent: 'space-around',
          paddingBottom: '25px',
        }}
      >
        <Button
          type="button"
          className="min-w-40 border-2 border-[#193CB9] bg-white text-[#193CB9] hover:bg-[#e9eaf1]"
          onClick={onClose}
        >
          Cancelar
        </Button>
        <Button
          type="button"
          className={`min-w-40 ${!justification.trim() ? 'cursor-not-allowed opacity-50' : ''}`}
          onClick={onConfirmation}
          disabled={!justification.trim()} // Disable the button when justification is empty
        >
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
