import { atom } from 'recoil';

export interface NewJobTitleAtom extends Record<string, string[] | string> {
  titles: string;
  seniorityLevels: string[];
  companies: string[];
  boards: string[];
  managements: string[];
  workStations: string[];
  // requirementTypes: string[];
  // educationalRequirements: string[];
  // professionalRequirements: string[];
  // otherRequirements: string[];
}

export const newJobTitleAtom = atom<NewJobTitleAtom>({
  key: 'newJobTitle',
  default: {
    titles: '',
    seniorityLevels: [],
    companies: [],
    boards: [],
    managements: [],
    workStations: [],
    // requirementTypes: [],
    // educationalRequirements: [],
    // professionalRequirements: [],
    // otherRequirements: [],
  },
});
