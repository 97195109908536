import IconButton from '@mui/material/IconButton';
import React from 'react';
import { CgSpinner } from 'react-icons/cg';
import { FaRegFile } from 'react-icons/fa';
import { toast } from 'react-toastify';

import { downloadFile } from '@/utils/downloadFile';

import LightTooltip from '../atoms/LightTooltip';

export const RequirementControlTemplateButton = () => {
  const [loading, setLoading] = React.useState(false);

  async function download() {
    setLoading(true);
    try {
      downloadFile(
        '/extraction/requirement-control-template',
        'Controle de Requisitos.xlsx',
      );
    } catch (e) {
      toast.error('Não foi possível fazer o download do template', {
        theme: 'colored',
        toastId: 'error',
      });
      throw e;
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="cursor-pointer">
      <LightTooltip title="Baixar template para atualização em massa">
        <IconButton onClick={download} disabled={loading}>
          {loading ? (
            <CgSpinner
              size={30}
              color="#c1c1c1"
              className="h-full animate-spin"
            />
          ) : (
            <FaRegFile size={22} />
          )}
        </IconButton>
      </LightTooltip>
    </div>
  );
};
