import { ColumnDef } from '@tanstack/react-table';
import { format } from 'date-fns';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { useState } from 'react';
import { CgSpinner } from 'react-icons/cg';
import { FaArrowUpRightFromSquare } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useRecoilState, useRecoilValue } from 'recoil';

import { queryClient } from '@/App';
import FileUploadComponent from '@/components/atoms/FileUpload';
import SimpleMenu from '@/components/atoms/Menu';
import { TatableCell } from '@/components/atoms/TatableCell';
import { ViewFiles } from '@/components/atoms/ViewFiles';
import ModalConfirmation from '@/components/molecules/ConfirmationModal';
import { TrainingFileTeamInstructors } from '@/components/molecules/TrainingFileTeamInstructors';
import api from '@/services/apiSgft';
import { trainingFileOpenTableAtom } from '@/state/TrainingFileOpenTable.atom';
import { TrainingFileTeam } from '@/types/TrainingFile';

import TableCellText from '../../components/atoms/TableCellText';

export const columns: Array<ColumnDef<TrainingFileTeam>> = [
  {
    accessorKey: 'id',
    header: '',
    meta: {
      headerClassName: 'w-20',
    },
    cell: (props) => {
      const [tableOpen, setTableOpen] = useRecoilState(
        trainingFileOpenTableAtom,
      );
      const id = props.getValue() as number;
      const planningDays = props.row.original.planningDays;
      const isOpen = tableOpen.includes(id);
      const onToggle = () => {
        if (isOpen) {
          setTableOpen((prev) => prev.filter((it) => it !== id));
        } else {
          setTableOpen((prev) => prev.concat(id));
        }
      };
      if (planningDays.length <= 1) {
        return <div className="h-12 w-8 p-2"></div>;
      }
      return (
        <button
          className="item flex h-12 w-8 items-center p-2 uppercase"
          onClick={onToggle}
        >
          {isOpen ? (
            <ChevronUp className="text-primary" size={16} />
          ) : (
            <ChevronDown className="text-primary" size={16} />
          )}
        </button>
      );
    },
    enableSorting: false,
  },
  {
    accessorKey: 'name',
    header: 'Turma',
    cell: (props) => {
      const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
      const navigate = useNavigate();
      const handleOpenDeleteModal = () => {
        setIsDeleteModalOpen(true);
      };
      const handleCloseDeleteModal = () => {
        setIsDeleteModalOpen(false);
      };
      const handleDeleteModal = async () => {
        try {
          await api.delete(`/training-plannings/${props.row.original.id}`);
          toast.success('Turma excluída com sucesso', { theme: 'colored' });
          queryClient.invalidateQueries(['training-file-details']);
        } catch {
          toast.error('Erro ao excluir ficheiro', { theme: 'colored' });
        }
      };

      const { concluded: disabled } = props.row.original as unknown as {
        concluded: boolean;
      };

      const options = [
        {
          name: 'Editar turma',
          onClick: () =>
            navigate(
              `/home/ficheiros/${props.row.original.trainingFileId}/planejamento-de-turma/${props.row.original.id}`,
            ),
          disabled,
        },
        {
          name: 'Excluir turma',
          onClick: () => handleOpenDeleteModal(),
          disabled,
        },
      ];
      return (
        <div className="flex h-12 w-[14rem] items-center uppercase">
          <TableCellText text={props.getValue() as string} width="100%" />
          <SimpleMenu upDotsButton options={options} />

          {isDeleteModalOpen && (
            <ModalConfirmation
              confirmAction={handleDeleteModal}
              title="Excluir Turma"
              isOpen={isDeleteModalOpen}
              onClose={handleCloseDeleteModal}
              description={`Tem certeza que deseja excluir a turma: ${props.row.original.name}?`}
            />
          )}
        </div>
      );
    },
    enableSorting: false,
  },
  {
    accessorKey: 'date',
    header: 'Data',
    cell: (props) => {
      const openIds = useRecoilValue(trainingFileOpenTableAtom);
      const id = props.row.original.id;
      const isOpen = openIds.includes(id);
      const planningDays = props.row.original.planningDays;
      const tableValue =
        planningDays.length === 0
          ? 'N/A'
          : planningDays.length === 1
            ? format(new Date(planningDays[0].date), 'dd/MM/yyyy')
            : `${planningDays.length} dias`;
      return (
        <TatableCell
          isOpen={isOpen}
          text={tableValue}
          tableCellClassName={
            planningDays.length > 1 ? 'font-bold text-primary' : ''
          }
        >
          {planningDays.map((planningDay) => {
            return (
              <div
                className="flex h-8 min-h-8 w-full items-center bg-gray-200 p-1 font-semibold"
                key={planningDay.id}
              >
                <p>{format(new Date(planningDay.date), 'dd/MM/yyyy')}</p>
              </div>
            );
          })}
        </TatableCell>
      );
    },
    enableSorting: false,
  },
  {
    accessorKey: 'startTime',
    header: 'Horário de Início',
    cell: (props) => {
      const openIds = useRecoilValue(trainingFileOpenTableAtom);
      const id = props.row.original.id;
      const isOpen = openIds.includes(id);
      const planningDays = props.row.original.planningDays;
      const tableValue =
        planningDays.length === 0
          ? 'N/A'
          : planningDays.length === 1
            ? planningDays[0].startTime
            : ' - ';
      return (
        <TatableCell isOpen={isOpen} text={tableValue}>
          {planningDays.map((planningDay) => {
            return (
              <div
                className="flex h-8 min-h-8 w-full items-center bg-gray-200 p-1"
                key={planningDay.id}
              >
                <p>{planningDay.startTime}</p>
              </div>
            );
          })}
        </TatableCell>
      );
    },
    enableSorting: false,
  },
  {
    accessorKey: 'endTime',
    header: 'Horário de Término',
    cell: (props) => {
      const openIds = useRecoilValue(trainingFileOpenTableAtom);
      const id = props.row.original.id;
      const isOpen = openIds.includes(id);
      const planningDays = props.row.original.planningDays;
      const tableValue =
        planningDays.length === 0
          ? 'N/A'
          : planningDays.length === 1
            ? planningDays[0].endTime
            : ` - `;
      return (
        <TatableCell isOpen={isOpen} text={tableValue}>
          {planningDays.map((planningDay) => {
            return (
              <div
                className="flex h-8 min-h-8 w-full items-center bg-gray-200 p-1"
                key={planningDay.id}
              >
                <p>{planningDay.endTime}</p>
              </div>
            );
          })}
        </TatableCell>
      );
    },
    enableSorting: false,
  },
  {
    accessorKey: 'instructors',
    header: 'Instrutores',
    cell: (props) => {
      const openIds = useRecoilValue(trainingFileOpenTableAtom);
      const id = props.row.original.id;
      const isOpen = openIds.includes(id);
      const planningDays = props.row.original.planningDays;
      const tableValue =
        planningDays.length === 0
          ? 'N/A'
          : planningDays.length === 1
            ? format(new Date(planningDays[0].date), 'dd/MM/yyyy')
            : ` - `;
      return (
        <div className="flex  max-w-48 items-center">
          {planningDays.length === 0 ? (
            <div className="flex items-center text-xs">
              <TableCellText
                text={tableValue}
                className="flex h-12 items-center"
              />
            </div>
          ) : planningDays.length === 1 ? (
            <TrainingFileTeamInstructors
              trainingFileId={props.row.original.trainingFileId}
              trainingPlanningDay={planningDays[0]}
              className="h-12"
            />
          ) : (
            <TatableCell
              isOpen={isOpen}
              text={tableValue}
              containerClassName="text-center"
            >
              {planningDays.map((planningDay) => {
                return (
                  <TrainingFileTeamInstructors
                    key={planningDay.id}
                    trainingFileId={props.row.original.trainingFileId}
                    trainingPlanningDay={planningDay}
                    className="bg-gray-200 "
                  />
                );
              })}
            </TatableCell>
          )}
        </div>
      );
    },
    enableSorting: false,
  },
  {
    header: 'Evidências',
    meta: {
      headerClassName: 'text-start',
    },
    cell: (props) => {
      const [isLoading, setIsLoading] = useState(false);

      async function handleFilesSelected(files: File[], id: string) {
        setIsLoading(true);
        const formData = new FormData();
        for (const file of files) {
          formData.append('files', file);
        }

        formData.append('id', id);
        try {
          await api.post(
            `training-plannings/${props.row.original.id}/evidences/`,
            formData,
          );
          toast.success('Arquivo(s) foram salvos com sucesso!', {
            theme: 'colored',
          });
        } catch (e: any) {
          const errorMessage =
            e?.response?.status === 400
              ? e?.response?.data?.message
              : 'Não foi possível salvar o(s) arquivo(s) enviado(s)!';
          toast.error(errorMessage, {
            theme: 'colored',
            toastId: 'error',
          });
        } finally {
          setIsLoading(false);
          queryClient.invalidateQueries({
            queryKey: ['training-files'],
          });
        }
      }

      const handleDeleteEvidence = async (evidenceId: number) => {
        try {
          await api.delete(
            `training-plannings/${props.row.original.id}/evidences/${evidenceId}`,
          );

          toast.success(`Evidência deletada com sucesso!`, {
            theme: 'colored',
            toastId: 'success',
          });
          queryClient.invalidateQueries({
            queryKey: ['training-files'],
          });
        } catch {
          toast.error('Não foi possível deleta a evidência!', {
            theme: 'colored',
            toastId: 'error',
          });
        }
      };

      return (
        <div className="mx-2 flex h-12 w-48 items-center py-2">
          {isLoading ? (
            <div className="flex cursor-pointer flex-col items-center justify-center text-center">
              <CgSpinner
                size={20}
                color="#c1c1c1"
                className="h-full animate-spin"
              />
            </div>
          ) : (
            <div className="flex items-center justify-center">
              <FileUploadComponent
                onFilesSelected={(filesList: FileList | null) => {
                  const filesArray = filesList ? Array.from(filesList) : [];
                  handleFilesSelected(
                    filesArray,
                    String(props.row.original.id),
                  );
                }}
                rowId={String(props.row.original.id)}
              />
              <ViewFiles
                files={props.row.original.evidences}
                onDeleteFunction={handleDeleteEvidence}
                deleteFileDescrption={
                  'do planejamento do ficheiro e não poderá ser recuperado'
                }
              />
            </div>
          )}
        </div>
      );
    },
  },
  {
    accessorKey: 'id',
    header: 'Detalhamento da turma',
    cell: (props) => {
      const navigate = useNavigate();
      const handleClick = () => {
        const trainingFileId = props.row.original.trainingFileId;
        navigate(
          `detalhamento/${trainingFileId}/?turma=${props.getValue() as string}`,
        );
      };
      return (
        <div className="flex h-12 w-16 items-center justify-end">
          <button
            className="flex h-6 w-6 items-center justify-center rounded-full bg-primary hover:bg-primary/70"
            onClick={handleClick}
          >
            <FaArrowUpRightFromSquare color="white" size={12} />
          </button>
        </div>
      );
    },
    enableSorting: false,
  },
];
