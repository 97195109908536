import { useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';

import NoPermissionText from '@/components/atoms/NoPermissionText';
import BasicTabs from '@/components/molecules/BasicTabs';
import NewSideFilter from '@/components/molecules/NewSideFilter';
import ClosedPendenciesTab from '@/components/organisms/ClosedPendenciesTab';
import OpenPendenciesTab from '@/components/organisms/OpenPendenciesTab';
import {
  PendencyFilterOptionsSchema,
  PendencyFiltersOptions,
  PendencyStatus,
} from '@/constants/PendencyConstants';
import api from '@/services/apiSgft';
import { PendenciesFiltersAtom } from '@/state/PendenciesFilter.atom';
import { isReadOnlyUser } from '@/utils/handleSavePermissions';

import { filters } from '../../constants/filters/NewPendenciesFilter';

const Pendencies = () => {
  const filterValues = useRecoilValue(PendenciesFiltersAtom);

  const fetchFilterOptions = async () => {
    try {
      const response = await api.get('pendencies/filters', {
        params: {
          status: PendencyStatus.OPEN,
          management: filterValues.managements,
          area: filterValues.areas,
          sector: filterValues.sectors,
          workStation: filterValues.workstations,
          scale: filterValues.scales,
          pendencyType: filterValues.types,
          responsible: filterValues.responsibles,
        },
      });
      const parsedData = PendencyFilterOptionsSchema.parse(response.data);
      return parsedData;
    } catch (error) {
      toast.error('Erro ao carregar os dados', {
        theme: 'colored',
        toastId: 'error',
      });
      throw error;
    }
  };

  const { data: filterOptions, isLoading } = useQuery<PendencyFiltersOptions>(
    ['pendencies-filters', filterValues],
    fetchFilterOptions,
  );

  if (isReadOnlyUser()) return <NoPermissionText />;

  return (
    <div className="h-full w-full font-graphie">
      <NewSideFilter
        filters={filters}
        atom={PendenciesFiltersAtom}
        options={filterOptions}
        isLoading={isLoading}
      />
      <BasicTabs
        tabsNames={['Pendências Abertas', 'Pendências Concluídas']}
        tabs={[<OpenPendenciesTab key={0} />, <ClosedPendenciesTab key={1} />]}
      />
    </div>
  );
};

export default Pendencies;
