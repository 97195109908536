import { useQueryClient } from '@tanstack/react-query';
import { ColumnDef } from '@tanstack/react-table';
import { isAxiosError } from 'axios';
import { useState } from 'react';
import { FaCheckCircle, FaEye } from 'react-icons/fa';
import { FaCircleXmark } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { JustificationDialog } from '@/components/atoms/JustificationDialog';
import TableCellDate from '@/components/atoms/TableCellDate';
import TableCellText from '@/components/atoms/TableCellText';
import ModalConfirmation from '@/components/molecules/ConfirmationModal';
import api from '@/services/apiSgft';
import { removeTimeZone } from '@/utils/formatDate';

import {
  allocationRequestApproveResponseSchema,
  allocationRequestMessageSchema,
  AllocationRequestStatus,
} from '../AllocationRequest';
import { PendingRequest } from '../TurnstileApprovalsConstants';

export const pendingRequestColumns: ColumnDef<PendingRequest>[] = [
  {
    id: 'view',
    header: '',
    cell: (props) => {
      const navigate = useNavigate();
      const pendingRequest = props.row.original;
      const handleView = () => {
        navigate(
          `/home/planejamento-de-escalas-e-ferias?colaborador=${pendingRequest.employeeId}&estrutura=${pendingRequest.teamStructureId}&tipo=alocações`,
        );
      };

      return (
        <div className="flex w-20 justify-center">
          <button
            onClick={handleView}
            className="flex size-5 items-center justify-center rounded-full bg-[#1F3FA8] hover:bg-[#4c4c73]"
          >
            <FaEye style={{ color: 'white', cursor: 'pointer' }} size={12} />
          </button>
        </div>
      );
    },
    enableSorting: false,
  },
  {
    accessorKey: 'requesterName',
    header: 'Requisitante',
    cell: (props) => {
      return (
        <div className="w-60">
          <TableCellText text={props.getValue() as string} widthFull />
        </div>
      );
    },
  },
  {
    accessorKey: 'employeeName',
    header: 'Colaborador',
    cell: (props) => {
      return (
        <div className="w-60">
          <TableCellText text={props.getValue() as string} widthFull />
        </div>
      );
    },
  },
  {
    accessorKey: 'management',
    header: 'Gerência',
    cell: (props) => (
      <div className="w-56">
        <TableCellText text={props.getValue() as string} widthFull />
      </div>
    ),
  },
  {
    accessorKey: 'actualTeamStructure',
    header: 'Estrutura Atual',
    cell: (props) => (
      <div className="w-56">
        <TableCellText text={props.getValue() as string} widthFull />
      </div>
    ),
  },
  {
    accessorKey: 'actualTeam',
    header: 'Turma Atual',
    cell: (props) => (
      <div className="w-28">
        <TableCellText text={props.getValue() as string} widthFull />
      </div>
    ),
  },
  {
    accessorKey: 'newTeamStructure',
    header: 'Estrutura Nova',
    cell: (props) => (
      <div className="w-56">
        <TableCellText text={props.getValue() as string} widthFull />
      </div>
    ),
  },
  {
    accessorKey: 'newTeam',
    header: 'Turma Nova',
    cell: (props) => (
      <div className="w-28">
        <TableCellText text={props.getValue() as string} widthFull />
      </div>
    ),
  },
  {
    accessorKey: 'requestDate',
    header: 'Data',
    cell: (props) => (
      <div className="w-28">
        <TableCellDate
          date={
            (props.getValue() as string) && props.getValue() !== '-'
              ? removeTimeZone(new Date(props.getValue() as string))
              : undefined
          }
        />
      </div>
    ),
  },
  {
    accessorKey: 'motive',
    header: 'Motivo',
    cell: (props) => (
      <div className="w-48">
        <TableCellText text={props.getValue() as string} widthFull />
      </div>
    ),
  },
  {
    id: 'actions',
    header: '',
    cell: (props) => {
      const request = props.row.original;
      const queryClient = useQueryClient();
      const [isDialogOpen, setIsDialogOpen] = useState(false);
      const [justification, setJustification] = useState('');
      const [message, setMessage] = useState('');
      const [isModalOpen, setIsModalOpen] = useState(false);

      const handleRejectClick = () => {
        setIsDialogOpen(true);
      };

      const handleApproveClick = async () => {
        const response = await api.get(
          '/team-employee/allocation-request/message',
          {
            params: {
              employeeId: request.employeeId,
              startDate: request.requestDate,
              isAllocationRequest: false,
            },
          },
        );
        const parsedResponse = allocationRequestMessageSchema.safeParse(
          response.data,
        );
        if (!parsedResponse.success) {
          toast.error('Não foi possível enviar a solicitação', {
            theme: 'colored',
            toastId: 'error',
          });
          return;
        }
        setMessage(parsedResponse.data.message);
        setIsModalOpen(true);
      };

      const handleRejectConfirmation = async () => {
        try {
          const response = await api.patch(
            `team-employee/allocation-approval/${request.requestId}`,
            {
              status: AllocationRequestStatus.REFUSED,
              justification,
            },
          );
          const responseData = allocationRequestApproveResponseSchema.safeParse(
            response.data,
          );
          if (responseData.success) {
            toast.success(responseData.data.message, {
              theme: 'colored',
            });
            queryClient.invalidateQueries(['pending-approvals']);
          }
        } catch (e) {
          if (isAxiosError(e)) {
            if (e.response?.data?.message) {
              toast.error(e.response?.data?.message, {
                theme: 'colored',
                toastId: 'error',
              });
            }
          } else {
            toast.error('Não foi possível enviar a solicitação', {
              theme: 'colored',
              toastId: 'error',
            });
          }
        }

        setIsDialogOpen(false);
      };

      const handleApproveConfirmation = async () => {
        try {
          const response = await api.patch(
            `team-employee/allocation-approval/${request.requestId}`,
            {
              status: AllocationRequestStatus.APPROVED,
            },
          );
          const responseData = allocationRequestApproveResponseSchema.safeParse(
            response.data,
          );
          if (responseData.success) {
            toast.success(responseData.data.message, {
              theme: 'colored',
            });
            queryClient.invalidateQueries(['pending-allocation-requests']);
          }
        } catch (e) {
          if (isAxiosError(e)) {
            if (e.response?.data?.message) {
              toast.error(e.response?.data?.message, {
                theme: 'colored',
                toastId: 'error',
              });
            }
          } else {
            toast.error('Não foi possível enviar a solicitação', {
              theme: 'colored',
              toastId: 'error',
            });
          }
        }
        setIsModalOpen(false);
      };

      return (
        <div className="flex w-20 justify-center gap-4">
          <button onClick={handleRejectClick}>
            <FaCircleXmark
              style={{ color: '#f4503a', cursor: 'pointer' }}
              size={18}
            />
          </button>
          <JustificationDialog
            title="Rejeitar Solicitação"
            justification={justification}
            setJustification={setJustification}
            isOpen={isDialogOpen}
            setIsOpen={setIsDialogOpen}
            onConfirmation={handleRejectConfirmation}
          />
          <button onClick={handleApproveClick}>
            <FaCheckCircle
              style={{ color: '#108a6d', cursor: 'pointer' }}
              size={18}
            />
          </button>
          {isModalOpen && (
            <ModalConfirmation
              title="Aprovar Solicitação"
              description={message}
              descriptionClassName="whitespace-pre-wrap"
              confirmAction={handleApproveConfirmation}
              isOpen={isModalOpen}
              onClose={() => setIsModalOpen(false)}
              discartAction={() => setIsModalOpen(false)}
            />
          )}
        </div>
      );
    },
    enableSorting: false,
  },
];
