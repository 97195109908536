import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { TrainingType } from '@/constants/TrainingPlanning';
import { TrainingTypeEnum } from '@/constants/trainingTypeOptions';

import EmptyIcon from '../../assets/icons/empty.svg';
import api from '../../services/apiSgft';
import { JobTrainingMatrixFilters } from '../../state/JobTraningMatrixFilter.atom';
import {
  ProcedureTraining,
  Training,
  TrainingMatrix,
} from '../../types/Training';
import Line from '../atoms/Line';
import Spinner from '../atoms/Spinner';
import { TooltipString } from '../atoms/TooltipString';
import ModalConfirmation from '../molecules/ConfirmationModal';
import TrainingHeader from '../molecules/TrainingHeader';
import TrainingMatrixCell from '../molecules/TrainingMatrixCell';
import TraniningWrapper from '../molecules/TrainingWrapper';
import DesactivateTrainingModal from './DesactivateTrainingModal';
import InfiniteTable from './InfiniteTable';
import TrainingModal from './TrainingModal';

interface Props {
  trainingsMatrixData?: TrainingMatrix;
  isLoading: boolean;
  isError: boolean;
  fetchNextPage: () => void;
  isFetchingNextPage: boolean;
  hasNextPage?: boolean;
  filteredData: JobTrainingMatrixFilters;
  needsToRefetch: boolean;
  setNeedsToRefetch: Dispatch<SetStateAction<boolean>>;
  handleMatrixRefetch: () => void;
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
  canSave?: boolean;
  isEditMode: boolean;
  modifiedCheckboxes: Map<string, boolean>;
  onMappingChange: (
    trainingId: number,
    jobTitleId: number,
    location: string,
    checked: boolean,
  ) => void;
}

const JobTrainingMatrix: FC<Props> = ({
  trainingsMatrixData,
  isLoading,
  isError,
  hasNextPage,
  fetchNextPage,
  isFetchingNextPage,
  filteredData,
  needsToRefetch,
  setNeedsToRefetch,
  handleMatrixRefetch,
  isModalOpen,
  setIsModalOpen,
  canSave,
  isEditMode,
  modifiedCheckboxes,
  onMappingChange,
}) => {
  const [selectedTraining, setSelectedTraining] = useState<
    Training | undefined
  >(undefined);
  const [selectedProcedure, setSelectedProcedure] = useState<
    ProcedureTraining | undefined
  >(undefined);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [isLoadingTrainings, setIsLoadingTrainings] = useState(false);
  const [isDesactivateModalOpen, setIsDesactivateModalOpen] = useState(false);
  const [desactivateTrainingId, setDesactivateTrainingId] = useState(-1);
  const [size, setSize] = useState(10);
  const [searchParams, setSearchParams] = useSearchParams();

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedTraining(undefined);
    setSelectedProcedure(undefined);
  };

  const NoTrainings = () => {
    return (
      <div className="flex h-[80vh] w-full flex-col items-center justify-center py-10">
        <img src={EmptyIcon} className="w-40" />
        <div className="flex flex-col items-center text-sm">
          <p>{`Nenhum treinamento foi encontrado com esses parâmetros`}</p>
          <span className="font-semibold text-blue-800">
            {`Cadastre um ou altere os valores dos filtros para visualizar a matriz!`}
          </span>
        </div>
      </div>
    );
  };

  const handleCloseDesactivateModal = () => {
    setIsDesactivateModalOpen(false);
  };

  const handleTransformTrainingName = (title: string, maxLength: number) => {
    if (title.length > maxLength) {
      return title.substring(0, maxLength) + '...';
    } else {
      return title;
    }
  };

  const handleEditTraining = useCallback(
    async (trainingId: number) => {
      try {
        setIsLoadingTrainings(true);
        const response = await api.get(`trainings/${trainingId}`);
        setSelectedTraining(response.data);

        if (response.data?.trainingType === TrainingType.Procedimento) {
          const response = await api.get(`procedure/${trainingId}`);
          setSelectedProcedure(response.data);
        }
        setIsModalOpen(true);
      } catch (error) {
        toast.error('Erro ao carregar os dados', {
          theme: 'colored',
          toastId: `error-trainings/${trainingId}`,
        });
        throw error;
      } finally {
        setIsLoadingTrainings(false);
      }
    },
    [setIsModalOpen],
  );

  useEffect(() => {
    const trainingId = searchParams.get('id');
    if (trainingId) {
      handleEditTraining(Number(trainingId));
      searchParams.delete('id');
      setSearchParams(searchParams);
    }
  }, []);

  const handleDesactivateTraining = useCallback(async (trainingId: number) => {
    setDesactivateTrainingId(trainingId);
    setIsDesactivateModalOpen(true);
  }, []);

  const handleDeleteTraining = useCallback(
    async (trainingId: number) => {
      try {
        setIsLoadingTrainings(true);
        const response = await api.get(`trainings/${trainingId}`, {
          params: { location: filteredData.location },
        });
        setSelectedTraining(response.data);
        setDeleteConfirmation(true);
      } catch (error) {
        toast.error('Erro ao carregar os dados', {
          theme: 'colored',
          toastId: `error-trainings/${trainingId}`,
        });
        throw error;
      } finally {
        setIsLoadingTrainings(false);
      }
    },
    [filteredData.location],
  );

  const specialRowsByTrainingType = {
    [TrainingTypeEnum.PROCEDURE]: [
      { isSpecial: true, type: 'isCritical' },
      { isSpecial: true, type: 'totalWorkload' },
      { isSpecial: true, type: 'functions' },
    ],
    [TrainingTypeEnum.REGULATORY]: [
      { isSpecial: true, type: 'validity' },
      { isSpecial: true, type: 'totalWorkload' },
      { isSpecial: true, type: 'recyclingMonths' },
      { isSpecial: true, type: 'functions' },
    ],
  };

  const trainingColumns = trainingsMatrixData?.trainings.map(
    (training, index: number) => ({
      accessorKey: `training_${training.id}`,
      meta: {
        headerClassName: 'pr-2 text-xs bg-blue-200 min-w-[12rem] uppercase',
        stickyClassName: 'pr-2',
      },
      enableSorting: false,
      header: () => (
        <TrainingHeader
          training={training}
          canSeeTraining={true}
          canSave={canSave}
          handleEditTraining={handleEditTraining}
          handleDesactivateTraining={handleDesactivateTraining}
          handleDeleteTraining={handleDeleteTraining}
          handleTransformTrainingName={handleTransformTrainingName}
        />
      ),
      cell: ({ row }: { row: any }) => {
        const trainingType = filteredData.trainingType[0];

        /*
          TODO: Number of header rows before the job titles rows start. Important to ensure
          the correct index is passed to the cell component and the job titles are
          correctly aligned with the training columns.
        */
        const JOB_TITLE_GAP =
          (trainingType as TrainingTypeEnum) === TrainingTypeEnum.REGULATORY
            ? 4
            : 3;

        return (
          <TrainingMatrixCell
            isEditMode={isEditMode}
            rowIndex={row.index}
            colIndex={index}
            trainingsMatrixData={trainingsMatrixData}
            jobTitleId={
              trainingsMatrixData?.jobTitles[row.index - JOB_TITLE_GAP]?.id || 0
            }
            trainingId={training.id}
            isCritical={training.isCritical}
            totalWorkload={training.totalWorkload}
            validity={training.validity}
            recyclingWorkload={training.recyclingMonths}
            modifiedCheckboxes={modifiedCheckboxes}
            canSave={canSave}
            isResponsible={training.isResponsible}
            trainingType={trainingType as TrainingTypeEnum}
            onChangeCallback={(checked) => {
              onMappingChange(
                training.id,
                trainingsMatrixData?.jobTitles[row.index - JOB_TITLE_GAP]?.id ||
                  0,
                filteredData.location[0],
                checked,
              );
            }}
            jobTitleGap={JOB_TITLE_GAP}
          />
        );
      },
    }),
  );

  const trainingType = filteredData.trainingType[0];
  const specialRows =
    specialRowsByTrainingType[
      trainingType as keyof typeof specialRowsByTrainingType
    ] || [];

  const formatJobTitleName = (name: string) => {
    if (!name) return name;
    return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
  };

  const jobTitlesData = trainingsMatrixData?.jobTitles
    ? [...specialRows, ...trainingsMatrixData.jobTitles].map((jobTitle) => {
        if ('name' in jobTitle) {
          return {
            ...{
              ...jobTitle,
              name: formatJobTitleName(jobTitle.name),
            },
          };
        }

        return {
          ...jobTitle,
        };
      })
    : [...specialRows];

  const createRow = (text: string) => (
    <div className="flex h-12 items-center justify-center bg-blue-700 px-2 font-graphie text-[12px] text-white">
      {text}
    </div>
  );

  const CriticalityRow = createRow('CRITICIDADE');
  const ValidityRow = createRow('VALIDADE (anos)');
  const TotalWorkloadRow = createRow('CARGA HORÁRIA TOTAL (H)');
  const RecycleTotalWorkloadRow = createRow('CARGA HORÁRIA RECICLAGEM (H)');

  const jobTitleColumn = [
    {
      accessorKey: 'locations',
      meta: {
        stickyClassName: 'sticky left-0 bg-white z-10 py-0 h-12',
        headerClassName: 'min-w-[12rem]',
      },
      header: '',
      cell: (info: any) => {
        const trainingType = filteredData.trainingType[0];
        const value: string[] = info.getValue();
        switch (info.row.index) {
          case 0:
            return '';
          case 1:
            return '';
          case 2:
            return trainingType === TrainingTypeEnum.REGULATORY ? (
              ''
            ) : (
              <div className="top-0 z-10 flex h-[3.4rem] items-center justify-center overflow-x-hidden bg-blue-200 text-base duration-200">
                POLO
              </div>
            );
          case 3:
            return trainingType === TrainingTypeEnum.REGULATORY ? (
              <div className="top-0 z-10 flex h-[3.4rem] items-center justify-center overflow-x-hidden bg-blue-200 text-base duration-200">
                POLO
              </div>
            ) : (
              <div className="flex h-12 w-[12rem] items-center justify-start overflow-x-hidden px-2 ">
                <TooltipString
                  value={value.length > 0 ? value[0]?.toUpperCase() : ''}
                  color="black"
                  fontSize="11px"
                />
              </div>
            );
          default:
            return (
              <div className="flex h-12 w-[12rem] items-center justify-start overflow-x-hidden px-2">
                <TooltipString
                  value={value.length > 0 ? value[0]?.toUpperCase() : ''}
                  color="black"
                  fontSize="11px"
                />
              </div>
            );
        }
      },
    },
    {
      accessorKey: 'management',
      meta: {
        stickyClassName: 'sticky left-0 bg-white z-10 py-0 h-12',
        headerClassName: 'min-w-[12rem]',
      },
      header: '',
      cell: (info: any) => {
        const trainingType = filteredData.trainingType[0];
        switch (info.row.index) {
          case 0:
            return '';
          case 1:
            return '';
          case 2:
            return trainingType === TrainingTypeEnum.REGULATORY ? (
              ''
            ) : (
              <div className="top-0 z-10 flex h-[3.4rem] items-center justify-center overflow-x-hidden bg-blue-200 text-base duration-200">
                GERÊNCIA
              </div>
            );
          case 3:
            return trainingType === TrainingTypeEnum.REGULATORY ? (
              <div className="top-0 z-10 flex h-[3.4rem] items-center justify-center overflow-x-hidden bg-blue-200 text-base duration-200">
                GERÊNCIA
              </div>
            ) : (
              <div className="flex h-12 w-[12rem] items-center justify-start overflow-x-hidden px-2">
                <TooltipString
                  value={info.getValue()?.toUpperCase()}
                  color="black"
                  fontSize="11px"
                />
              </div>
            );
          default:
            return (
              <div className="flex h-12 w-[12rem] items-center justify-start overflow-x-hidden px-2">
                <TooltipString
                  value={info.getValue()?.toUpperCase()}
                  color="black"
                  fontSize="11px"
                />
              </div>
            );
        }
      },
    },
    {
      accessorKey: 'areaCoordination',
      meta: {
        stickyClassName: 'sticky left-0 bg-white z-10 py-0 h-12',
        headerClassName: 'min-w-[12rem]',
      },
      header: '',
      cell: (info: any) => {
        const trainingType = filteredData.trainingType[0];
        switch (info.row.index) {
          case 0:
            return '';
          case 1:
            return '';
          case 2:
            return trainingType === TrainingTypeEnum.REGULATORY ? (
              ''
            ) : (
              <div className="top-0 z-10 flex h-[3.4rem] items-center justify-center overflow-x-hidden bg-blue-200 text-base duration-200">
                COORDENAÇÃO
              </div>
            );
          case 3:
            return trainingType === TrainingTypeEnum.REGULATORY ? (
              <div className="top-0 z-10 flex h-[3.4rem] items-center justify-center overflow-x-hidden bg-blue-200 text-base duration-200">
                COORDENAÇÃO
              </div>
            ) : (
              <div className="flex h-12 w-[12rem] items-center justify-start overflow-x-hidden px-2">
                <TooltipString
                  value={info.getValue()?.toUpperCase()}
                  color="black"
                  fontSize="11px"
                />
              </div>
            );
          default:
            return (
              <div className="flex h-12 w-[12rem] items-center justify-start overflow-x-hidden px-2">
                <TooltipString
                  value={info.getValue()?.toUpperCase()}
                  color="black"
                  fontSize="11px"
                />
              </div>
            );
        }
      },
    },
    {
      accessorKey: 'workstation',
      meta: {
        stickyClassName: 'sticky left-0 bg-white z-10 py-0 h-12',
        headerClassName: 'min-w-[12rem]',
      },
      header: '',
      cell: (info: any) => {
        const trainingType = filteredData.trainingType[0];
        switch (info.row.index) {
          case 0:
            return '';
          case 1:
            return '';
          case 2:
            return trainingType === TrainingTypeEnum.REGULATORY ? (
              ''
            ) : (
              <div className="top-0 z-10 flex h-[3.4rem] items-center justify-center overflow-x-hidden bg-blue-200 text-base duration-200">
                LOCAL DE TRABALHO
              </div>
            );
          case 3:
            return trainingType === TrainingTypeEnum.REGULATORY ? (
              <div className="top-0 z-10 flex h-[3.4rem] items-center justify-center overflow-x-hidden bg-blue-200 text-base duration-200">
                LOCAL DE TRABALHO
              </div>
            ) : (
              <div className="flex h-12 w-[12rem] items-center justify-start overflow-x-hidden px-2">
                <TooltipString
                  value={info.getValue()?.toUpperCase()}
                  color="black"
                  fontSize="11px"
                />
              </div>
            );
          default:
            return (
              <div className="flex h-12 w-[12rem] items-center justify-start overflow-x-hidden px-2">
                <TooltipString
                  value={info.getValue()?.toUpperCase()}
                  color="black"
                  fontSize="11px"
                />
              </div>
            );
        }
      },
    },
    {
      accessorKey: 'name',
      meta: {
        stickyClassName: 'sticky left-0 bg-white z-10 py-0 h-12',
        headerClassName: 'min-w-[12rem] sticky left-0 z-20 bg-white',
      },
      header: () => {
        return (
          <div className="flex h-8 items-center justify-center font-graphie text-[13px]">
            <span>FUNÇÃO / CURSO</span>
          </div>
        );
      },
      cell: (info: any) => {
        const trainingType = filteredData.trainingType[0];
        switch (info.row.index) {
          case 0:
            return trainingType === TrainingTypeEnum.REGULATORY
              ? ValidityRow
              : CriticalityRow;
          case 1:
            return TotalWorkloadRow;
          case 2:
            return trainingType === TrainingTypeEnum.REGULATORY ? (
              RecycleTotalWorkloadRow
            ) : (
              <div className="top-0 z-10 flex h-[3.4rem] items-center justify-center overflow-x-hidden bg-blue-200 text-base duration-200">
                FUNÇÃO
              </div>
            );
          case 3:
            return trainingType === TrainingTypeEnum.REGULATORY ? (
              <div className="top-0 z-10 flex h-[3.4rem] items-center justify-center overflow-x-hidden bg-blue-200 text-base duration-200">
                FUNÇÃO
              </div>
            ) : (
              <div className="flex h-12 w-[12rem] items-center justify-start overflow-x-hidden px-2">
                <TooltipString
                  value={info.getValue()?.toUpperCase()}
                  color="black"
                  fontSize="11px"
                />
              </div>
            );
          default:
            return (
              <div className="flex h-12 w-[12rem] items-center justify-start overflow-x-hidden px-2">
                <TooltipString
                  value={info.getValue()?.toUpperCase()}
                  color="black"
                  fontSize="11px"
                />
              </div>
            );
        }
      },
    },
  ];

  const submitDelete = async () => {
    try {
      setIsLoadingTrainings(true);
      const response = await api.delete(`trainings/${selectedTraining?.id}`, {
        params: {
          trainingType: filteredData.trainingType[0],
        },
      });
      setSelectedTraining(response.data);
      setDeleteConfirmation(true);
    } catch (error) {
      toast.error('Erro ao carregar os dados', {
        theme: 'colored',
        toastId: `error-trainings/${selectedTraining?.id}`,
      });
      throw error;
    } finally {
      setIsLoadingTrainings(false);
      setSelectedTraining(undefined);
      setDeleteConfirmation(false);
      setNeedsToRefetch(!needsToRefetch);
    }
  };

  const handleJobTitleColumn = () => {
    if (filteredData.trainingType[0] === TrainingType.Regulatorio) {
      return jobTitleColumn.filter(
        (el) =>
          el.accessorKey !== 'locations' && el.accessorKey !== 'workstation',
      );
    } else {
      if (filteredData.location[0] !== 'Corporativo') {
        return jobTitleColumn.filter((el) => el.accessorKey !== 'locations');
      } else {
        return jobTitleColumn;
      }
    }
  };

  const columns =
    trainingsMatrixData?.trainings && trainingColumns
      ? [...handleJobTitleColumn(), ...trainingColumns]
      : [...handleJobTitleColumn()];

  if (
    !trainingsMatrixData ||
    Object.keys(trainingsMatrixData).length === 0 ||
    trainingsMatrixData.trainings.length === 0
  ) {
    return (
      <TraniningWrapper isLoading={isLoading} isError={isError}>
        <div className="flex h-full w-full rounded-md bg-white p-5">
          {NoTrainings()}
        </div>
        <TrainingModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          matrixRefetch={handleMatrixRefetch}
          trainingData={selectedTraining}
        />
      </TraniningWrapper>
    );
  }

  const handleLocationName = (location: string) => {
    const lowerCaseLocation = location.toLowerCase();

    if (lowerCaseLocation === 'corporativo') {
      return 'Corporativo';
    }
    if (lowerCaseLocation === 'matriz') {
      return 'Matriz';
    }
    if (lowerCaseLocation === 'tamac') {
      return 'Terminal Aquaviáro de Maceió (TAMAC)';
    }

    return location;
  };

  const fetchNextHorizontalPage = () => {
    setSize((prevState) => prevState + 10);
  };
  const stickyRowForTrainingType =
    filteredData.trainingType[0] === TrainingTypeEnum.REGULATORY
      ? [0, 1, 2, 3]
      : [0, 1, 2];

  return (
    <TraniningWrapper isLoading={isLoading} isError={isError}>
      {(isLoading || isLoadingTrainings) && (
        <div className="flex h-full w-full items-center justify-center">
          <Spinner size={50} />
        </div>
      )}

      <div className="flex h-full w-full flex-col rounded-md bg-white p-5">
        <div>
          <div className="align-center flex w-full font-graphie text-[20px] font-bold text-blue-800">{`${handleLocationName(filteredData.location[0].toLocaleUpperCase())}`}</div>
          <Line />
        </div>

        <InfiniteTable
          hasNextPage={hasNextPage}
          data={jobTitlesData}
          columns={columns.filter(
            (el, index) => index < Math.min(size, columns.length),
          )}
          fetchNextPage={fetchNextPage}
          isFetchingNextPage={isFetchingNextPage}
          fetchNextHorizontalPage={fetchNextHorizontalPage}
          hasDivider
          isWhiteTable
          shouldFetchNextHorizontalPage
          stickyRows={stickyRowForTrainingType}
        />
      </div>
      <TrainingModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        matrixRefetch={handleMatrixRefetch}
        trainingData={selectedTraining}
        procedureData={selectedProcedure}
      />

      <ModalConfirmation
        isOpen={deleteConfirmation}
        title="Deseja confirmar a remoção do treinamento para todos os polos?"
        description="Cuidado, esta ação não poderá ser desfeita!"
        confirmAction={submitDelete}
        discartAction={() => {
          setSelectedTraining(undefined);
          setSelectedProcedure(undefined);
          setDeleteConfirmation(false);
        }}
        onClose={() => {
          setSelectedTraining(undefined);
          setSelectedProcedure(undefined);
          setDeleteConfirmation(false);
        }}
      />

      <DesactivateTrainingModal
        trainingId={desactivateTrainingId}
        isOpen={isDesactivateModalOpen}
        onClose={handleCloseDesactivateModal}
        matrixRefetch={handleMatrixRefetch}
        trainingType={filteredData.trainingType[0] as TrainingType}
      />
    </TraniningWrapper>
  );
};

export default JobTrainingMatrix;
