import { NewFilter } from '@/types/NewFilter';

export const filters: NewFilter[] = [
  {
    key: 'management',
    name: 'Gerência SGFT',
    isMulti: false,
    isDisabled: () => false,
  },
  {
    key: 'workStation',
    name: 'Local de Trabalho SGFT',
    isMulti: true,
    isDisabled: () => false,
  },
  {
    key: 'teamStructure',
    name: 'Estrutura de Turma',
    isMulti: true,
    isDisabled: () => false,
  },
  {
    key: 'role',
    name: 'Cargo',
    isMulti: true,
    isDisabled: () => false,
  },
];
