import { Checkbox, FormControlLabel, TextField } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useState } from 'react';
import { CgSpinner } from 'react-icons/cg';

import { removeTimeZone } from '../../utils/removeTimezone';
import { Button } from './Button';

interface DateRange {
  start: string;
  end: string;
}

interface Props {
  title: string;
  dateRange: DateRange;
  setDateRange: (value: any) => void;
  isEverything: boolean;
  setIsEverything: (value: boolean) => void;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  onConfirmation: () => Promise<void>;
}

export const DateRangeDialog = ({
  title,
  dateRange,
  setDateRange,
  isEverything,
  setIsEverything,
  isOpen,
  setIsOpen,
  onConfirmation,
}: Props) => {
  const [loading, setLoading] = useState(false);

  const handleConfirmation = async () => {
    setLoading(true);
    try {
      await onConfirmation();
    } catch (error) {
      console.error('Error during confirmation:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      PaperProps={{
        style: {
          borderRadius: '20px',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
          overflowY: 'unset',
          height: '20rem',
          width: '40rem',
        },
      }}
      open={isOpen}
      onClose={() => setIsOpen(false)}
    >
      <div className="flex justify-center border-b-[1px] border-[#7b7b7b42] p-2">
        <DialogTitle>
          <span className="px-1 text-center font-graphie  text-[19px] font-semibold text-slate-800">
            {title}
          </span>
        </DialogTitle>
      </div>
      <DialogContent
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={isEverything}
              onChange={(e) => setIsEverything(e.target.checked)}
            />
          }
          label="Tudo"
        />
        <>
          <TextField
            label="Data Início"
            type="date"
            value={dateRange.start}
            onChange={(e) =>
              setDateRange((prev: any) => ({
                ...prev,
                start: removeTimeZone(e.target.value),
              }))
            }
            InputLabelProps={{ shrink: true }}
            disabled={isEverything}
          />
          <TextField
            label="Data Fim"
            type="date"
            value={dateRange.end}
            onChange={(e) =>
              setDateRange((prev: any) => ({
                ...prev,
                end: removeTimeZone(e.target.value),
              }))
            }
            InputLabelProps={{ shrink: true }}
            sx={{ marginLeft: 2 }}
            disabled={isEverything}
          />
        </>
      </DialogContent>{' '}
      <DialogActions
        sx={{
          width: 'full',
          display: 'flex',
          justifyContent: 'space-around',
          paddingBottom: '25px',
        }}
      >
        <Button
          type="button"
          className="min-w-40 border-2 border-[#193CB9] bg-white text-[#193CB9] hover:bg-[#e9eaf1]"
          onClick={() => setIsOpen(false)}
          disabled={loading}
        >
          Cancelar
        </Button>
        <Button
          type="button"
          className={`min-w-40 ${loading ? 'bg-gray-200' : ''} ${!isEverything && (!dateRange.start || !dateRange.end) ? 'bg-gray-200' : ''}`} // Add additional condition for background color if needed
          onClick={handleConfirmation}
          disabled={
            loading || (!isEverything && (!dateRange.start || !dateRange.end))
          }
        >
          {loading ? (
            <CgSpinner
              size={30}
              color="#4f4f4f"
              className="h-full animate-spin"
            />
          ) : (
            'Confirmar'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
