import { useInfiniteQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useRecoilState } from 'recoil';

import { PendencyStatus, PendencyType } from '@/constants/PendencyConstants';
import { columns } from '@/constants/tableColumns/TurnstileApprovalTableColumns';
import { TurnstileApprovalListSchema } from '@/constants/TurnstileApprovalsConstants';
import api from '@/services/apiSgft';
import { TurnstileApprovalFiltersAtom } from '@/state/TurnstileApprovalFilter.atom';

import EmptyIcon from '../../assets/icons/empty.svg';
import Spinner from '../atoms/Spinner';
import InfiniteTable from './InfiniteTable';

const PendingTurnstileApprovalsTab = () => {
  const [filteredData, setFilteredData] = useRecoilState(
    TurnstileApprovalFiltersAtom,
  );

  const fetchStructures = async ({ pageParam = 1 }) => {
    try {
      const response = await api.get('pendencies/turnstile-approvals', {
        params: {
          size: 20,
          page: pageParam,
          status: [PendencyStatus.OPEN],
          type: [PendencyType.TURNSTILE_APPROVAL],
          management: filteredData.management,
          teamStructure: filteredData.teamStructure,
          area: filteredData.area,
          workStation: filteredData.workStation,
          sector: filteredData.sector,
          regime: filteredData.regime,
          scale: filteredData.scale,
        },
      });
      const parsedData = TurnstileApprovalListSchema.parse(response.data);
      return parsedData;
    } catch (e) {
      toast.error('Erro ao carregar os dados', {
        theme: 'colored',
        toastId: 'error',
      });
      throw e;
    }
  };

  const { data, isLoading, fetchNextPage, isFetchingNextPage, hasNextPage } =
    useInfiniteQuery(
      ['pending-turnstile-approvals', filteredData],
      fetchStructures,
      {
        retry: false,
        getNextPageParam: (actualPage) => {
          return actualPage.nextPage;
        },
      },
    );

  const pendingTurnstilesApprovals =
    data?.pages.flatMap((page) => page.pendencyData) ?? [];

  useEffect(() => {
    setFilteredData((prev) => ({
      ...prev,
      tab: 1,
      status: [],
      type: [],
    }));
  }, []);

  return (
    <div className="w-full rounded-md bg-white px-4 py-4">
      {isLoading ? (
        <div className="flex h-full w-full items-center rounded-md bg-white">
          <Spinner size={50} />
        </div>
      ) : pendingTurnstilesApprovals.length > 0 ? (
        <div className="max-w-full overflow-x-auto scrollbar-thin scrollbar-thumb-[#D9D9D9] scrollbar-thumb-rounded-full">
          <InfiniteTable
            hasNextPage={hasNextPage}
            data={pendingTurnstilesApprovals}
            columns={columns}
            fetchNextPage={fetchNextPage}
            isFetchingNextPage={isFetchingNextPage}
            alignTextLeft
          />
        </div>
      ) : (
        <div className="flex h-full w-full flex-col items-center justify-center py-10">
          <img src={EmptyIcon} className="w-40" />
          <div className="flex flex-col items-center text-sm">
            <p>Nenhuma liberação encontrada com esses parâmetros</p>
            <span className="font-semibold text-blue-800">
              Liberações aparecerão aqui
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default PendingTurnstileApprovalsTab;
